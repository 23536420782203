import React, {useState , useEffect} from 'react';
import './App.css';
import LoginForm from "./components/LoginForm";
// import CaseList from "./components/CaseList";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import PersonList from "./pages/person/PersonList";
import PersonsCreate from "./pages/person/PersonsCreate";

import CaseList from "./pages/case/CaseList";
import Persons from "./pages/person/Persons";
import PartnerList from "./pages/procurement/PartnerList";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown/style.css';
import './media.css';
// import { CategoryScale } from "chart.js";
// import Chart from "chart.js/auto";
import { Chart as ChartJS, ArcElement, Tooltip, Legend ,CategoryScale, registerables } from "chart.js";
import ProfileChangeWrapper from './components/common/ProfileChangeWrapper';

function App() {
  

  let params = new URLSearchParams(document.location.search);
  if( params.get('rs') ){
    localStorage.setItem("rs", params.get('rs')??'');
  }

  return (
    <React.Fragment>
        {/* <div className="container" style={{margin: '0px',padding: '0px'}}> */}
            <Routes>
                {/* <Route path={'/'} element={<Navigate to = {'/login'}/>} /> */}
                <Route path={'/'} element={<LoginForm/>} />
                <Route path={'/person/create'} element={<PersonsCreate/>} />
                <Route path="/setting" element={<ProfileChangeWrapper />} />
                {/* <Route path={'/cases/list'} element={<CaseList/>} />
                <Route path={'/persons/list'} element={<PersonList/>} />
                <Route path={'/persons'} element={<Persons/>} /> */}
                {/* <Route path="/" element={<LoginForm/>} />
                <Route path={'/login'} element={<LoginForm/>} />
                <Route path={'/cases/list'} element={<CaseList/>} />
                <Route path={'/persons/list'} element={<PersonList/>} />
                <Route path={'/persons'} element={<Persons/>} /> */}
                <Route path="/" element={<MainLayout/>} >
                  {routes}
                </Route>
            </Routes>
        {/* </div> */}
          
    </React.Fragment>
  );
}

ChartJS.register(CategoryScale, ArcElement, Tooltip, Legend, ...registerables);
// ChartJS.register(ArcElement, Tooltip, Legend);

export default App;
