import React, {useEffect, useState} from 'react';
import {CaseListService} from "../../services/CaseService";
import PersonService from "../../services/PersonService";
import {AssignmentService} from "../../services/AssignmentService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import {useParams, Link, useLocation, useNavigate  } from "react-router-dom";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {SendMail} from "../../models/ICase";
import Loader from "../../components/common/Loader";
import CaseMainInfo from "../../components/common/CaseMainInfo";

interface URLParam {
    id : string;
}

interface IState{
    loading :boolean;
    cases_arr : [];
    assignment_arr : [];
    work_arr : [];
    eigyou : [];
    hr : string[];
    sale : string[];
    send_mail_arr : SendMail;
    flb_sale_id : number;
    errorMessage : string;
}

interface IProps{}
let CaseDetail:React.FC<IProps> = () => {
    let {encode} = useParams<URLParam | any>();
    let [state, setState] = useState<IState>({
        loading : false,
        cases_arr :  [],
        assignment_arr : [],
        work_arr : [],
        eigyou : [],
        hr : [],
        sale : [],
        flb_sale_id : 0,
        send_mail_arr : {
            personId : "",
            mail_to : "",
            mail_cc : "",
            mail_subject : "",
            mail_contact_name: "",
            mail_message : "",
            attachment:"",
        },
        errorMessage : "",
    });

    let person_id : string[] = [];
    let [person_ids, setPersonID] = useState(person_id);  
    const [open, setOpen] = React.useState(false);
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const handleClose = () => setOpen(false);
    let location = useLocation();

    useEffect(() => {

        // if (location.pathname !== previousRoute) {
        //   localStorage.setItem('previousRoute', location.pathname);
        // }
        // const currentPath = window.location.pathname; // Get current path
        // alert(currentPath);
        // if (currentPath !== '/') {
        //     setPreviousPath(currentPath);
        //   }
        setState({...state, loading: true});

        if(encode){
            CaseListService.show(encode).then((response)=>{
                setState({
                    ...state,
                    loading : false,
                    cases_arr : response.data.case,
                    assignment_arr : response.data.assignments,
                    work_arr : response.data.person_info,
                    eigyou: response.data.eigyou,
                    hr : response.data.case.hr,
                    sale : response.data.case.sale,
                    flb_sale_id : response.data.case.flb_sales_id,
                    send_mail_arr : {
                        personId : "",
                        mail_to : response.data.case.contact_email,
                        mail_cc : response.data.case.contact_email,
                        mail_subject : `案件「${response.data.case.case_name}」の要員提案`,
                        mail_contact_name: response.data.case.contact_name,
                        mail_message : "",
                        attachment:"",
                    },
                })
                // setData(response.data.person);
            }).catch((error)=>{
                if(error.response.status == 403){
                    navigate('/403');
                }
                else if(error.response.status == 404 || error.response.status == 402){
                    navigate('/404');
                }
                setState({
                    ...state,
                    loading : false,
                    errorMessage : error.message
                })
            });
        }
    }, [encode, location]);

    const navigateToPrevious = () => {
        if (localStorage.getItem('previousRoute') && localStorage.getItem('previousRoute')!= location.pathname) {
          navigate(localStorage.getItem('previousRoute')!);
        } else {
          navigate('/cases'); // Fallback to home if previousPath is not set
        }
      };

    let {loading, cases_arr, assignment_arr, work_arr, eigyou, hr , sale,flb_sale_id, send_mail_arr, errorMessage} = state;
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const sayHello = () => {
        if(person_ids.length == 0){
            alert("要員を選択してください。");
            return false;
        }
        let data = {};
        data["id"] = person_ids;
        data["case_id"] =  cases_arr["case_id"];
        PersonService.personlist(data).then((response)=>{
            setState({
                ...state,
                send_mail_arr : {
                    personId : person_ids.join(","),
                    mail_to : send_mail_arr.mail_to,
                    mail_cc : send_mail_arr.mail_cc,
                    mail_subject : send_mail_arr.mail_subject,
                    mail_contact_name: send_mail_arr.mail_contact_name,
                    mail_message : response.data.mail_message.replaceAll('&nbsp;', ' '),
                    attachment:"",
                },
            })
            setOpen(true);
        });
      };
 
    const handleCaseAssignment = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(isChecked === true){
            person_ids.push(value);
            setPersonID(person_ids);
        }
        else{            
            const person_id = person_ids.filter(function(person_id){
                return person_id != value; 
             });
             setPersonID(person_id);
        }
    }

    
    const copy = () => {
        let id =  cases_arr["case_id"];
        localStorage.setItem("case_encode" , cases_arr["encode"]);

        CaseListService.copy(id).then((response) => {
            const someUrl = "/" +response.data;
            // const someUrl = "http://localhost:3000/" +response.data;
            window.location.href = someUrl;
        }).catch((error)=>{
            if(error.response.status == 403){
             alert(error.response.data.message);
            }
         });
    }
    
    const handleAttachment = function (e:any) {
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(isChecked === true){
            setState({
                ...state,
                send_mail_arr : {
                    personId : person_ids.join(","),
                    mail_to : send_mail_arr.mail_to,
                    mail_cc : send_mail_arr.mail_cc,
                    mail_subject : send_mail_arr.mail_subject,
                    mail_contact_name: send_mail_arr.mail_contact_name,
                    mail_message : send_mail_arr.mail_message,
                    attachment:"1",
                },
            })

        }
        else{            
            setState({
                ...state,
                send_mail_arr : {
                    personId : person_ids.join(","),
                    mail_to : "",
                    mail_cc : "",
                    mail_subject : "",
                    mail_contact_name: "",
                    mail_message : send_mail_arr.mail_message,
                    attachment:"",
                },
            })
        }
    }
    let emailSend = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        CaseListService.sendMail(state.send_mail_arr).then((response) => {
            window.location.reload();
        //    window.location.href = "/cases";
        })

    };

    let assignmentFinish =  (e:any,assignment_id:any) => {
        if(!window.confirm("割り当て終了しますか？")){
            return false;
        }

        AssignmentService.assignmentFinish(assignment_id).then((response) =>{
           window.location.reload();
        })

    }

    let assignmentDelete =  (e:any,assignment_id:any) => {
        if(!window.confirm("削除を実施します、大丈夫ですか？")){
            return false;
        }

        AssignmentService.assignmentDelete(assignment_id).then((response) =>{
           window.location.reload();
        });
    }

    // if(cases_arr['required_skill'] != null){
    //     if(Array.isArray(cases_arr['required_skill'])){
    //         cases_arr['required_skill'] = cases_arr['required_skill'].join(', ');
    //     }else{
    //         cases_arr['required_skill'] =  Object.entries(cases_arr['required_skill'])
    //             .map( ([key, value]) => `${value}` )
    //     }
    // }
    const navigate = useNavigate();
  
    let redirectBack  = function () {
        if(location.pathname !== localStorage.getItem('previousRoute')){
            navigate(-1);
        }else{
            window.location.href = "/cases";
        }
    }
    

    const personDetail = function (encode:any) {
        if(encode != null){
            localStorage.setItem('previousRoute', location.pathname);
            window.location.href = `/persons/${encode}`
        }
    }
 
    return(
        <React.Fragment>
          <DetailTopbar /> 
        <div className="container">
            <div className="detail">
                <div className = "row">
                    <div className="col-md-4 col-sm-6 col-xs-6">
                        <h3 className='header-left-design'>案件情報<span className='span-title'> (案件ID : {cases_arr['case_id']})</span> </h3>
                    </div>
                    <div className="col-md-4  col-sm-6 col-xs-6">
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </div>
                    <div className="col-md-2  col-sm-6 col-xs-6">
                    {
                                    (() => {
                                         if(login_user.role ==2 || login_user.role ==9){
                                            return(<button className="btn_standard float-right" id="case-show-send-mail" style={assignment_arr.length <= 0 ? {opacity:"0.5",position:"relative",top:"2em"} : {opacity:"1",position:"relative",top:"2em"}}  disabled={(assignment_arr.length <= 0)? true:false}
                                                onClick={() => {
                                                sayHello();
                                            }
                                        }
                                            >
                                            取引先へ提案
                                            </button>)
                                        }else if (login_user.role == 1){
                                            if(login_user.user_id == flb_sale_id){
                                                return(<button className="btn_standard float-right" id="case-show-send-mail"
                                                onClick={() => {
                                                sayHello();
                                                
                                            }
                                        } disabled={(assignment_arr.length <= 0)? true:false}
                                            >
                                            取引先へ提案
                                            </button>)
                                            }
                                        }
                                    })()
                                    }
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-6 mt_2">
                                {
                                    (() => {

                                         if(login_user.role ==2 || login_user.role ==8 ||login_user.role ==9){
                                            return(<Link to={`/cases/${cases_arr["case_id"]}/edit`} className="header-back-btn">
                                            案件編集
                                        </Link>)
                                        }else if (login_user.role == 1){
                                            if(login_user.user_id ==flb_sale_id){
                                                return(<Link to={`/cases/${cases_arr["case_id"]}/edit`} className="header-back-btn">
                                                案件編集
                                            </Link>)
                                            }
                                        }
                                    })()
                                }
                        {/* <Link to={`/cases/${cases_arr["case_id"]}/edit`} className="header-back-btn">
                            案件編集
                        </Link> */}
                        <button onClick={() => {navigateToPrevious()}} className="header-back-btn-white" style={{position:"relative",top:"2px"}}>戻る</button>
                    </div>
                </div>
                {
                    (() => {

                            if(login_user.role ==1 || login_user.role==2 || login_user.role ==8 ||login_user.role ==9){
                                return(
                                    <div className = "row">
                                        <a href = "#"
                                          onClick={() => {copy()}}>コピー作成</a>
                                    </div>
                                )
                            }
                            })()
                }
                <div className="row">
                    <div className="col-6 col-sm-6 col-xs-12">
                        <CaseMainInfo cases_arr={cases_arr} />

                        {/* <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>基本情報 </h4>   
                                    </div>
                                </div>
                                <hr className="reset" />
                                <table className="new-table" >
                                    <tbody>
                                        <tr>
                                            <th>案件ステータス：</th>
                                            <td>{cases_arr['case_status']}</td>
                                        </tr>
                                        <tr>
                                            <th>案件名：</th>
                                            <td>{cases_arr['case_name']}</td>
                                        </tr>
                                        <tr>
                                            <th>一行アピール：</th>
                                            <td>{cases_arr['appeal_cmnt']}</td>
                                        </tr>
                                        <tr>
                                            <th>職種：</th>
                                            <td>{cases_arr['category']}</td>
                                        </tr>
                                        <tr>
                                            <th>募集エリア：</th>
                                            <td>{cases_arr['recurit_area']}</td>
                                        </tr>
                                        <tr>
                                            <th>案件概要：</th>
                                            <td>{cases_arr['case_overview']}</td>
                                        </tr>
                                        <tr>
                                            <th>要求内容：</th>
                                            <td>{cases_arr['requisition']}</td>
                                        </tr>
                                        <tr>
                                            <th>要求スキル：</th>
                                            <td>
                                          
                                            {cases_arr['required_skill']}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>期間種別：</th>
                                            <td>{cases_arr['case_period_type']}</td>
                                        </tr>
                                        <tr>
                                            <th>開始年月日：</th>
                                            <td>{cases_arr['start_date_plan']}</td>
                                        </tr>
                                        <tr>
                                            <th>終了予定年月日：</th>
                                            <td>{cases_arr['end_date_plan']}</td>
                                        </tr>
                                        <tr>
                                            <th>募集人数：</th>
                                            <td>{cases_arr['recruit_num']}</td>
                                        </tr>
                                        <tr>
                                            <th>作業場所：</th>
                                            <td>{cases_arr['work_place']}</td>
                                        </tr>
                                        {
                                        (() => {
                                            if([1,2,4,8,9].includes(login_user.role)){
                                                return(
                                                        <tr>
                                                            <th>予算：</th>
                                                            <td>{cases_arr['budget']}</td>
                                                        </tr>
                                                         )}
                                            })()
                                        }
                                        {
                                        (() => {
                                            if(login_user.role != 7){
                                                return(
                                                        <tr>
                                                            <th>精算：</th>
                                                            <td>{cases_arr['settlement']}</td>
                                                        </tr>
                                                    )}
                                                })()
                                         }
                                    </tbody>
                                    </table>
                                   
                            <hr className="reset" />
                            <table className="new-table">
                                <tbody>
                                    <tr>
                                        <th>予定受注契約：</th>
                                        <td>{cases_arr['expect_contract_type']}</td>
                                    </tr>

                                    <tr>
                                        <th>公開日：</th>
                                        <td>{cases_arr['publish_date']}</td>
                                    </tr>
                                    <tr>
                                        <th>募集期限：</th>
                                        <td>{cases_arr['recruit_deadline']}</td>
                                    </tr>
                                    <tr>
                                        <th>面談回数：</th>
                                        <td>{cases_arr['interview_num']}回</td>
                                    </tr>

                                    <tr>
                                        <th>案件依頼日：</th>
                                        <td>{cases_arr['requested_date']}</td>
                                    </tr>

                                    <tr>
                                        <th>提案締切日：</th>
                                        <td>{cases_arr['proposal_deadline']}</td>
                                    </tr>
                                    {
                                        (() => {
                                            if(login_user.role != 7){
                                                return(
                                                <tr>
                                                        <th>営業担当者：</th>
                                                        <td>{cases_arr['saler-name']}</td>
                                                    </tr>
                                            )}
                                        })()
                                    }
                                    {
                                    (() => {
                                        if(login_user.role != 7){
                                            return(
                                                <tr>
                                                    <th>担当営業メアド：</th> 
                                                    <td>{cases_arr['saler-email']}</td> 
                                                </tr>
                                                )}
                                            })()
                                    }
                                    {
                                    (() => {
                                        if(login_user.role != 7){
                                            return(
                                                    <tr>
                                                        <th>担当営業電話：</th>
                                                        <td>{cases_arr['saler-tel_no']}</td>
                                                    </tr> 
                                                )}
                                            })()
                                    }
                                    {
                                    (() => {
                                        if(login_user.role != 8){
                                            return(
                                                <tr>
                                                    <th>HR担当者：</th>
                                                    <td>{cases_arr['hr-name']}</td>
                                                </tr>
                                                 )}
                                                })()
                                    }
                                    {
                                        (() => {
                                            if(login_user.role != 8){
                                                return(
                                                <tr>
                                                    <th>担当ＨＲメアド：</th>
                                                    <td>{cases_arr['hr-email']}</td>
                                                </tr>
                                                 )}
                                                })()
                                    }
                                    {
                                        (() => {
                                            if(login_user.role != 8){
                                                return(
                                                    <tr>
                                                        <th>担当ＨＲ電話：</th>
                                                        <td>{cases_arr['hr-tel_no']}</td>
                                                    </tr>
                                                    )}
                                                })()
                                    }
                                    {
                                    (() => {
                                         if(login_user.role !=7 && login_user.role !=8){
                                            return(
                                            <tr>
                                                <th>公開フラグ：</th>
                                                <td>
                                                {(cases_arr['published_flg'] == 0 )? "未公開": "公開"}
                                                </td>
                                            </tr>
                                            )
                                        }
                                    })()
                                    }
                                    <tr>
                                        <th>特記事項：</th>
                                        <td>{cases_arr['remarks']}</td>
                                    </tr>
                                    {
                                    (() => {
                                         if(login_user.role !=7 && login_user.role !=8){
                                            return(
                                            <tr>
                                                <th>社内共有メモ：</th>
                                                <td>{cases_arr['flb_shared_cmt']}</td>
                                            </tr>
                                            )
                                        }
                                    })()
                                    }
                                    </tbody>
                                    </table>
                                </div>
                         </div> */}

                         {
                            (() => {
                                    if(login_user.role ==1 && login_user.user_id == cases_arr['flb_sales_id']){
                                    return(
                                        <div className="card mt-3">
                                        <div className="card-body">
                                            <div className="row">
                                                 <div className="col-6">
                                                    <h4> 取引先情報</h4>
                                                    </div>
                                                    <div className="col-6">
                                                    </div>
                                                </div>
                                                <hr className="reset" />    
                                                <div className="row">
                                                    <table className="new-table">
                                                        <tbody>
                                                            {
                                                                (() => {
                                                                    if(login_user.role ==1 || login_user.role ==2 || login_user.role ==4 || login_user.role==9){
                                                                        return(
                                                                            <tr>
                                                                                <th>取引先ID：</th>
                                                                                <td>{eigyou['customer_company']}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                            {
                                                                (() => {
                                                                    if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                                        return(
                                                                            <tr>
                                                                                <th>取引先部門：</th>
                                                                                <td>{eigyou['customer_dept']}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                            {
                                                                (() => {
                                                                    if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                                        return(
                                                                            <tr>
                                                                                <th>取引先担当者：</th>
                                                                                <td className="text-wrap">{eigyou['contact_name']}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                            {
                                                                (() => {
                                                                    if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                                        return(
                                                                            <tr>
                                                                                <th>取引先担当者メール：</th>
                                                                                <td>{eigyou['contact_email']}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                            {
                                                                (() => {
                                                                    if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                                        return(
                                                                            <tr>
                                                                                <th>取引先担当者電話：</th>
                                                                                <td>{eigyou['contact_tel']}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                     )
                                }else{
                                    <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                            {
                                                (() => {
                                                     if(login_user.role !=3 && login_user.role !=7){
                                                        return(
                                                            <h4> 取引先情報</h4>
                                                        )
                                                    }
                                                })()
                                                }
                                                {/* <h4> 営業情報</h4> */}
                                            </div>
                                            <div className="col-6">
                                            </div>
                                        </div>
                                    <hr className="reset" />    
                                    <div className="row">
                                    <table className="new-table">
                                        <tbody>
                                            {
                                                (() => {
                                                     if(login_user.role ==1 || login_user.role ==2 || login_user.role ==4 || login_user.role==9){
                                                        return(
                                                            <tr>
                                                                <th>取引先ID：</th>
                                                                <td>{eigyou['customer_company']}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                     if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                        return(
                                                            <tr>
                                                                <th>取引先部門：</th>
                                                                <td>{eigyou['customer_dept']}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                     if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                        return(
                                                            <tr>
                                                                <th>取引先担当者：</th>
                                                                <td className="text-wrap">{eigyou['contact_name']}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                     if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                        return(
                                                            <tr>
                                                                <th>取引先担当者メール：</th>
                                                                <td>{eigyou['contact_email']}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                     if(login_user.role ==1 || login_user.role ==2 || login_user.role==9){
                                                        return(
                                                            <tr>
                                                                <th>取引先担当者電話：</th>
                                                                <td>{eigyou['contact_tel']}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    
                                    </div>
                                </div>
                                }
                            })()
                            }
                    </div>
                    <div className="col-6">
                  
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-7">
                                    <h4>割リ当て状況</h4>
                                </div>
                                <div className="col-md-5">
                              
                                    {
                                    (() => {
                                         if(login_user.role !=5 && login_user.role !=6 && login_user.role!=7){
                                            return(
                                                <Link to={`/cases/assignment/${cases_arr["encode"]}`} className="text-decoration-none text-success fw-bold toriate-btn blue assign_btn btn_standard">
                                                    割リ当て追加
                                                </Link>
                                            )
                                        }
                                    })()
                                    }
                                    {/* <Link to={`/cases/assignment/${cases_arr["encode"]}`} className="text-decoration-none text-success fw-bold toriate-btn blue assign_btn btn_standard">
                                        割リ当て追加
                                    </Link> */}
                                </div>
                            </div>
                        
                                {/* <a href="#" className="toriate-btn blue">割リ当て追加</a> */}

                            <hr className="reset" />
                            {
                            assignment_arr.length > 0 && assignment_arr.map((assignment, index)=> {
                                let i = {index}.index;
                                let proposal = new Number(assignment['proposal_price']);
                                let flg = (assignment['adoption_flg'] == "0")?" 検討中":((assignment['adoption_flg'] == "1")? "採用": "不採用");

                                return(
                                    <div className="">
                                        <span><input type="checkbox" name="person_check" onChange={e => handleCaseAssignment(e)}  value={(assignment['person'] != null)?assignment['person']['person_id']:''} />  
                                            {(assignment['person'] != null)?<Link to='' onClick={()=>personDetail(assignment['person']['encode'])} style={{textDecoration:"none",fontWeight:"bold",color:"rgb(0, 23, 193)",position:"relative",bottom:"2px"}}>    {(assignment['person'] != null)?assignment['person']['InitialName']:''}</Link>:''}
                                            </span><br/>
                                            {
                                                (() => {
                                                    if(login_user.role !=5 && login_user.role!=7){
                                                        return(
                                                            <tr>
                                                                <th>希望単価：</th>
                                                                <td>{proposal.toLocaleString()+ "　円"}</td>
                                                            </tr>
                                                            // <span> 希望単価： {proposal.toLocaleString()+ "　円"}</span>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    if(login_user.role !=5 && login_user.role!=7){
                                                        // return(
                                                        //     <br/>
                                                        // )
                                                    }
                                                })()
                                            }
                                        {/* <span> 希望単価： {proposal.toLocaleString()+ "　円"}</span><br/> */}
                                        <tr>
                                            <th>提案日：</th>
                                            <td>{assignment['proposal_date']}</td>
                                        </tr>
                                        <tr>
                                            <th>面談日：</th>
                                            <td> {assignment['interview_date']} {assignment['interview_time']}</td>
                                        </tr>
                                        <tr>
                                            <th>面談場所：</th>
                                            <td> {assignment['interview_place']}</td>
                                        </tr>
                                        <tr>
                                            <th>面談評価：</th>
                                            <td> {assignment['interview_remark']}</td>
                                        </tr>
                                        <tr>
                                            <th>最終結果：</th>
                                            <td> {flg}</td>
                                        </tr>
                                        <tr>
                                            <th>稼働開始日：</th>
                                            <td> {assignment['work_start_date']} </td>
                                        </tr>
                                        {/* <span> 提案日：{assignment['proposal_date']}</span><br/> */}
                                        {/* <span> 面談日： {assignment['interview_date']} {assignment['interview_time']}</span><br/> */}
                                        {/* <span> 面談場所： {assignment['interview_place']} </span><br/> */}
                                        {/* <span> 面談評価：{assignment['interview_remark']} </span><br/> */}
                                        {/* <span> 最終結果： {flg}</span><br/> */}
                                        {/* <span> 稼働開始日：{assignment['work_start_date']}</span> <br/> */}
                                        {
                                            (() => {
                                                if(login_user.role!=7){
                                                    return(
                                                        <Link to={`/cases/assignment/edit/${assignment["assignment_id"]}`} className="text-decoration-none text-success fw-bold btn-delete flit-right delete-assignment">
                                                            編集
                                                        </Link>
                                                    )
                                                }
                                            })()
                                        }                  
                                        {/* <button  onClick={(e) => {
                                        assignmentDelete(e,assignment["assignment_id"]);
                                        }} className="btn-finish flit-right finish-wariate">取り消し</button> */}
                                        {
                                            (() => {
                                                if(login_user.role!=7){
                                                    return(
                                                        <button  onClick={(e) => {
                                                            assignmentFinish(e,assignment["assignment_id"]);
                                                            }} className="btn-finish flit-right finish-wariate">割り当て終了</button>
                                                    )
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if(login_user.role!=7){
                                                    return(
                                                        <button  onClick={(e) => {
                                                            assignmentDelete(e,assignment["assignment_id"]);
                                                            }} className="btn-finish flit-right finish-wariate">取り消し</button>
                                                    )
                                                }
                                            })()
                                        }
                                        {/* <button  onClick={(e) => {
                                        assignmentFinish(e,assignment["assignment_id"]);
                                        }} className="btn-finish flit-right finish-wariate">割り当て終了</button> */}
                                     {/* {
                                            (() => {
                                                if(login_user.role!=7){
                                                    return(
                                                        <Link to={`/cases/assignment/edit/${assignment["assignment_id"]}`} className="text-decoration-none text-success fw-bold btn-delete flit-right delete-assignment">
                                                            編集
                                                        </Link>
                                                    )
                                                }
                                            })()
                                     } */}
                                    {/* <Link to={`/cases/assignment/edit/${assignment["assignment_id"]}`} className="text-decoration-none text-success fw-bold btn-delete flit-right delete-assignment">
                                        編集
                                    </Link> */}
                                    <hr className="reset" /><br/>
                                    </div>
                                    )
                                })
                            } 
                        </div>
                    </div>  
            {
                work_arr.length > 0 && work_arr.map((work, index)=> {
                    let i = {index}.index;
                    if(login_user.role!=7){
                    return(   
                 <div className="card mt-3">
                    <div className="card-body">
                    <h4>稼働中要員情報</h4>
                           
								<div>
                                <tr>
                                    <th>技術者：</th>
                                    <td><Link to= "" onClick={()=>personDetail(work['encode'])}>   {work['full_name']}</Link></td>
                                </tr>
								{/* <span> 技術者： 
                                <Link to= "" onClick={()=>personDetail(work['encode'])}>   {work['full_name']}</Link>
                                </span><br/> */}
                                 <tr>
                                    <th>稼働開始日：</th>
                                    <td>{work['work_start_date']}</td>
                                </tr>
                                <tr>
                                    <th>勤務場所：</th>
                                    <td>{work['work_place']}</td>
                                </tr>
                                <tr>
                                    <th>稼働終了予定日：</th>
                                    <td>{work['work_end_date_plan']}</td>
                                </tr>
                                {/* <span> 稼働開始日： {work['work_start_date']}</span><br/> */}
                                {/* <span> 勤務場所：{work['work_place']}</span><br/>
                                <span> 稼働終了予定日： {work['work_end_date_plan']}</span><br/> */}
                                {/* {(login_user.role!=8)?<span> 社内共有メモ： {work['flb_shared_cmt']}</span>:""} */}
                                {
                                            (() => {
                                                if(login_user.role!=8){
                                                    return(
                                                       <tr>
                                                            <th> 社内共有メモ： </th>
                                                            <td>{work['flb_shared_cmt']}</td>
                                                       </tr>
                                                    )
                                                }
                                            })()
                                }
                                <hr /> 
                                </div>
                           
                    </div>
                </div>       
                 )}
                })
            }   
                </div>
            </div>
        </div>
    </div>
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >                                
            <Box sx={style}>
                <div className="row">
                    <div className="col-12">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                <form className="form" onSubmit={emailSend}>
                   <div className="form-group">
                        <label htmlFor="exampleInputEmail1">件名:</label> 
                        <input type="text" className="form-control" name="mail_subject"  value={`案件「${cases_arr['case_name']}」の要員提案`} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">取引先担当者:</label> 
                        <input type="text" className="form-control" name="mail_contact_name" value={cases_arr['contact_name']} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">TO:</label> 
                        <input type="text" className="form-control" name="mail_to"  value={cases_arr['contact_email']}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">CC:</label> 
                        <input type="text" className="form-control" name="mail_cc" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">メール内容:</label> 
                        <textarea name="mail_message" rows={12} cols={40} className="form-control"  value={state.send_mail_arr.mail_message} id="send_mail_model_text">

                        </textarea>
                    </div>
                    <div className="form-group text-center">
                        <input type="checkbox" name="attachment" onChange={e => handleAttachment(e)}  value="" />
                        <label htmlFor="resume">履歴書も添付する</label>
                        <input type="hidden" name="personId" id="person-id" />
                    </div>
                    
                    <div className="form-group mt-5 text-center">
                         <button type="reset" className="button modal_cancel_btn" onClick={handleClose}>キャンセル</button>
                        <button type="submit" className="button submit-btn btn_standard">送信</button>
                    </div>
                </form>
            {/* <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                Text in a modal
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
            </Box>
        </Modal>
        </React.Fragment>
    );
};
export default CaseDetail;