// src/components/ForbiddenPage.js
import React from 'react';
import { Link } from 'react-router-dom'; // if you're using react-router

const ForbiddenPage = () => {
  return (
    <div className="flex-center position-ref full-height">
      <div className= "code">403</div>
      <div className="message"> 権限がない</div>
    </div>
  );
};

export default ForbiddenPage;
