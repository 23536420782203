import { Bar } from "react-chartjs-2";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '',
    },
  },
};

function BarChart({ chartData } :any) {
  return (
    <div className="chart-container">
      {/* <h2 style={{ textAlign: "center" }}>Bar Chart</h2> */}
      <Bar
        data={chartData}
        // options={{
        //   plugins: {
        //     title: {
        //       display: true,
        //       text: "Users Gained between 2016-2020"
        //     },
        //     legend: {
        //       display: false
        //     }
        //   }
        // }}
        options={options}
      />
    </div>
  );
};
export default BarChart;

