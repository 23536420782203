import React, {useEffect, useState} from 'react';
import SaleryService from "../../services/SaleryService";
import PersonService from "../../services/PersonService";
 
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo,truncateDecimals} from "../../components/common/Helpers";
 
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import {SaleryModel} from "../../models/Salery";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import $ from 'jquery';
 
interface URLParam {
    id : string;
}
 
interface IState{
    salery : SaleryModel;
    company_list : [];
    contract_type_list : [];
    job_type_list : [];
    salery_stage_list : [];
    salery_grade_list : [];
    ins_calc_flg_list : [];
    tax_calc_type_list : [];
}
interface IProps{}
let SaleryCreate:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();
 
    let [state , setState] = useState<IState>({
        salery : {
            person_id: 0,
            emp_id : "",
            company_name : "FloBoard",
            org_name : "",
            full_name : "",
            kana_name : "",
            email : "",
            birthday : "",
            contract_type : "1",
            job_type : "1",
            position : "",
            salery_stage : "S",
            salery_grade : "1",
            base_sal : 0,
            job_allow :  0,
            mng_duty_allow :  0,
            housing_allow :  0,
            qual_allow :  0,
            adjust_allow :  0,
            fixed_overtime_allow : 0,
            adj_hours_low :  0,
            adj_price_deduct:  0,
            adj_hours_high :  0,
            adj_price_excess :  0,
            travel_expense:  0,
            commute_allow:  0,
            taxable_pay: 0,
            nontaxable_pay: 0,
            gross_pay: 0,
            standard_monthly_rem: "",
            health_ins_1:  0,
            welfare_pension_1:  0,
            emp_ins_rate_1:  0,
            emp_ins_1:  0,
            ins_total_1 :  0,
            health_ins_2:  0,
            welfare_pension_2:  0,
            emp_ins_rate_2:  0,
            emp_ins_2:  0,
            ins_total_2 : 0,
            ins_calc_flg:  0,
            health_welfare_total:  0,
            ins_total:  0,
            dependants_num: "",
            tax_calc_type:  "0",
            taxable_net_amnt:  0,
            tax_pay:  0,
            inhabitant_tax:  0,
            deduction_total: 0,
            payment_amount: 0,
            employment_date: "",
            reemploy_date: "",
            vacation_startday: "",
            retirement_date: "",
            remarks: "",
        },
        company_list : [],
        contract_type_list : [],
        job_type_list : [],
        salery_stage_list : [],
        salery_grade_list : [],
        ins_calc_flg_list : [],
        tax_calc_type_list : []
    });
    const [person_list, setPersonList] = useState([]);
    const [person_field, setPersonFieldList] = useState([]);
    const [person_list_display, setPersonListDisplay] = useState("none");  
    const [person_search_result , setSearchResult] = useState("");
 
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedBirthday, setSelectedBirthday] = useState(null);
    const [selectedEmploymentDate, setSelectedEmploymentDate] = useState(null);
    const [selectedReemployDate, setSelectedReemployDate] = useState(null);
    const [selectedVacationStartDay, setSelectedVacationStartDay] = useState(null);
    const [selectedRetirementDate, setSelectedRetirementDate] = useState(null);
 
    
    
    
 
    useEffect(()=>{
        if(id != undefined){
            SaleryService.edit(id).then((response)=>{
                setState({
                    ...state,
                    salery:response.data.salery,
                    company_list : response.data.select_options.company_name,
                    contract_type_list : response.data.select_options.contract_type,
                    job_type_list : response.data.select_options.job_type,
                    salery_stage_list : response.data.select_options.salery_stage,
                    salery_grade_list : response.data.select_options.salery_grade,
                    ins_calc_flg_list : response.data.select_options.ins_calc_flg,
                    tax_calc_type_list : response.data.select_options.tax_calc_type
                })
                setTaxablePay(response.data.salery.taxable_pay);
                setGrossPay(response.data.salery.gross_pay);
                setHealthIns1(response.data.salery.health_ins_1);
                setHealthIns2(response.data.salery.health_ins_2);
                setWelfarePension1(response.data.salery.welfare_pension_1);
                setWelfarePension2(response.data.salery.welfare_pension_2);
                setEmpIns1(response.data.salery.emp_ins_1);
                setEmpIns2(response.data.salery.emp_ins_2);
                setInsTotal1(response.data.salery.ins_total_1);
                setInsTotal2(response.data.salery.ins_total_2);
                setInsTotal(response.data.salery.ins_total);
                setHealthWelfareTotal(response.data.salery.health_welfare_total);
                setDeductionTotal(response.data.salery.deduction_total);
                setPaymentAmount(response.data.salery.payment_amount);
                setTaxableNetAmt(response.data.salery.taxable_net_amnt);
                setNonTaxablePay(response.data.salery.nontaxable_pay);
                setTaxPay(response.data.salery.tax_pay);
                setInhabitantTax(response.data.salery.inhabitant_tax);
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }else{
            SaleryService.create().then((response)=>{
                console.log(response);
                setState({
                    ...state,
                    company_list : response.data.select_options.company_name,
                    contract_type_list : response.data.select_options.contract_type,
                    job_type_list : response.data.select_options.job_type,
                    salery_stage_list : response.data.select_options.salery_stage,
                    salery_grade_list : response.data.select_options.salery_grade,
                    ins_calc_flg_list : response.data.select_options.ins_calc_flg,
                    tax_calc_type_list : response.data.select_options.tax_calc_type
                })
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }
       
 
    }, [id]);
    let {salery, company_list, contract_type_list, job_type_list, salery_stage_list, salery_grade_list, ins_calc_flg_list, tax_calc_type_list} = state;
 
    let assignPersonId = (e: React.ChangeEvent<HTMLSelectElement>): void => { // change event type declare in React
        var index = e.target.selectedIndex;
        setSearchResult(e.target[index].innerHTML);
        let full_name = e.target.selectedOptions[0]?.getAttribute('data-full_name');
        let kana_name = e.target.selectedOptions[0]?.getAttribute('data-kana_name')
        setState({
            salery : {
                ...state.salery,
                person_id : parseInt(e.target.value),
                full_name : (full_name != undefined)?full_name : "",
                kana_name : (kana_name != undefined)?kana_name : ""
            },
            company_list : company_list,
            contract_type_list : contract_type_list,
            job_type_list : job_type_list,
            salery_stage_list : salery_stage_list,
            salery_grade_list : salery_grade_list,
            ins_calc_flg_list : ins_calc_flg_list,
            tax_calc_type_list : tax_calc_type_list
        });  
        setPersonListDisplay("none");       
    };
 
 
    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        state.salery.taxable_pay = taxable_pay;
        state.salery.gross_pay = gross_pay;
        state.salery.health_ins_1 = health_ins_1;
        state.salery.health_ins_2 = health_ins_2;
        state.salery.welfare_pension_1 = welfare_pension_1;
        state.salery.welfare_pension_2 = welfare_pension_2;
        state.salery.emp_ins_1 = emp_ins_1;
        state.salery.emp_ins_2 = emp_ins_2;
        state.salery.ins_total_1 = ins_total_1;
        state.salery.ins_total_2 = ins_total_2;
        state.salery.ins_total = ins_total;
        state.salery.health_welfare_total = health_welfare_total;
        state.salery.deduction_total = deduction_total;
        state.salery.payment_amount = payment_amount;
        state.salery.taxable_net_amnt = taxable_net_amnt;
        state.salery.nontaxable_pay = nontaxable_pay;
        state.salery.tax_pay = tax_pay;
        state.salery.inhabitant_tax = inhabitant_tax;
        // setState({
        //     salery : {
        //         ...state.salery,
        //         taxable_pay: taxable_pay,
        //         gross_pay : gross_pay,
        //         health_ins_1 : health_ins_1,
        //         health_ins_2 : health_ins_2,
        //         welfare_pension_1 : welfare_pension_1,
        //         welfare_pension_2 : welfare_pension_2,
        //         emp_ins_1 : emp_ins_1,
        //         emp_ins_2 : emp_ins_2,
        //         ins_total_1 : ins_total_1,
        //         ins_total_2 : ins_total_2,
        //         ins_total : ins_total,
        //         health_welfare_total : health_welfare_total,
        //         deduction_total : deduction_total.toString(),
        //         payment_amount : payment_amount.toString(),
        //         taxable_net_amnt : taxable_net_amnt,
        //         nontaxable_pay : nontaxable_pay,
        //         tax_pay : tax_pay,
        //         inhabitant_tax : inhabitant_tax
        //     },
        //     company_list : company_list,
        //     contract_type_list : contract_type_list,
        //     job_type_list : job_type_list,
        //     salery_stage_list : salery_stage_list,
        //     salery_grade_list : salery_grade_list,
        //     ins_calc_flg_list : ins_calc_flg_list,
        //     tax_calc_type_list : tax_calc_type_list
        // }); 
 
        if(id != undefined){
            SaleryService.update(state.salery, id).then((response) => {
               window.location.href = "/salery/list";
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
 
            })
        }else{
            SaleryService.store(state.salery).then((response) => {
                console.log(response);
                if(response.data.data[0] == "exist"){
                    alert("同じ要員IDの」社員が未既に登録しているので、登録できません。")
                }else{
                    window.location.href = "/salery/list";
                }
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
 
            })
        }
    };
  
    const [taxable_pay, setTaxablePay] = useState(0.0);
    const [gross_pay, setGrossPay] = useState(0.0);
    const [health_ins_1 , setHealthIns1] = useState(0.0);
    const [health_ins_2, setHealthIns2] = useState(0.0);
    const [welfare_pension_1 , setWelfarePension1] = useState(0.0);
    const [welfare_pension_2, setWelfarePension2] = useState(0.0);
    const [emp_ins_1, setEmpIns1] = useState(0.0);
    const [emp_ins_2, setEmpIns2] = useState(0.0);
    const [ins_total_1, setInsTotal1] = useState(0.0);
    const [ins_total_2, setInsTotal2] = useState(0.0);
    const [ins_total, setInsTotal] = useState(0.0);
    const [health_welfare_total, setHealthWelfareTotal] = useState(0.0);
    const [deduction_total, setDeductionTotal] = useState(0.0);
    const [payment_amount, setPaymentAmount] = useState(0.0);
    const [taxable_net_amnt, setTaxableNetAmt] = useState(0.0);
    const [nontaxable_pay, setNonTaxablePay] = useState(0.0);
    const [tax_pay, setTaxPay] = useState(0.0);
    const [inhabitant_tax, setInhabitantTax] = useState(0.0);
 
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
       
        if(event.target.name == "person_id"){
            setSearchResult("");
        }
        
        if(['company_name', 'org_name', 'full_name', 'kana_name', 'email', 'position', 'emp_id'].includes(event.target.name)){
            setState({
                salery : {
                    ...state.salery,
                    [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
                },
                company_list : company_list,
                contract_type_list : contract_type_list,
                job_type_list : job_type_list,
                salery_stage_list : salery_stage_list,
                salery_grade_list : salery_grade_list,
                ins_calc_flg_list : ins_calc_flg_list,
                tax_calc_type_list : tax_calc_type_list
            }); 
        }else{
            setState({
                salery : {
                    ...state.salery,
                    [event.target.name] : parseFloat(event.target.value)  // input name 'username' set value 'event target value'
                },
                company_list : company_list,
                contract_type_list : contract_type_list,
                job_type_list : job_type_list,
                salery_stage_list : salery_stage_list,
                salery_grade_list : salery_grade_list,
                ins_calc_flg_list : ins_calc_flg_list,
                tax_calc_type_list : tax_calc_type_list
            }); 
        }
        
        let cal_tax_pay = 0.0;
 

        switch (event.target.name) {
            case "base_sal":
                cal_tax_pay =  parseFloat(event.target.value) +state.salery.job_allow+state.salery.mng_duty_allow+state.salery.housing_allow+
                         state.salery.qual_allow+state.salery.adjust_allow+state.salery.fixed_overtime_allow;
                setTaxablePay(truncateDecimals(cal_tax_pay, 0));
                autoCount(truncateDecimals(cal_tax_pay, 0), nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "job_allow":
                cal_tax_pay =  state.salery.base_sal+parseFloat(event.target.value)+state.salery.mng_duty_allow+state.salery.housing_allow+
                            state.salery.qual_allow+state.salery.adjust_allow+state.salery.fixed_overtime_allow;
                setTaxablePay(truncateDecimals(cal_tax_pay, 0));
                autoCount(truncateDecimals(cal_tax_pay, 0), nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "mng_duty_allow":
                cal_tax_pay =  state.salery.base_sal+state.salery.job_allow+parseFloat(event.target.value)+state.salery.housing_allow+
                            state.salery.qual_allow+state.salery.adjust_allow+state.salery.fixed_overtime_allow;
                setTaxablePay(truncateDecimals(cal_tax_pay, 0));
                autoCount(truncateDecimals(cal_tax_pay, 0), nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "housing_allow":
                cal_tax_pay =  state.salery.base_sal+state.salery.job_allow+state.salery.mng_duty_allow+parseFloat(event.target.value)+
                            state.salery.qual_allow+state.salery.adjust_allow+state.salery.fixed_overtime_allow;
                setTaxablePay(truncateDecimals(cal_tax_pay, 0));
                autoCount(truncateDecimals(cal_tax_pay, 0), nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "qual_allow":
                cal_tax_pay =  state.salery.base_sal+state.salery.job_allow+state.salery.mng_duty_allow+state.salery.housing_allow+
                            parseFloat(event.target.value)+state.salery.adjust_allow+state.salery.fixed_overtime_allow;
                setTaxablePay(truncateDecimals(cal_tax_pay, 0));
                autoCount(truncateDecimals(cal_tax_pay, 0), nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "adjust_allow":
                cal_tax_pay =  state.salery.base_sal+state.salery.job_allow+state.salery.mng_duty_allow+state.salery.housing_allow+
                            state.salery.qual_allow+parseFloat(event.target.value)+state.salery.fixed_overtime_allow;
                setTaxablePay(truncateDecimals(cal_tax_pay, 0));
                autoCount(truncateDecimals(cal_tax_pay, 0), nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "fixed_overtime_allow":
                cal_tax_pay =  state.salery.base_sal+state.salery.job_allow+state.salery.mng_duty_allow+state.salery.qual_allow+
                            state.salery.qual_allow+state.salery.adjust_allow+parseFloat(event.target.value);
                setTaxablePay(truncateDecimals(cal_tax_pay, 0));
                autoCount(truncateDecimals(cal_tax_pay, 0), nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "travel_expense":
                cal_tax_pay =  parseFloat(event.target.value)+ state.salery.commute_allow;
                setNonTaxablePay(cal_tax_pay);  //Need to add toFixed(2) function
                autoCount(taxable_pay, truncateDecimals(cal_tax_pay, 0), emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "commute_allow":
                cal_tax_pay = state.salery.travel_expense +  parseFloat(event.target.value);
                setNonTaxablePay(cal_tax_pay); //Need to add toFixed(2) function
                autoCount(taxable_pay, truncateDecimals(cal_tax_pay, 0), emp_ins_1, emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "emp_ins_rate_1":
                cal_tax_pay = gross_pay *  parseFloat(event.target.value);
                console.log("Emp Ins Rate" + cal_tax_pay);
                setEmpIns1(cal_tax_pay); //Need to add toFixed(2) function
                autoCount(taxable_pay,nontaxable_pay , truncateDecimals(cal_tax_pay, 0), emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "emp_ins_rate_2":
                cal_tax_pay = gross_pay *  parseFloat(event.target.value);
                setEmpIns2(cal_tax_pay); //Need to add toFixed(2) function
                autoCount(taxable_pay,nontaxable_pay ,emp_ins_1,  truncateDecimals(cal_tax_pay, 0), deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "health_ins_1":
                setHealthIns1(parseFloat(event.target.value));
                autoCount(taxable_pay,nontaxable_pay , emp_ins_1,  emp_ins_2, deduction_total,parseFloat(event.target.value), health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "welfare_pension_1":
                setWelfarePension1(parseFloat(event.target.value));
                autoCount(taxable_pay,nontaxable_pay ,emp_ins_1,  emp_ins_2, deduction_total,health_ins_1, health_ins_2,parseFloat(event.target.value),welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "health_ins_2":
                setHealthIns2(parseFloat(event.target.value));
                autoCount(taxable_pay,nontaxable_pay ,emp_ins_1,  emp_ins_2, deduction_total,health_ins_1, parseFloat(event.target.value), welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
            case "welfare_pension_2":
                setWelfarePension2(parseFloat(event.target.value));
                autoCount(taxable_pay,nontaxable_pay ,emp_ins_1,  emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, parseFloat(event.target.value),tax_pay, inhabitant_tax);
                break;
            case "tax_pay":
                cal_tax_pay =  parseFloat(event.target.value) + ins_total;
                setDeductionTotal(cal_tax_pay); //Need to add toFixed(2) function
                //setTaxablePay(cal_tax_pay);
                setTaxPay(parseFloat(event.target.value));
                autoCount(taxable_pay,nontaxable_pay ,emp_ins_1,  emp_ins_2, deduction_total ,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,parseFloat(event.target.value), inhabitant_tax);
                break;
            case "inhabitant_tax":
                setInhabitantTax(parseFloat(event.target.value)); //Need to add toFixed(2) function
                setPaymentAmount(truncateDecimals(gross_pay - deduction_total -  parseFloat(event.target.value), 0));
                break;
            case "taxable_net_amnt":
                setTaxableNetAmt(parseFloat(event.target.value)); //Need to add toFixed(2) function
                break;
            default:
                autoCount(taxable_pay,nontaxable_pay ,emp_ins_1,  emp_ins_2, deduction_total,health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,tax_pay, inhabitant_tax);
                break;
        }
 
    };
 
    let setTaxablePaySalery = function (tax:any) {
        // setState({
        //     salery : {
        //         ...state.salery,
        //         taxable_pay : tax  // input name 'username' set value 'event target value'
        //     },
        //     company_list : company_list,
        //     contract_type_list : contract_type_list,
        //     job_type_list : job_type_list,
        //     salery_stage_list : salery_stage_list,
        //     salery_grade_list : salery_grade_list,
        //     ins_calc_flg_list : ins_calc_flg_list,
        //     tax_calc_type_list : tax_calc_type_list
        // }); 
    }
 
    let autoCount = function(taxable_pay:any, nontaxable_pay:any,emp_ins_1:any, emp_ins_2:any, deduction_total:any ,health_ins_1:any ,health_ins_2:any,welfare_pension_1:any, welfare_pension_2:any, tax_pay :any , inhabitant_tax :any){
        console.log("inhabitant_tax "+inhabitant_tax);
      
        console.log("taxable_pay "+taxable_pay);
        console.log("tax pay "+tax_pay);
       setGrossPay(truncateDecimals(taxable_pay + nontaxable_pay , 0));
       let gross = truncateDecimals(taxable_pay + nontaxable_pay , 0);
   
    
        setInsTotal1(truncateDecimals(health_ins_1 + welfare_pension_1 +emp_ins_1, 0));
 
       
 
       setInsTotal2(truncateDecimals(health_ins_2 + welfare_pension_2 + emp_ins_2, 0));
    
       let ins_total_1 = truncateDecimals(health_ins_1 + welfare_pension_1 + emp_ins_1, 0);
       let ins_total_2 = truncateDecimals(health_ins_2 + welfare_pension_2 + emp_ins_2, 0);
 
       setInsTotal(truncateDecimals(ins_total_1 + ins_total_2,0));
       let ins_total = truncateDecimals(ins_total_1 + ins_total_2,0);
 
       setHealthWelfareTotal(truncateDecimals(health_ins_1 + health_ins_2 + welfare_pension_1 + welfare_pension_2, 0));
       setDeductionTotal(truncateDecimals(ins_total + tax_pay , 0));
       
       let deduction  = truncateDecimals(ins_total + tax_pay , 0);
       console.log("ins_total "+ins_total);
       console.log("tax_pay "+tax_pay);
       console.log("deduction "+deduction);
       console.log("gross "+gross);
 
       setPaymentAmount(truncateDecimals(gross - deduction -  inhabitant_tax, 0));
       
       setTaxableNetAmt(truncateDecimals(taxable_pay - ins_total, 0));
    }
 
 
    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            salery : {
                ...state.salery,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            company_list : company_list,
            contract_type_list : contract_type_list,
            job_type_list : job_type_list,
            salery_stage_list : salery_stage_list,
            salery_grade_list : salery_grade_list,
            ins_calc_flg_list : ins_calc_flg_list,
            tax_calc_type_list : tax_calc_type_list
        });
    }
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            salery : {
                ...state.salery,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
            company_list : company_list,
            contract_type_list : contract_type_list,
            job_type_list : job_type_list,
            salery_stage_list : salery_stage_list,
            salery_grade_list : salery_grade_list,
            ins_calc_flg_list : ins_calc_flg_list,
            tax_calc_type_list : tax_calc_type_list
        });
     };
 
     
 
 
     let keyupInput = (e:React.KeyboardEvent<HTMLInputElement>):void => { // change event type declare in React
        // let countData = 0; 
        // const element = e.currentTarget as HTMLInputElement
        // const name = element.name
        // console.log(name);
 
        // // if(e.target.name == "taxable-count"){
        // //     alert("Hello");
        // // }
        // switch (name) {
        //         case "base_sal":
        //             let base_sal:number = parseFloat(element.value);
        //             setTaxPay(tax_pay+base_sal);
        //             // parseFloat(state.salery.base_sal)+parseFloat(state.salery.job_allow)+
        //             // parseFloat(state.salery.mng_duty_allow)+parseFloat(state.salery.housing_allow)+
        //             // parseFloat(state.salery.qual_allow)+parseFloat(state.salery.adjust_allow)+
        //             // parseFloat(state.salery.fixed_overtime_allow);
        //             // let tax_pay = state.salery.base_sal +state.salery.job_allow+state.salery.mng_duty_allow+state.salery.housing_allow+
        //             //          state.salery.qual_allow+state.salery.adjust_allow+state.salery.fixed_overtime_allow;
        //             // console.log(tax_pay);
        //             // let base_sal = state.salery.base_sal;
        //             // console.log(typeof base_sal);
        //             // let flo = parseFloat(base_sal);
        //             console.log(tax_pay);
 
        //             setState({
        //                 salery : {
        //                     ...state.salery,
        //                    taxable_pay : tax_pay
        //                 },
        //                 company_list : company_list,
        //                 contract_type_list : contract_type_list,
        //                 job_type_list : job_type_list,
        //                 salery_stage_list : salery_stage_list,
        //                 salery_grade_list : salery_grade_list,
        //                 ins_calc_flg_list : ins_calc_flg_list,
        //                 tax_calc_type_list : tax_calc_type_list
        //             });
        //             // autoCount();    
        //             break;
            
        //         default:
        //             break;
        //     }
    };
 
 
    const handleKeyPress = (event:React.KeyboardEvent)  => {
        if (event.key === "Backspace") {
            setSearchResult("");
        }
    };
    
 
 
    const personSearch = function(){
        PersonService.jishaSearch("InitialName,full_name,kana_name,alphabet_name,emp_id",state.salery.person_id,"persons").then((response) => {
            setPersonList(response.data.data);
            setPersonFieldList(response.data.info);
            setPersonListDisplay("block");
        //    window.location.href = "/cases";
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    }
 
    const autoCalTax = function () {
        let data = {};
        data["person_id"] = state.salery.person_id;
        data["emp_id"] = state.salery.emp_id;
        data["taxable_net_amnt"] = taxable_net_amnt;
        data["dependants_num"] = state.salery.dependants_num;
        SaleryService.calTax(data).then(response=>{
            console.log(response.data.data.tax);
            if(response.data.data.flg == "warning"){
                alert(response.data.data.msg);
            }else{
                setState({
                    salery : {
                        ...state.salery,
                        tax_pay : response.data.data.tax
                    },
                    company_list : company_list,
                    contract_type_list : contract_type_list,
                    job_type_list : job_type_list,
                    salery_stage_list : salery_stage_list,
                    salery_grade_list : salery_grade_list,
                    ins_calc_flg_list : ins_calc_flg_list,
                    tax_calc_type_list : tax_calc_type_list
                });
                setTaxPay(response.data.data.tax);
                autoCount(taxable_pay, nontaxable_pay, emp_ins_1, emp_ins_2, deduction_total, health_ins_1, health_ins_2, welfare_pension_1, welfare_pension_2,truncateDecimals(response.data.data.tax, 0), inhabitant_tax);
 
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    }
 
 
 
    const handleDateChange = function(date:any, column_name:any){
    // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()); 
    let selected_data =(date != null)? date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()) : "";       
        switch (column_name) {
            case "birthday":
                setSelectedBirthday(date);
                setState({
                    salery : {
                        ...state.salery,
                       birthday : date // input name 'username' set value 'event target value'
                    },
                    company_list : company_list,
                    contract_type_list : contract_type_list,
                    job_type_list : job_type_list,
                    salery_stage_list : salery_stage_list,
                    salery_grade_list : salery_grade_list,
                    ins_calc_flg_list : ins_calc_flg_list,
                    tax_calc_type_list : tax_calc_type_list
                });
                break;
            case "employment_date":
                setSelectedEmploymentDate(date);
                setState({
                    salery : {
                        ...state.salery,
                        employment_date : date // input name 'username' set value 'event target value'
                    },
                    company_list : company_list,
                    contract_type_list : contract_type_list,
                    job_type_list : job_type_list,
                    salery_stage_list : salery_stage_list,
                    salery_grade_list : salery_grade_list,
                    ins_calc_flg_list : ins_calc_flg_list,
                    tax_calc_type_list : tax_calc_type_list
                });
                break;
            case "reemploy_date":
                setSelectedReemployDate(date);
                setState({
                    salery : {
                        ...state.salery,
                        reemploy_date : date // input name 'username' set value 'event target value'
                    },
                    company_list : company_list,
                    contract_type_list : contract_type_list,
                    job_type_list : job_type_list,
                    salery_stage_list : salery_stage_list,
                    salery_grade_list : salery_grade_list,
                    ins_calc_flg_list : ins_calc_flg_list,
                    tax_calc_type_list : tax_calc_type_list
                });
                break;
            case "vacation_startday":
                setSelectedVacationStartDay(date);
                setState({
                    salery : {
                        ...state.salery,
                        vacation_startday : date // input name 'username' set value 'event target value'
                    },
                    company_list : company_list,
                    contract_type_list : contract_type_list,
                    job_type_list : job_type_list,
                    salery_stage_list : salery_stage_list,
                    salery_grade_list : salery_grade_list,
                    ins_calc_flg_list : ins_calc_flg_list,
                    tax_calc_type_list : tax_calc_type_list
                });
                break;
            case "retirement_date":
                setSelectedRetirementDate(date);
                setState({
                    salery : {
                        ...state.salery,
                        retirement_date : date // input name 'username' set value 'event target value'
                    },
                    company_list : company_list,
                    contract_type_list : contract_type_list,
                    job_type_list : job_type_list,
                    salery_stage_list : salery_stage_list,
                    salery_grade_list : salery_grade_list,
                    ins_calc_flg_list : ins_calc_flg_list,
                    tax_calc_type_list : tax_calc_type_list
                });
                break;
            default:
                break;
        }
 
    }
 
    return(
        <React.Fragment>
            <DetailTopbar />
            <div className="container">
                <div className = "row">
                        <h3 className='header-left-design'>給料情報{(id !== undefined) ?"編集":"登録"}</h3>
                </div>
                <div className="row anken-detail">
                <form className="form create-form " onSubmit={store}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-3">
                                  <div className="col-md-6 input-box col-xs-12">
                                <span id='text' className="input-required">要員ID</span>
                                <div className="row partner_search">
                                        <div className="col-9">
                                            <input type="number" name="person_id" value={(state.salery.person_id != 0)?state.salery.person_id:""} id="person_id" className="form-control" onKeyDown={handleKeyPress} onChange={updateInput} />
                                        </div>
                                        <div className="col-3">
                                            <button className="btn_standard btn_search w-100" onClick={personSearch} type="button">検索</button>
                                        </div> 
                                        <span style={{fontSize: "13px" }}>{person_search_result}</span>
                                        {/* {(id !== undefined) ?
                                            <span>{person_search_result}</span> :
                                            ""
                                        } */}
                                    </div>
                                    <div className="row  mt-3" style={{display: person_list_display }}>
                                        <div className="col-12">
                                            <select name="partner_id" className="form-control" onChange={assignPersonId}>
                                                <option value="" selected disabled></option>
                                            {person_list.length > 0 && person_list.map((key,val) => {
                                                return(<option  data-full_name={key["full_name"]}   data-kana_name={key["kana_name"]}   value={key['person_id']}>イニシャル：{key["InitialName"]}、
                                            英語氏名：{key["full_name"]}、 漢字氏名：{key["alphabet_name"]}</option>);
                                                })
                                            }
                                            </select>
                                            {
                                            person_list.length == 0 ?
                                            <span className='search-txt'>検索結果はありません</span>
                                            : null
                                            }
                                        </div>
                                    </div>
                                </div>
 
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">社員ID</span>
                                    <input type="text" name="emp_id" id="emp_id" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.emp_id} onChange={updateInput}/>
                                </div>
                              
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text'>会社名</span>
                                     <select name="company_name" className="input-box form-control" value={state.salery.company_name} onChange={onSelect} aria-label=".form-select-sm example">
                                   {company_list.map(company => {
                                                if (company !== null){
                                                    return(<option value={company}> {company}</option>)
                                                }
                                            })
                                        }                 
                                    </select>
                                </div>
                                <div className="col-md-6 input-box col-xs-12">
                                <span id='text'>部署名</span>
                                    <input type="text" name="org_name" id="org_name" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.org_name} onChange={updateInput}/>
                                </div>
                            </div>
    
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">漢字氏名</span>
                                    <input type="text" name="full_name" id="full_name" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.full_name} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">氏名カナ</span>
                                    <input type="text" name="kana_name" id="phone" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.kana_name} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>メールアドレス</span>
                                    <input type="email" name="email" id="phone" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.email} onChange={updateInput}/>
                                </div> 
                                <div className="col-md-6 col-xs-12">
                                    <span id='text'>生年月日</span>
                                    <DatePicker 
                                            value={(state.salery.birthday != null)?state.salery.birthday: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedBirthday} 
                                            onChange={date =>
                                                handleDateChange(date, "birthday")
                                            }
                                            name="birthday"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            // showMonthYearPicker
                                            // showTimeSelect
                                            // timeIntervals={30}
                                            />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 col-xs-12 input-box horizontal">
                                    <span id='text'>契約種類</span>
                                    <select name="contract_type" id="contract_type" className="input-box form-control" aria-label="form-select-sm example" value={state.salery.contract_type} onChange={onSelect}>
                                    {contract_type_list.map(contract_type => {
                                                if (contract_type !== null){
                                                    return(<option value={contract_type[0]}> {contract_type[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 col-xs-12 input-box horizontal">
                                    <span id='text'>職種</span>
                                    <select name="job_type" id="job_type" className="input-box form-control" aria-label="form-select-sm example" value={state.salery.job_type} onChange={onSelect}>
                                    {job_type_list.map(job_type => {
                                                if (job_type !== null){
                                                    return(<option value={job_type[0]}> {job_type[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>役職</span>
                                    <input type="text" name="position" id="position" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.position} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>ステージ</span>
                                    <select name="salery_stage" id="salery_stage" className="input-box form-control" aria-label="form-select-sm example" value={state.salery.salery_stage} onChange={onSelect}>
                                    {salery_stage_list.map(salery_stage => {
                                                if (salery_stage !== null){
                                                    return(<option value={salery_stage[0]}> {salery_stage[1]}</option>)
                                                }
                                            })
                                        } 
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>グレード</span>
                                    <select name="salery_grade" id="salery_grade" className="input-box form-control" aria-label="form-select-sm example" value={state.salery.salery_grade} onChange={onSelect}>
                                    {salery_grade_list.map(salery_grade => {
                                                if (salery_grade !== null){
                                                    return(<option value={salery_grade[0]}> {salery_grade[1]}</option>)
                                                }
                                            })
                                        } 
                                    </select>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text' >基本給</span>
                                    <input type="number" name="base_sal" id="base_sal" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.base_sal} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>職務手当</span>
                                    <input type="number" name="job_allow" id="job_allow" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.job_allow} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>管理職手当</span>
                                    <input type="number" name="mng_duty_allow" id="mng_duty_allow" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.mng_duty_allow} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>住宅手当</span>
                                    <input type="number" name="housing_allow" id="housing_allow" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.housing_allow} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>資格手当</span>
                                    <input type="number" name="qual_allow" id="qual_allow" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.qual_allow} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>調整手当</span>
                                    <input type="number" name="adjust_allow" id="adjust_allow" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.adjust_allow} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>固定残業手当</span>
                                    <input type="number" name="fixed_overtime_allow" id="fixed_overtime_allow" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.fixed_overtime_allow} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>精算控除時間</span>
                                    <input type="number" name="adj_hours_low" id="adj_hours_low" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.adj_hours_low} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>精算控除時間単金</span>
                                    <input type="number" name="adj_price_deduct" id="adj_price_deduct" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.adj_price_deduct} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>精算超過時間</span>
                                    <input type="number" name="adj_hours_high" id="adj_hours_high" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.adj_hours_high} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>精算超過時間単金</span>
                                    <input type="number" name="adj_price_excess" id="adj_price_excess" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.adj_price_excess} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>交通費</span>
                                    <input type="number" name="travel_expense" id="travel_expense" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.travel_expense} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>通勤手当</span>
                                    <input type="number" name="commute_allow" id="commute_allow" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.commute_allow} onChange={updateInput} onKeyUp={keyupInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>予定課税支給額</span>
                                    <input type="number" name="taxable_pay" id="tax_pay" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={taxable_pay} onChange={updateInput} readOnly />
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>予定非課税支給額</span>
                                    <input type="number" name="nontaxable_pay" id="nontaxable_pay" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={nontaxable_pay} onChange={updateInput} readOnly/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>予定総支給額</span>
                                    <input type="text" name="gross_pay" id="gross_pay" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={gross_pay} onChange={updateInput} readOnly/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>社会保険標準月額</span>
                                    <input type="number" name="standard_monthly_rem" id="standard_monthly_rem" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.salery.standard_monthly_rem} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>健康保険1</span>
                                    <input type="number" name="health_ins_1" id="health_ins_1" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={health_ins_1} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>厚生年金1</span>
                                    <input type="number" name="welfare_pension_1" id="welfare_pension_1" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={welfare_pension_1} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>雇用保険率1</span>
                                    <input type="number" name="emp_ins_rate_1" id="emp_ins_rate_1" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.emp_ins_rate_1} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>雇用保険1</span>
                                    <input type="number" name="emp_ins_1" id="emp_ins_1" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={emp_ins_1} onChange={updateInput} readOnly/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>社会保険合計1</span>
                                    <input type="number" name="ins_total_1" id="ins_total_1" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={ins_total_1} onChange={updateInput} readOnly/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>健康保険2</span>
                                    <input type="number" name="health_ins_2" id="health_ins_2" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={health_ins_2} onChange={updateInput} />
                                </div>
                            </div>
                           
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>厚生年金2</span>
                                    <input type="number" name="welfare_pension_2" id="welfare_pension_2" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={welfare_pension_2} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>雇用保険率2</span>
                                    <input type="number" name="emp_ins_rate_2" id="emp_ins_rate_2" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.emp_ins_rate_2} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>雇用保険2</span>
                                    <input type="number" name="emp_ins_2" id="emp_ins_2" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={emp_ins_2} onChange={updateInput} readOnly/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>社会保険合計2</span>
                                    <input type="number" name="ins_total_2" id="ins_total_2" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={ins_total_2} onChange={updateInput} readOnly/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>社会保険計算方法</span>
                                    <select name="ins_calc_flg" id="ins_calc_flg" className="input-box form-control" aria-label="form-select-sm example" value={state.salery.ins_calc_flg} onChange={onSelect}>
                                    {ins_calc_flg_list.map(ins_calc_flg => {
                                                if (ins_calc_flg !== null){
                                                    return(<option value={ins_calc_flg[0]}> {ins_calc_flg[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>健康・厚生年金合計額</span>
                                    <input type="number" name="health_welfare_total" id="health_welfare_total" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={health_welfare_total} onChange={updateInput} readOnly/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>社会保険合計額</span>
                                    <input type="number" name="ins_total" id="ins_total" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={ins_total} onChange={updateInput} readOnly/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">扶養親族の数</span>
                                    <input type="number" name="dependants_num" id="dependants_num" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.salery.dependants_num} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>税月額表適用区分</span>
                                    <select name="tax_calc_type" id="tax_calc_type" className="input-box form-control" aria-label="form-select-sm example" value={state.salery.tax_calc_type} onChange={onSelect}>
                                    {tax_calc_type_list.map(tax_calc_type => {
                                                if (tax_calc_type !== null){
                                                    return(<option value={tax_calc_type[0]}> {tax_calc_type[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>予定課税対象額</span>
                                    <input type="number" name="taxable_net_amnt" id="taxable_net_amnt" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={taxable_net_amnt} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>予定所得税</span>
                                    <div className="row">
                                        <div className="col-9">
                                            <input type="number" name="tax_pay" id="tax_pay" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={tax_pay} onChange={updateInput}/>
                                        </div>
                                        <div className="col-3">
                                            <button type="button" className="btn_standard btn_search w-100" onClick={autoCalTax}>自動計算</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>住民税</span>
                                    <input type="number" name="inhabitant_tax" id="inhabitant_tax" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={inhabitant_tax} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>控除合計（住民税以外）</span>
                                    <input type="number" name="deduction_total" id="deduction_total" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={deduction_total} onChange={updateInput} readOnly/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>予定支給額</span>
                                    <input type="number" name="payment_amount" id="payment_amount" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={payment_amount} onChange={updateInput} readOnly/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">入職年月日</span>
                                    <DatePicker 
                                        value={(state.salery.employment_date != null)?state.salery.employment_date: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedEmploymentDate} 
                                        onChange={date =>
                                            handleDateChange(date, "employment_date")
                                        }
                                        name="employment_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        // showMonthYearPicker
                                        // showTimeSelect
                                        // timeIntervals={30}
                                        />
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>契約更新日</span>
                                    <DatePicker 
                                        value={(state.salery.reemploy_date != null)?state.salery.reemploy_date: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedReemployDate} 
                                        onChange={date =>
                                            handleDateChange(date, "reemploy_date")
                                        }
                                        name="reemploy_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>有給開始日</span>
                                    <DatePicker 
                                        value={(state.salery.vacation_startday != null)?state.salery.vacation_startday: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedVacationStartDay} 
                                        onChange={date =>
                                            handleDateChange(date, "vacation_startday")
                                        }
                                        name="vacation_startday"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>退職年月日</span>
                                    <DatePicker 
                                        value={(state.salery.retirement_date != null)?state.salery.retirement_date: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedRetirementDate} 
                                        onChange={date =>
                                            handleDateChange(date, "retirement_date")
                                        }
                                        name="retirement_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 input-box">
                                    <span id='text'>備考</span>
                                    <textarea className="form-control" rows={3} name = "remarks" id= "remarks" aria-label="With textarea" value={state.salery.remarks} onChange={updateTextArea}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <button className="btn btn_standard" id="case_create_submit" type="submit">{(id !== undefined) ?"更新":"登録"}</button>
                            <Link to={`/salery/list`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                            
                        </div>
                    </div>
                    </form>
                </div>
                
            </div>
        </React.Fragment>
    );
};
export default SaleryCreate;