import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client_domain" :  `${CLIENT_DOMAIN}`,
       } 
};
class HRService {
    list(company_filter:string, perPage:number, page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/hrs/list?company_filter=${company_filter}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, header);
    }
    hrPerformance(company_filter:string, representative_filter:string, target_month:any, start_date:any, end_date:any, perPage:number, page:number,search_keywords:any, sort:any, column:string, order:string, assignment_status:any, propose_status:any){
        let dataURL:string = API_URL + `/hrs/performance?company_filter=${company_filter}&representative_filter=${representative_filter}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        dataURL += `&assignment_status=${assignment_status}&propose_status=${propose_status}`;
        if(target_month !== undefined){
            dataURL += `&target_month=${target_month}`;
        }
        if(start_date !== undefined){
            dataURL += `&start_date=${start_date}`;
        }
        if(end_date !== undefined){
            dataURL += `&end_date=${end_date}`;
        }

        return axios.get(dataURL, header);

        
    }
}

export default new HRService();