import React ,{useState , useEffect} from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";

interface IProps{}

let SecurityPolicy:React.FC<IProps> = () =>{
    return(
        <>
       <React.Fragment>
           <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                ml: sizeConfigs.sidebar.width,
                boxShadow: "unset",
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color
            }}
            >
            <Toolbar>
                <Typography variant="h6">
                <header className="top-baner">
                    <div className="row" style={{maxHeight:"100vh",overflow:"auto"}}>
                        <div className="col-10">
                        </div>
                        <div className="col-2 mt-4">
                            <DropDownList />
                        </div>
                        <div className="row dashboard">
                            <div className="col-md-12 dashboard-header">
                                <h2>情報セキュリティ基本方針</h2>
                            </div>
                            <div className="row mt-2 security-policy">
                                <div className="col-md-6 col-sm-6 col-xs-6" style={{width: "50% !important"}}>
                                    <div className="float-start">
                                        <img src="/images/ISMS_floboard.png" className="img-responsive w-30"/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6" style={{width: "50% !important"}}>
                                    <div className="float-end"> 
                                        <span className="block">第1版</span>
                                        <span className="block">2019年9月17日</span>
                                        <span className="block">株式会社 FloBoard</span>
                                        <span className="block">代表取締役　羿　明</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3" style={{fontSize:"13px"}}>
                                <div className="col-md-12">
                                    <p className="body-text">私たち<mark>株式会社FloBoard</mark>は、ソフトウェアの設計・開発・コンサルティング及び、当該内容に関わる人材派遣の提供を通じて、お客様、社員とその家族などすべてのステークホルダーの期待に応え、社会に貢献することを使命と考えています。
                                    </p>
                                    <p className="body-text">当社の事業活動において、お客様からお預かりする個人情報を含む多くの情報資産を活用しており、すべてのステークホルダーの期待に応えるためには、これらの情報資産を保護する事は、経営上の最重要課題であると認識しています。
                                    </p>
                                    <p className="body-text">よって、私たちは、情報セキュリティ基本方針を策定し、本基本方針に基づいて、<mark>ISMS（情報セキュリティマネジメントシステム）</mark>を構築・運用し、当社を取り巻く環境の変化を踏まえ、継続的改善に全社を挙げて取り組むことをここに宣言します。
                                    </p>
                                    <p className="body-text">さらに、当社、以下のセキュリティ目的を設定し、この目的を達成するための諸施策を確実に実施します。
                                    </p>
                                    <ul className="list-style-img">
                                        <li>お客様との契約及び法的または規制要求事項を尊重し遵守する。</li>
                                        <li>情報セキュリティ事故を未然に防止する。</li>	
                                        <li>万一情報セキュリティ事故が発生した場合、影響を最小限にする。</li>
                                    </ul>           
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="float-end" style={{color:"black",fontSize:"12px"}}>以上</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                </Typography>
            </Toolbar>
            </AppBar>
        </React.Fragment>
        </>
    );
}

export default SecurityPolicy;