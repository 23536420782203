// src/components/ForbiddenPage.js
import React from 'react';
import { Link } from 'react-router-dom'; // if you're using react-router

const NotFoundPage = () => {
  return (
    <div className="flex-center position-ref full-height">
      <div className= "code">404</div>
      <div className="message"> 無効なURLです。</div>
    </div>
  );
};

export default NotFoundPage;
