import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client_domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};
class ProspectService {
    list(partner_type:any, mail_flg:string, upgradeflg:string,prospect_staff_id:string, perPage:number, page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/prospects/list?partner_type=${partner_type}&mail_flg=${mail_flg}&upgradeflg=${upgradeflg}&prospect_staff_id=${prospect_staff_id}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, header);
    }

    create(){
        let dataURL:string = API_URL + `/prospects/create1`;
        return axios.get(dataURL, header);
    }

    store(data:any) {
        console.log("Prospect Store Data");
        console.log(data);
        let dataURL:string = API_URL + "/prospects/store1";
        return axios.post(dataURL,data, header);
    }

    edit(id:any){
        let dataURL:string = API_URL + "/prospects/"+id+"/edit";
        return axios.get(dataURL, header);
    }

    update(data:any ,id:any) {
        let dataURL:string = API_URL + "/prospects/update1/"+id;
        return axios.post(dataURL,data, header);
    }

    updateMailFlag(data:any){
        let dataURL:string = API_URL + "/prospect/list/edit/mail_flg1";
        return axios.post(dataURL,data, header);
    }

    deleteList(data:any){
        let dataURL:string = API_URL+"/prospect/list/destroy1";
        return axios.post(dataURL,data,header);
    }

}

export default new ProspectService();