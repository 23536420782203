import React ,{useState , useEffect} from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";

interface IProps{}

let PersonalInformationPolicy:React.FC<IProps> = () =>{
    return(
        <>
       <React.Fragment>
           <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                ml: sizeConfigs.sidebar.width,
                boxShadow: "unset",
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color
            }}
            >
            <Toolbar>
                <Typography variant="h6">
                <header className="top-baner">
                    <div className="row">
                        <div className="col-10">
                        </div>
                        <div className="col-2 mt-4">
                            <DropDownList />
                        </div>
                        <div className="row text-body" style={{maxHeight:"100vh",overflow:"auto"}}>
                            <div className="row dashboard">
                                <div className="col-md-12 dashboard-header">
                                    <h2>今月のサマリー</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                <div className="float-start">
                                    <img src={"/images/PMark_floboard_JP.jpg"} className="img-responsive w-50"/>
                                </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                    <div className="float-end"> 
                                        <span className="block">制定　2017年10月10日</span>
                                        <span className="block">改定　2022年03月15日</span>
                                        <span className="block">株式会社 FloBoard</span>
                                        <span className="block">代表取締役　羿　明</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <p className="w-100">当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。
                                    </p>
                                </div>
                            </div>
                            <div className ="row list-text">
                                <div className ="col-md-12">
                                    <ol type="1">
                                        <li>個人情報は、ソフト開発業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。
                                        </li>
                                        <li>個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</li>
                                        <li>個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。
                                        </li>
                                        <li>個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。
                                        </li>
                                        <li>個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="last">本方針は、全ての従業者に配付して周知させるとともに、当社のホームページ、パンフレットなどに掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="float-end last">以上</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="text">【お問合せ窓口】</span>
                                    <p className="last">個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="block">株式会社FloBoard 　個人情報問合せ窓口</span>
                                    <span className="block">〒101-0031　東京都千代田区東神田二丁目7番4-305</span>
                                    <span className="block">メールアドレス：info@floboard.co.jp　TEL：03-6753-0948</span>
                                    <span className="block">（受付時間　9:00～18:00　※土・日曜日、祝日、年末年始、ゴールデンウィークを除く)</span>
                                </div>
                            </div>
                            <div className="row mt-5 pt-3 policy">
                                <div className="col-md-12 col-sm-12 col-xs-12 policy-header">
                                    <h2>個人情報の取扱いについて</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 table-responsive" style={{fontSize:"12px"}}>
                                    <ol type="1">
                                        <li>当社が取り扱う個人情報の利用目的</li>
                                        <ol type="1" className="policy-purpose">
                                            <li><span>ご本人から直接書面によって取得する個人情報（ホームページや電子メール等によるものを含む）の利用目的 取得に先立ち、ご本人に対し書面により明示します。</span></li>
                                            <li><span>前項以外の方法によって取得する個人情報の利用目的</span><br/>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>分類</th>
                                                            <th>利用目的</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>個人のお客様情報</td>
                                                            <td>ご利用履歴管理のため<br/>
                                                                お問合せ対応のため</td>
                                                        </tr>
                                                        <tr>
                                                            <td>業務協力技術員様の情報</td>
                                                            <td>協力会社からのスキル表の取得のため</td>    
                                                        </tr>
                                                        <tr>
                                                            <td>お取引先担当者様情報</td>
                                                            <td>発注内容確認のため（通信記録等）</td>
                                                        </tr>
                                                        <tr>    
                                                            <td>業務の受託に伴い、お客様からお預かりする個人情報</td>
                                                            <td>委託された当該業務を適切に遂行するため</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                        </ol>
                                    </ol>
                                </div>
                            </div>
                            <div className="row">
                            <div className="row mt-5 pt-3 policy">
                                <div className="col-md-12 policy-header">
                                    <h2>保有個人データに関する事項の周知</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 table-responsive" style={{fontSize:"12px"}}>
                                <p className="com-quantity" style={{color:"black"}}>当社で保有している保有個人データに関して、ご本人様又はその代理人様からの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、以下の要領にて対応させていただきます
                                </p>
                                <ol type="a" className="personal">
                                    <li><span className="block">事業者の名称</span>
                                        <span className="block">株式会社FloBoard</span>
                                    </li>
                                    <li>
                                        <span className="block">個人情報の保護管理者</span>
                                        <span className="block">管理者名：馮　雁</span>
                                        <span className="block">所属部署：営業・管理部 </span>
                                        <span className="block">連絡先　：メールアドレス：info@floboard.co.jp　TEL：03-6753-0948</span>
                                    </li>
                                    <li>
                                        <span>全ての開示対象個人情報の利用目的</span>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>分類</th>
                                                    <th>利用目的</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>個人のお客様情報</td>
                                                    <td>ユーザー様サポートのため<br/>
                                                        ご利用履歴管理のため<br/>
                                                        当社サービスのご案内のため<br/>
                                                        お問合せ対応のため
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>お取引先担当者様情報</td>
                                                    <td>発注内容確認のため（通信記録等）</td>
                                                </tr>
                                                <tr>
                                                    <td>当社従業員情報</td>
                                                    <td>社員の人事労務管理、業務管理、健康管理、セキュリティ管理のため</td>
                                                </tr>
                                                <tr>
                                                    <td>当社への採用応募者情報</td>
                                                    <td>採用応募者への連絡と当社の採用業務管理のため</td>
                                                </tr>
                                                <tr>
                                                    <td>特定個人情報</td>
                                                    <td>番号法に定められた利用目的のため</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                    <li>
                                        <span className="block">保有個人データの取扱いに関する苦情の申し出先</span>
                                        <span className="block">株式会社FloBoard 　個人情報問合せ窓口</span>
                                        <span className="block">〒101-0031　東京都千代田区東神田二丁目7番4-305 </span>
                                        <span className="block">メールアドレス：info@floboard.co.jp　TEL：03-6753-0948 </span>
                                        <span className="block">（受付時間　9:00～18:00　※土・日曜日、祝日、年末年始、ゴールデンウィークを除く)</span>
                                    </li>
                                    <li>
                                        <span className="block">認定個人情報保護団体</span>
                                        <p className="m-3 mt-0 mb-0" style={{color:"black"}}>認定個人情報保護団体の名称<br/>
                                            <span className="m-2 mt-0 mb-0">一般財団法人日本情報経済社会推進協会</span>
                                        </p>
                                        <p className="m-3 mt-0 mb-0" style={{color:"black"}}>苦情の解決の申出先<br/>
                                            <span className="m-2 mt-0 mb-0">個人情報保護苦情相談室</span>
                                        </p>
                                        <p className="m-3 mt-0 mb-0" style={{color:"black"}}>住所<br/>
                                            <span className="m-2 mt-0 mb-0">〒106-0032 東京都港区六本木一丁目９番９号 六本木ファーストビル内</span>
                                        </p>
                                        <p className="m-3 mt-0 mb-0" style={{color:"black"}}>電話番号<br/>
                                            <span className="m-2 mt-0 mb-0">03-5860-7565　／　0120-700-779</span>
                                        </p>
                                        <p className="m-3 mt-0 mb-0" style={{color:"black"}}>【当社の商品・サービスに関する問合せ先ではありません。】
                                        </p>
                                        <p className="m-3 mt-0 mb-0" style={{color:"black"}}> 【当社の商品・サービスに関する問合せは（03-6753-0948）です。】</p>
                                    </li>
                                    <li>保有個人データの開示等の求めに応じる手続き
                                        <ol type="1">
                                            <li>開示等の求めの申し出先 <br/>
                                                開示等のお求めは、上記個人情報問合せ係にお申し出ください。
                                            </li>
                                            <li>開示等の求めに関するお手続き
                                                <ol type="1">
                                                    <li>お申し出受付け後、当社からご利用いただく所定の請求書様式「開示対象個人情報開示等請求書」を郵送いたします。</li>
                                                    <li>ご記入いただいた請求書、代理人によるお求めの場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知並びに開示の請求の場合のみ）を上記個人情報問合せ係までご郵送ください。</li>
                                                    <li>上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうちご本人確認可能な2項目程度（例：電話番号と生年月日等）の情報をお問合せさせていただきます。</li>
                                                    <li>回答は原則としてご本人に対して書面（封書郵送）にておこないます。</li>
                                                </ol>
                                            </li>
                                            <li> 代理人によるお求めの場合、代理人であることを確認する資料<br/>
                                                開示等をお求めになる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を墨塗り等の処理をしてください。
                                                <ol type="1">
                                                    <li>代理人である事を証明する資料<br/>
                                                        ＜開示等の求めをすることにつき本人が委任した代理人様の場合＞<br/>
                                                        　本人の委任状（原本）<br/>
                                                        ＜代理人様が未成年者の法定代理人の場合＞いずれかの写し<br/>
                                                        　戸籍謄本<br/>
                                                        　住民票（続柄の記載されたもの）<br/>
                                                        　その他法定代理権の確認ができる公的書類<br/>
                                                        ＜代理人様が成年被後見人の法定代理人の場合＞いずれかの写し<br/>
                                                        　後見登記等に関する登記事項証明書<br/>
                                                        　その他法定代理権の確認ができる公的書類
                                                    </li>
                                                    <li>代理人様ご自身を証明する資料<br/>
                                                        　運転免許証<br/>
                                                        　パスポート<br/>
                                                        　健康保険の被保険者証<br/>
                                                        　住民票<br/>
                                                        　住民基本台帳カード
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                利用目的の通知または開示のお求めについての手数料 1回のお求めにつき1000円<br/>
                                                （お送りいただく請求書等に郵便為替を同封していただきます。）
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </header>
                </Typography>
            </Toolbar>
            </AppBar>
        </React.Fragment>
        </>
    );
}

export default PersonalInformationPolicy;