import React from "react";
const login_user =   JSON.parse(localStorage.getItem("user") || '{}');


function CaseMainInfo({ cases_arr }:any) {
  return (
    <div className="card">
    <div className="card-body">
        <div className="row">
            <div className="col-12">
                <h4>基本情報 </h4>   
            </div>
        </div>
        <hr className="reset" />
        <table className="new-table" >
            <tbody>
                <tr>
                    <th>案件ステータス：</th>
                    <td>{cases_arr['case_status']}</td>
                </tr>
                <tr>
                    <th>優先順位：</th>
                    <td>{cases_arr['priority']}</td>
                </tr>
                <tr>
                    <th>案件名：</th>
                    <td>{cases_arr['case_name']}</td>
                </tr>
                <tr>
                    <th>一行アピール：</th>
                    <td>{cases_arr['appeal_cmnt']}</td>
                </tr>
                <tr>
                    <th>職種：</th>
                    <td>{cases_arr['category']}</td>
                </tr>
                <tr>
                    <th>募集エリア：</th>
                    <td>{cases_arr['recurit_area']}</td>
                </tr>
                <tr>
                    <th>案件概要：</th>
                    <td>{cases_arr['case_overview']}</td>
                </tr>
                <tr>
                    <th>要求内容：</th>
                    <td>{cases_arr['requisition']}</td>
                </tr>
                <tr>
                    <th>要求スキル：</th>
                    <td>
                  
                    {cases_arr['required_skill']}
                    </td>
                </tr>
                <tr>
                    <th>期間種別：</th>
                    <td>{cases_arr['case_period_type']}</td>
                </tr>
                <tr>
                    <th>開始年月日：</th>
                    <td>{cases_arr['start_date_plan']}</td>
                </tr>
                <tr>
                    <th>終了予定年月日：</th>
                    <td>{cases_arr['end_date_plan']}</td>
                </tr>
                <tr>
                    <th>募集人数：</th>
                    <td>{cases_arr['recruit_num']}</td>
                </tr>
                <tr>
                    <th>作業場所：</th>
                    <td>{cases_arr['work_place']}</td>
                </tr>
                {
                (() => {
                    if([1,2,4,8,9].includes(login_user.role)){
                        return(
                                <tr>
                                    <th>予算：</th>
                                    <td>{cases_arr['budget']}</td>
                                </tr>
                                 )}
                    })()
                }
                {
                (() => {
                    if(login_user.role != 7){
                        return(
                                <tr>
                                    <th>精算：</th>
                                    <td>{cases_arr['settlement']}</td>
                                </tr>
                            )}
                        })()
                 }
            </tbody>
            </table>
           
    <hr className="reset" />
    <table className="new-table">
        <tbody>
            <tr>
                <th>予定受注契約：</th>
                <td>{cases_arr['expect_contract_type']}</td>
            </tr>

            <tr>
                <th>公開日：</th>
                <td>{cases_arr['publish_date']}</td>
            </tr>
            <tr>
                <th>募集期限：</th>
                <td>{cases_arr['recruit_deadline']}</td>
            </tr>
            <tr>
                <th>面談回数：</th>
                <td>{cases_arr['interview_num']}回</td>
            </tr>

            <tr>
                <th>案件依頼日：</th>
                <td>{cases_arr['requested_date']}</td>
            </tr>

            <tr>
                <th>提案締切日：</th>
                <td>{cases_arr['proposal_deadline']}</td>
            </tr>
            {
                (() => {
                    if(login_user.role != 7){
                        return(
                        <tr>
                                <th>営業担当者：</th>
                                <td>{cases_arr['saler-name']}</td>
                            </tr>
                    )}
                })()
            }
            {
            (() => {
                if(login_user.role != 7){
                    return(
                        <tr>
                            <th>担当営業メアド：</th> 
                            <td>{cases_arr['saler-email']}</td> 
                        </tr>
                        )}
                    })()
            }
            {
            (() => {
                if(login_user.role != 7){
                    return(
                            <tr>
                                <th>担当営業電話：</th>
                                <td>{cases_arr['saler-tel_no']}</td>
                            </tr> 
                        )}
                    })()
            }
            {
            (() => {
                if(login_user.role != 8){
                    return(
                        <tr>
                            <th>HR担当者：</th>
                            <td>{cases_arr['hr-name']}</td>
                        </tr>
                         )}
                        })()
            }
            {
                (() => {
                    if(login_user.role != 8){
                        return(
                        <tr>
                            <th>担当ＨＲメアド：</th>
                            <td>{cases_arr['hr-email']}</td>
                        </tr>
                         )}
                        })()
            }
            {
                (() => {
                    if(login_user.role != 8){
                        return(
                            <tr>
                                <th>担当ＨＲ電話：</th>
                                <td>{cases_arr['hr-tel_no']}</td>
                            </tr>
                            )}
                        })()
            }
            {
            (() => {
                 if(login_user.role !=7 && login_user.role !=8){
                    return(
                    <tr>
                        <th>公開フラグ：</th>
                        <td>
                        {(cases_arr['published_flg'] == 0 )? "未公開": "公開"}
                        </td>
                    </tr>
                    )
                }
            })()
            }
            <tr>
                <th>特記事項：</th>
                <td>{cases_arr['remarks']}</td>
            </tr>
            {
            (() => {
                 if(login_user.role !=7 && login_user.role !=8){
                    return(
                    <tr>
                        <th>社内共有メモ：</th>
                        <td>{cases_arr['flb_shared_cmt']}</td>
                    </tr>
                    )
                }
            })()
            }
            </tbody>
            </table>
        </div>
 </div>
  );
}

export default CaseMainInfo;
