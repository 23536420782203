import React, {useEffect, useState} from 'react';
import ProcedureService from "../../services/ProcedureService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import $ from 'jquery';
import {AccountingModel} from "../../models/Accounting";
import { useLocation } from 'react-router-dom';
import {generatePDF} from "../../components/common/Helpers";

// import PrintSvgsAsPdf from './PrintSvgsAsPdf';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';

interface URLParam {
    id : string;
}

// interface IState{
// }
interface IProps{}
let SVGDownload:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();

    const [svgContent, setSvgContent] = useState(null);


    const [svgContents, setSvgContents] = useState<string[]>([]);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    let location = useLocation();
    let search = location.search;
    const [file_name, setFileName] = useState("");

    useEffect(()=>{
        if(id != undefined){
            let data = {}
            data["id"] = id;
            if(search == "?type=1")
                {
                    ProcedureService.downloadPurchaseOrder(data).then((response)=>{
                        console.log(response);
                        let filename =  response.data.data.data.filename;
                        let exampleSvg = response.data.data.data.template;
                        // exampleSvg +=  response.data.data.data.template;
                        setSvgContents(exampleSvg);
                        setFileName(filename.replace(".svg", '.pdf'));
                        // Example SVG data
                        // const fetchedSvgContents = [exampleSvg,exampleSvg];
                        // setSvgContents(fetchedSvgContents);
                    }).catch((error) => {
                        console.log(error);
                        // setState({
                        //     ...state,
                        // })
                    })
                }else{
                    ProcedureService.downloadBill(data).then((response)=>{
                        let filename =  response.data.data.data.filename;
                        let exampleSvg = response.data.data.data.template;
                        // exampleSvg +=  response.data.data.data.template;
                        setSvgContents(exampleSvg);
                        setFileName(filename.replace(".svg", '.pdf'));
                        // Example SVG data
                        // const fetchedSvgContents = [exampleSvg,exampleSvg];
                        // setSvgContents(fetchedSvgContents);
                    }).catch((error) => {
                        // console.log(error);
                        // setState({
                        //     ...state,
                        // })
                    })
                }
        }
       

    }, [id]);

    const handleDownloadPdf = async () => {
        generatePDF(svgContents, file_name);
    }

    // Function to handle downloading the SVG
    const downloadSvg = () => {
        const blob = new Blob([svgContent!], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.svg';
        link.click();
        URL.revokeObjectURL(url);
    };

    // Function to handle printing the SVG
    const printSvg = () => {
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow!.document.write('<html><head><title>Print SVG</title></head><body>');
        printWindow!.document.write(svgContent!);
        printWindow!.document.write('</body></html>');
        printWindow!.document.close();
        printWindow!.focus();
        printWindow!.print();
    };

    // //   // Example SVG contents
    //   const svgContents = [
    //     '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /></svg>',
    //     '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100" height="100" style="fill:blue" /></svg>',
    //     // Add more SVG contents as needed
    // ];

    // const generatePdf = async () => {
    //     const pdf = new jsPDF('p', 'mm', 'a4');
        
    //     for (let i = 0; i < svgContents.length; i++) {
    //         const svg = svgContents[i];
            
    //         // Create a temporary container for the SVG
    //         const container = document.createElement('div');
    //         container.style.position = 'absolute';
    //         container.style.left = '-9999px'; // Hide it from view
    //         container.innerHTML = svg;
    //         document.body.appendChild(container);

    //         // Use html2canvas to render SVG as a canvas
    //         const canvas = await html2canvas(container, { useCORS: true });
    //         const imgData = canvas.toDataURL('image/png');

    //         // Add image to PDF
    //         if (i > 0) {
    //             pdf.addPage();
    //         }
    //         pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);

    //         // Clean up
    //         document.body.removeChild(container);
    //     }

    //     // Save the PDF
    //     pdf.save('svgs.pdf');
    // };


    return(
        <React.Fragment>
            {/* <div className="container text-center" >
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <button onClick={printSvg} className="btn_standard">Print SVG</button>
                </div>
                <div dangerouslySetInnerHTML={{ __html: svgContent! }} style={{width:"80%",margin:"auto", backgroundColor:"#fff", marginTop:"20px", marginBottom:"50px", border:"1px solid #cecece"}} />
               
            </div> */}
            <div>
            {/* <button onClick={generatePdf} disabled={svgContents.length === 0}>
                Generate PDF
            </button> */}
        <div className="container text-center">
            <button onClick={handleDownloadPdf}>Download PDF</button>
                <div ref={containerRef}>
                    {svgContents.map((svg, index) => (
                        <div key={index} dangerouslySetInnerHTML={{ __html: svg }} style={{width:"80%",margin:"auto", backgroundColor:"#fff", marginTop:"20px", marginBottom:"50px", border:"1px solid #cecece"}} />
                    ))}
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};
export default SVGDownload;