import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { UserModel } from "../../models/IUser";
import UserService from "../../services/UserService";
import ResourceImg from '../../components/common/ResourceImg';
import ResourceService from "../../services/ResourceService";
import $ from 'jquery';

interface IState {
  user_model: UserModel;
}

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ProfileChange: React.FC<IProps> = ({ open, onClose }) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxWidth: '90%', // Ensure the modal doesn't overflow the viewport
    height: 700,
    maxHeight: '90vh', // Limit the height to avoid overflow
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto' as 'auto', // Ensure content can scroll within the modal
  };

  const [showProfile, setShowProfile] = useState("none");
  const [dropProfile, setDropProfile] = useState("block");
  const [photoName, setPhotoName] = useState("");
  const loginUser = JSON.parse(localStorage.getItem("user") || '{}');

  const [state, setState] = useState<IState>({
    user_model: {
      user_id: loginUser.user_id,
      name_jp: loginUser.name_jp,
      kana_name: loginUser.kana_name,
      email: loginUser.email,
      photo: loginUser.photo,
      password: loginUser.password,
      tel_no: loginUser.tel_no,
      privilege_grp_cd: loginUser.privilege_grp_cd,
      company: loginUser.company,
      dept_name: loginUser.dept_name,
      position: loginUser.position,
      respond_area: loginUser.respond_area,
      role: loginUser.role,
      admission_date: loginUser.admission_date,
      withdraw_date: loginUser.withdraw_date,
      withdraw_reason: loginUser.withdraw_reason,
      memo: loginUser.memo
    }
  });

  const { user_model } = state;

  useEffect(() => {
    if (loginUser.user_id) {
      UserService.showUser(loginUser.user_id).then(response => {
        setState(prevState => ({
          ...prevState,
          user_model: {
            ...prevState.user_model,
            ...response.data
          }
        }));

        if (response.data.photo) {
          setShowProfile("block");
          setDropProfile("none");
        }
      }).catch(error => {
        console.error(error);
      });
    }
  }, [loginUser.user_id]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling on the body
    } else {
      document.body.style.overflow = 'auto'; // Restore scrolling on the body
    }

    return () => {
      document.body.style.overflow = 'auto'; // Cleanup on component unmount
    };
  }, [open]);

  const changeProfile = function(e:any) {
    let file_name = e.target.files[0].name;
    profileUpload(e.target.files[0],file_name);
      e.target.value = null;
  }

  const profileUpload = (file: File, name: string) => {
    ResourceService.apiUpload(file, 'profiles/', name).then(() => {
      setShowProfile("block");
      setDropProfile("none");
      setPhotoName(name);
      localStorage.setItem('profile_image', name);
      $(".img-content img").attr("src", ResourceService.url(`profiles/${name}`));
    }).catch(error => {
      console.error(error);
    });
  };

  const handleStoreProfile = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    UserService.updateProfile(state.user_model, photoName).then(response => {
      if (response.data.success) {
        alert("プロファイル内容を更新しました。");
        localStorage.setItem("user", JSON.stringify(response.data.info));
        onClose();
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const handleProfileExplorer = () => {
    $('#selectprofile').click();
  };

  const handleEditProfile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowProfile("none");
    setDropProfile("block");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({
      ...prevState,
      user_model: {
        ...prevState.user_model,
        [event.target.name]: event.target.value
      }
    }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ zIndex: 1300 }} // Ensure the modal is on top
    >
      <Box sx={style}>
        <div className="row">
          <div className="col-6">
            <h3>プロファイル変更</h3>
          </div>
          <div className="col-6">
            <button type="button" className="modal_close" onClick={onClose}>X</button>
          </div>
        </div>
        <form className="form" onSubmit={handleStoreProfile}>
          <input type="hidden" name="user_id" value={user_model.user_id} />
          <div className="row">
            <div className="col-12 input-box">
              <input type="hidden" name="photo" value={user_model.photo || ''} className="uploaded_profile" />
              <div className="img-content" style={{ display: showProfile }}>
                <button className="edit_btn" onClick={handleEditProfile}>
                  <i className="fas fa-edit"></i>
                </button>
                <ResourceImg remoteSrc={`profiles/${localStorage.getItem('profile_image')}`} src={'images/businessman.jpg'}  className="img-circle" alt="icons"  />
                </div>
              <div id="drop_file_zone" className="drop_profile" style={{ display: dropProfile }} onDrop={e => e.preventDefault()}>
                <div id="drag_upload_file">
                  <p>顔写真をドラッグしてください。</p>
                  <p>または</p>
                  <p><input type="button" value="顔写真を選択してください。" onClick={handleProfileExplorer} /></p>
                  <input type="file" name="profile" id="selectprofile" onChange={changeProfile} />
                </div>
              </div>
            </div>
          </div>
          {[
            { label: "アカウントID（メールアドレス）", name: "email", type: "text" },
            { label: "漢字氏名", name: "name_jp", type: "text", required: true },
            { label: "カタカナ名", name: "kana_name", type: "text" },
            { label: "パスワード", name: "password", type: "password", placeholder: "******" },
            { label: "電話番号", name: "tel_no", type: "text" },
            { label: "会社名", name: "company", type: "text" },
            { label: "部門名", name: "dept_name", type: "text" },
            { label: "担当エリア", name: "respond_area", type: "text" }
          ].map(field => (
            <div className="row" key={field.name}>
              <div className="col-12 input-box">
                <div className="form-group">
                  <label htmlFor={field.name} className={field.required ? 'input-required' : ''}>{field.label}:</label>
                  <input
                    type={field.type}
                    className="form-control"
                    name={field.name}
                    id={field.name}
                    value={user_model[field.name as keyof UserModel] || ''}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                    required={field.required}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="form-group mt-5 text-center">
              <button type="submit" className="button submit-btn btn_standard">更新</button>
              <button type="button" className="modal_cancel" onClick={onClose}>取消</button>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ProfileChange;