import axios from 'axios';
export class AssignmentService{
    private static serverURL = process.env.REACT_APP_API_URL;
    // private static SEV_URL = process.env.REACT_APP_SERVER_URL;
    private static token = localStorage.getItem('token');
    private static CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;

    private static header = { 
        headers: {
                    "Authorization" : `Bearer ${this.token}` , 
                    // "server_url" : `${this.SEV_URL}`,   
                    "client_domain" :  `${this.CLIENT_DOMAIN}`
                }
    };

    public static getAllAssignmentCaseList(assign_status:string,propose_status:string,flb_hr_id:any,perPage:number,page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string = `${this.serverURL}/assignment/list/case?assignment_status=${assign_status}&propose_status=${propose_status}&flb_hr_id=${flb_hr_id}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, this.header); // use axios library getting request data
    }

    public static getAllAssignmentPersonList(assign_status:string,propose_status:string,flb_sales_id:any,flb_hr_id:any,perPage:number,page:number,search_keywords:any,sort:any, column:string, order:string){
        let dataURL:string = `${this.serverURL}/assignment/list/person?assignment_status=${assign_status}&propose_status=${propose_status}&flb_sales_id=${flb_sales_id}&flb_hr_id=${flb_hr_id}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, this.header); // use axios library getting request data
    }

    public static getAllAssignmentApproveList(approve:any,perPage:number,page:number,search_keywords:any,sort:any, column:string, order:string){
        let dataURL:string = `${this.serverURL}/assignment/list/approve1?approve=${approve}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, this.header); // use axios library getting request data
    }

    public static assignmentFinish(assignment_id:any) {
        let dataURL:string = `${this.serverURL}/assignment/finish1/${assignment_id}`;
        return axios.post(dataURL,null, this.header);
    }

    public static assignmentDelete(assignment_id:any) {
        let dataURL:string = `${this.serverURL}/assignment/destroy/${assignment_id}`;
        return axios.delete(dataURL,{ headers: {
            "Authorization" : `Bearer ${this.token}` ,
             _method: 'delete', 
            //  "server_url" : `${this.SEV_URL}`,
            //  "RESOURCE_SESSION" :localStorage.getItem("rs")??'',
             "client_domain" :  `${this.CLIENT_DOMAIN}`
            }
        });
    }

    public static assignmentApprove(assignment_id:any) {
        const token = localStorage.getItem('token');
        let dataURL:string = `${this.serverURL}/assignment/list/approve/${assignment_id}`;
        return axios.post(dataURL,null,  this.header);
    }

    public static showAssignment(assignment_id:any) {
        const token = localStorage.getItem('token');
        let dataURL:string = `${this.serverURL}/assignment/detail/${assignment_id}`;
        return axios.get(dataURL, this.header);
    }

    public static assignmentUpdate(data:any) {
        const token = localStorage.getItem('token');
        let id = data["assignment_id"];
        let dataURL:string = `${this.serverURL}/assignment/update1/${id}`;
        return axios.put(dataURL, data, this.header);
    }

    public static assignmentDeleteList(assignment:any) {
        const token = localStorage.getItem('token');
        let dataURL:string = `${this.serverURL}/assignment/list/case/destroy`;
        return axios.post(dataURL,assignment, this.header);
    }

    public static assignmentAdmit(assignment_id:any) {
        const token = localStorage.getItem('token');
        let dataURL:string = `${this.serverURL}/assignment/admit2/${assignment_id}`;
        return axios.get(dataURL, this.header);
    }

    public static assignmentSendEmail(assignment_id:any) {
        const token = localStorage.getItem('token');
        let dataURL:string = `${this.serverURL}/assignment/email/send1/${assignment_id}`;
        return axios.post(dataURL,null, this.header);
    }

}