import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import SaleService from "../../services/SaleService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../components/common/Loader";

interface IState{
    loading :boolean;
    hrs :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
}
interface IProps{}

let SaleList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        hrs :  [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0
    });
    const [search, setSearch] = useState("");  
    const [company_filter, setCompanyFilter] = useState("");
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const defaultColumns = [
        {
            "name" : 'user_id', 
            "label" : "利用者ID"
        },
        {
            "name" : 'name_jp',
            "label" : "漢字氏名"
        },
        {
            "name" : 'kana_name', 
            "label" : "カタカナ氏名"
        },
        {
            "name" : 'email', 
            "label" : "メールアドレス"
        },
        {
            "name" : 'tel_no', 
            "label" : "電話番号"
        },
        {
            "name" : 'privilege_grp_cd', 
            "label" : "職種"
        },//
        {
            "name" : 'company', 
            "label" : "会社名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                }
        },
        {
            "name" : 'dept_name', 
            "label" : "部門名",
             class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                }
        },//
        {
            "name" : 'position', 
            "label" : "役職"
        },//
        {
            "name" : 'respond_area', 
            "label" : "担当エリア"
        },
        {
            "name" : 'role', 
            "label" : "権限"
        },
        {
            "name" : 'admission_date', 
            "label" : "入会年月日"
        },
        {
            "name" : 'withdraw_date', 
            "label" : "退会年月日"
        },
        {
            "name" : 'withdraw_reason', 
            "label" : "退会理由"
        },
        {
            "name" : 'memo', 
            "label" : "特記事項"
        }
    ]

    const getData = async (company_filter:string, perPage:number, page:number, search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        SaleService.list(company_filter, perPage, page, search, sort, column, order).then((response)=>{
            console.log(response.data.data);
            setState({
                ...state,
                loading:false,
                hrs:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                console.log("Response1111 " +response.data.per_page);
                setShowNextColor("blue");
            }else{
                console.log("Response2222 " +response.data.per_page);
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page){
                console.log("Response3333 " +response.data.per_page);
                setShowPrevColor("grey");
            }
            // if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            //     setShowNextColor("grey");
            // }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }
    let {loading, hrs, perPage, page, column, order, totalRows, errorMessage} = state;
    const [selected, setSelected] = useState('base');
    const [sort, setSort] = useState("0");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
 
    useEffect(()=>{
        setState({...state, loading: true});
        getData(company_filter,perPage, page, search, sort, column, order);
         if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(company_filter,perPage, page+1, search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(company_filter,perPage, page, search, sort, column, order);
    };
    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        getData(company_filter,perPage, page, search, sort, column, order);
    };
    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

    const options = {
        filterType: 'checkbox' as any,
        responsive: "scrollMaxHeight" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        selectableRows: true as any,
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns : true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                hrs.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });

                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "営業者" +date;
                }

            let headers = [
                {
                    "key" : 'user_id', 
                    "label" : "利用者ID"
                },
                {
                    "key" : 'name_jp',
                    "label" : "漢字氏名"
                },
                {
                    "key" : 'kana_name', 
                    "label" : "カタカナ氏名"
                },
                {
                    "key" : 'email', 
                    "label" : "メールアドレス"
                },
                {
                    "key" : 'tel_no', 
                    "label" : "電話番号"
                },
                {
                    "key" : 'privilege_grp_cd', 
                    "label" : "職種"
                },//
                {
                    "key" : 'company', 
                    "label" : "会社名"
                },
                {
                    "key" : 'dept_name', 
                    "label" : "部門名"
                },//
                {
                    "key" : 'position', 
                    "label" : "役職"
                },//
                {
                    "key" : 'respond_area', 
                    "label" : "担当エリア"
                },
                {
                    "key" : 'role', 
                    "label" : "権限"
                },
                {
                    "key" : 'admission_date', 
                    "label" : "入会年月日"
                },
                {
                    "key" : 'withdraw_date', 
                    "label" : "退会年月日"
                },
                {
                    "key" : 'withdraw_reason', 
                    "label" : "退会理由"
                },
                {
                    "key" : 'memo', 
                    "label" : "特記事項"
                }
              ];
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            setCurrentPage(currentPage);
            changePage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(company_filter, state.perPage, state.page, e.target.value, sort, column, order);              
                break;
            default:
                break;
        }
      };
      const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "company_filter":
                setCompanyFilter(e.target.value);
                getData(e.target.value, state.perPage, state.page, search, sort, column, order);              
                break;
            case "sort":
                setSort(e.target.value);
                getData(company_filter, state.perPage, state.page, search, e.target.value, column, order);              
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
                
            default:
                break;
        }
     }; 

     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    
    return(
        
       <React.Fragment>
       <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <div className="row">
                            <div className="col-10">

                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(漢字氏名、カタカナ氏名、メールアドレス、会社名、部署、特記事項を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </div>
                            <div className="col-2 mt-4">
                                <DropDownList />
                            </div>
                        </div>
                    </header>
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="container">
                    <div className = "row">
                        <div className="col-md-4">
                            <h3 className='header-left-design'>
                                営業者一覧
                            </h3>
                        </div>
                        <div className="col-md-4">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </div>
                    </div> 
                    <div className="row pt-2 pb-2">
                        <div className="col-4">
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                        </div>
                        <div className="col-8 filter_select">
                                <div className="float-right">
                                    <label className="control-label">会社名</label>
                                    <select name="company_filter" className="filter" onChange={onSelect} aria-label=".form-select-sm example">
                                        <option value=""> 選択なし</option>
                                        <option value="FloBoard"> FloBoard</option>
                                        <option value="FloNet"> FloNet</option>
                                    </select>  
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                            previousPage();
                                            }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                        </button>
                                        <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                            nextPage();
                                            }}>
                                            <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button>
                                </div>
                            </div>
                    </div>
                    <div className='datatable freeze-sale-header freeze-sale-cell frozen-columns'>
                {/* <ThemeProvider theme={getMuiTheme()}> */}
                    <MUIDataTable
                        title = {""}
                        data = {hrs}
                        columns={defaultColumns}
                        options={options}
                    />
                     {/* </ThemeProvider> */}
                </div>
            </div>
       
        </React.Fragment>
    )

}

export default SaleList;