import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {};

const HomePage = (props: Props) => {
  return (
    // <div>HomePage</div>
    <><Outlet /></>
  );
};

export default HomePage;