import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header = {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client_domain" :  `${CLIENT_DOMAIN}`,
        // "RESOURCE_SESSION" :localStorage.getItem("rs")??''
       }
};

class BillService {
    list(work_month:any, bill_admit_status:string, delivery_status:string,work_company:string,recipient_list:any, perPage:number,del_flg:any,send_email:any, page:number,search_keywords:any,sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/bill/list1?work_month=${work_month}&bill_admit_status=${bill_admit_status}&delivery_status=${delivery_status}&work_company=${work_company}&recipient_list=${recipient_list}&list_length=${perPage}&del_flg=${del_flg}&send_email=${send_email}&search_keywords=${search_keywords}&page=${page}&sort=${sort}&column=${column}&order=${order}`;
        console.log(dataURL);
        return axios.get(dataURL, header);
    }

    deleteList(data:any){
        let dataURL:string = API_URL+"/bill/list/destroy1";
        return axios.post(dataURL,data,header);
    }

    //承認、却下、取消 ボタンAPI
    admitDate(data:any){
        let dataURL:string = API_URL+`/bill/set/date`;
        console.log(dataURL);
        return axios.post(dataURL,data,header);
    }

    showPdf(bill_id:any) {
        // let showLink="http://localhost:3000/api"
        let dataURL:string = API_URL+`/bill/view-bill-file1/${bill_id}`;
        return axios.get(dataURL, header);
    }

    dlPdf(bill_id:any) {
        let dataURL:string = API_URL+`/bill/dl-bill-pdf1/${bill_id}`;
        return axios.get(dataURL, header);
    }

    showBill(bill_id:any) {
        let dataURL:string = API_URL+`/bill/detail/${bill_id}`;
        return axios.get(dataURL, header);
    }

    billUpdate(datas:any) {
        let dataURL:string = API_URL+`/bill/update1`;
        return axios.put(dataURL, datas, header);
    }
}
export default new BillService();