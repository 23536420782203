import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client_domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};
class PurchaseOrderService {
    list(work_month:any, admit_status:string, delivery_status:string,work_company:string,recipient_list:any, perPage:number,del_flg:any,send_email:any, page:number,search_keywords:any,sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/purchaseorder/list1?work_month=${work_month}&admit_status=${admit_status}&delivery_status=${delivery_status}&work_company=${work_company}&recipient_list=${recipient_list}&list_length=${perPage}&del_flg=${del_flg}&send_email=${send_email}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        console.log("search ");
        console.log(search_keywords);
        console.log("key");

        // if(work_month !== undefined){
        //     dataURL += `&work_month=${work_month}`;
        // }
        return axios.get(dataURL, header);
    }
    showOrder(order_id:any) {
        let dataURL:string = API_URL+`/purchaseorder/detail/${order_id}`;
        return axios.get(dataURL, header);
    }

    //承認ボタンAPI
    admitDate(data:any){
        let dataURL:string = API_URL+`/purchaseorder/set/admit-date1`;
        console.log(dataURL);
        return axios.post(dataURL,data,header);
    }

    showPdf(order_id:any) {
        let dataURL:string = API_URL+`/purchaseorder/view-purchase-order-file1/${order_id}`;
        return axios.get(dataURL, header);
    }

    dlPdf(order_id:any) {
        let dataURL:string = API_URL+`/purchaseorder/dl-purchaseorder-pdf1/${order_id}`;
        return axios.get(dataURL, header);
    }

    orderUpdate(datas:any) {
        console.log(datas);
        let dataURL:string = API_URL+`/purchaseorder/update1`;
        return axios.put(dataURL, datas, header);
    }
    
    deleteList(data:any){
        let dataURL:string = API_URL+"/purchaseorder/list/destroy1";
        return axios.post(dataURL,data,header);
    }

    
}

export default new PurchaseOrderService();