import axios from 'axios';
import { exit } from 'process';
export class CaseListService{
    private static serverURL = process.env.REACT_APP_API_URL;
    // private static SEV_URL = process.env.REACT_APP_SERVER_URL;
    private static token = localStorage.getItem('token');
    private static CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;

    private static header = { 
        headers: {
                    "Authorization" : `Bearer ${this.token}` , 
                    // "server_url" : `${this.SEV_URL}`,   
                    "client_domain" :  `${this.CLIENT_DOMAIN}`,
                    "Access-Control-Allow-Origin": "*"
                }
    };
    public static getAllCaseList(filter_list:any,flb_sales_id:any,flb_hr_id:any,case_status:string,perPage:number,customer:any,saler:any, page:number,search_keywords:any, skill_list:[], sort:any, column:string, order:string, recurit_area:string){
        let dataURL:string = `${this.serverURL}/case/list?flb_sales_id=${flb_sales_id}&flb_hr_id=${flb_hr_id}&status=${case_status}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        if(skill_list.length > 0){
            for (let i = 0; i < skill_list.length; i++) {
                dataURL += `&skill_list[]=${skill_list[i]}`;
           } 
        }
        dataURL += `&recurit_area=${recurit_area}`;
   
        return axios.get(dataURL, this.header); // use axios library getting request data
    }

    public static getAllCases(filter_list:any,flb_sales_id:any,flb_hr_id:any,status:string,skill_list:[],search_keywords:any,search_skill:any,sort:any){
        let dataURL:string = `${this.serverURL}/cases/info?filter_list=${filter_list}&flb_sales_id=${flb_sales_id}&flb_hr_id=${flb_hr_id}&status=${status}&search_keywords=${search_keywords}&search_skill=${search_skill}&sort=${sort}`;
        if(skill_list.length > 0){
            for (let i = 0; i < skill_list.length; i++) {
                dataURL += `&skill_list[]=${skill_list[i]}`;
           } 
        }
      
        return axios.get(dataURL, this.header); // use axios library getting request data
    }

    public static create(){
        let dataURL:string = `${this.serverURL}/cases/create1`;
        return axios.get(dataURL, this.header); // use axios library getting request data
    }
    public static store(data:any){
    // public static store(status:string,customerId:string,caseName:string,appealCmnt:string,recuritArea:string,contactName:any,contactEmail:any,flbHrId:any,caseOverview:string,requiredSkill:any,startDatePlan:any,recruitNum:any,workPlace:any,requestDate:any){
        let dataURL:string = `${this.serverURL}/cases/store`;
        return axios.post(dataURL,data, this.header); // use axios library getting request data
    }

    public static show(encode:any){
        let dataURL:string = `${this.serverURL}/cases/${encode}`;
        return axios.get(dataURL, this.header);
    }

    public static delete(ids:any) {
        let dataURL:string = `${this.serverURL}/cases/list/destroy?ids[]=${ids}`;
        return axios.delete(dataURL, this.header);
    }

    public static edit(id:any){
        let dataURL:string = `${this.serverURL}/cases/${id}/edit`;
        return axios.get(dataURL, this.header);
    }

    public static copy(id:any){
        let dataURL:string = `${this.serverURL}/cases/copy/${id}`;
        return axios.get(dataURL, this.header);
    }

    public static update(data:any ,id:any) {       
        // if(data.required_skill.length <= 0){
        //     data.required_skill = null;
        // }
        let dataURL:string = `${this.serverURL}/cases/update1/${id}`;
        return axios.put(dataURL, data, this.header);
    }

    public static assignmentByCase(data:any) {
        let dataURL:string = `${this.serverURL}/assignment/store`;
        return axios.post(dataURL,data, this.header);
    }

    public static sendMail(data:any) {
        let dataURL:string = `${this.serverURL}/case/email/send1`;
        return axios.post(dataURL,data, this.header);
    }

    public static editByCase(assignment_id:any){
        let dataURL:string = `${this.serverURL}/assignment/edit-by-case/${assignment_id}`;
        return axios.get(dataURL, this.header);
    }

    public static assignmentUpdateByCase(data:any, assignment_id:any) {;
        let dataURL:string = `${this.serverURL}/assignment/update1/${assignment_id}`;
        return axios.put(dataURL,data, this.header);
    }



    public static getBulkMailData(data:any){
        let dataURL:string = `${this.serverURL}/case/list/getData1`;
        return axios.post(dataURL,data, this.header);
    }

    public static bulkMailsend(data:any){
        let dataURL:string = `${this.serverURL}/casebulk/email/send1 `;
        return axios.post(dataURL,data, this.header);
    }

    public static searchPerson(fields:any,value:any,flg:any){
        let data = {};
        data["fields"] = fields;
        data["value"] = value;
        data["flg"] = flg;
        let dataURL:string = `${this.serverURL}/person/search1`;
        return axios.post(dataURL,data, this.header);
    }

    public static searchPartner(fields:any,value:any,flg:any){
        let data = {};
        data["fields"] = fields;
        data["value"] = value;
        data["flg"] = flg;
        let dataURL:string = `${this.serverURL}/partner/search1`;
        return axios.post(dataURL,data, this.header);
    }

    public static deleteList(data:any) {
        let dataURL:string = `${this.serverURL}/case/list/destroy1`;
        return axios.post(dataURL,data,this.header);
    }

    public static destroy(id:any){
        let dataURL:string = `${this.serverURL}/cases/delete/${id}`;
        return axios.get(dataURL, this.header);
    }
}

