import axios from "axios";

const RESOURCE_LOGIN_URL = process.env.REACT_APP_RESOURCE_LOGIN_URL;
const API_URL = process.env.REACT_APP_API_URL;
const COMPANY_CODE = process.env.REACT_APP_COMPANY_CODE;

const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header = {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        "company_code" : `${COMPANY_CODE}`
       }
};

class AuthService {

  login(data:any) {
    let dataURL:string = API_URL + "/login";
    // data["server_url"] = SERVER_URL;
    return axios.post(dataURL, data);
  }

  resourceLogin(loginCode:string, uri:string){
    let redectUrl = window.location.origin+uri;
    window.location.href = `${RESOURCE_LOGIN_URL}?loginCode=${loginCode}&redectUrl=${redectUrl}`;
    // window.location.href = `${RESOURCE_LOGIN_URL}?loginCode=${loginCode}&redectUrl=${redectUrl}&server_url=${SERVER_URL}`;
  }
  logout() {
      localStorage.removeItem("user"); // remove JWT from local storage
      localStorage.removeItem("token"); // remove JWT from local storage
  }
  resend(){
    let dataURL:string = API_URL+"/verify/resend"
    return axios.get(dataURL,header);
  }

  store(data:any) {
    let dataURL:string = API_URL + '/verify/store';
    // console.log("Token start");
    // console.log(token);
    // console.log("Token end");
    // data["server_url"] = SERVER_URL;
    return axios.post(dataURL, data, header);
  }

  
  // login(username: string, password: string) {
  //   return axios
  //     .post(API_URL + "signin", {
  //       username,
  //       password
  //     })
  //     .then(response => {
  //       if (response.data.accessToken) {
  //         localStorage.setItem("user", JSON.stringify(response.data)); // store the user login information & JWT to local storage
  //       }

  //       return response.data;
  //     });
  // }
  // logout() {
  //   localStorage.removeItem("user"); // remove JWT from local storage
  // }
  getCurrentUser() {
    const userStr = localStorage.getItem("user"); // retrieve stored user information from local storage
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

  export default new AuthService();