import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileChange from './ProfileChange';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const ProfileChangeWrapper: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/setting') {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [location.pathname]);

  const handleClose = () => {
    setModalOpen(false);
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      {/* Render ProfileChange modal */}
      <ProfileChange open={modalOpen} onClose={handleClose} />
    </>
  );
};

export default ProfileChangeWrapper;