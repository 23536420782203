import React, {useEffect, useState} from 'react';
import InterviewReminderService from "../../services/InterviewReminderService";
import PartnerService from "../../services/PartnerService";
import {CaseListService} from "../../services/CaseService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import {ReminderModel} from "../../models/Reminder";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import $ from 'jquery';

interface URLParam {
    id : string;
}

interface IState{
    reminder : ReminderModel;
    case_list : [];
    staff_list : [];
}
interface IProps{}
let ReminderCreate:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();

    let [state , setState] = useState<IState>({
        reminder : {
            staff_id: "",
            person_id : 0,
            case_id : 0,
            title : "",
            description : "",
            phone : "",
            start_dt : "",
            notify_dt : "1",
            sms_voice_flg : "SMS"
        },
        case_list : [],
        staff_list : []
    });
    const [person_list, setPersonList] = useState([]);
    const [person_field, setPersonFieldList] = useState([]);
    const [person_list_display, setPersonListDisplay] = useState("none");  
    const [person_search_result , setSearchResult] = useState("");

    const [startDate, setStartDate] = useState(new Date());
   

    useEffect(()=>{
        if(id != undefined){
            InterviewReminderService.edit(id).then((response)=>{
                setState({
                    ...state,
                    reminder:response.data.reminders,
                    staff_list:response.data.staff_list,
                    case_list:response.data.case_list
                })
                console.log("person_name");
                console.log(response.data.reminders.person_name);
                setSearchResult(response.data.reminders.person_name);

            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }else{
            InterviewReminderService.create().then((response)=>{
                console.log(response);
                setState({
                    ...state,
                    staff_list:response.data.staff_list,
                    case_list:response.data.case_list,
                })
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }
       

    }, [id]);
    let {reminder ,case_list ,staff_list} = state;

    let assignPersonId = (e: React.ChangeEvent<HTMLSelectElement>): void => { // change event type declare in React
        var index = e.target.selectedIndex;
        setSearchResult(e.target[index].innerHTML);
        setState({
            reminder : {
                ...state.reminder,
                person_id : parseInt(e.target.value)  // input name set value 'event target value'
            },
            case_list : case_list,
            staff_list : staff_list
        });  
        setPersonListDisplay("none");       
    };


    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        if(id != undefined){
            for (let key in reminder) {
                if (key == "person_name") {
                    delete reminder[key];
                }
            }
            InterviewReminderService.update(reminder, id).then((response) => {
                window.location.href = "/interview/reminders";
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }else{
            InterviewReminderService.store(state.reminder).then((response) => {
                console.log(response);
                // window.location.href = "/interview/reminders";
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }
    };
  
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            reminder : {
                ...state.reminder,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            case_list : case_list,
            staff_list : staff_list
        });        
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            reminder : {
                ...state.reminder,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            case_list : case_list,
            staff_list : staff_list
        });
    }
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            reminder : {
                ...state.reminder,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
            case_list : case_list,
            staff_list : staff_list
        });
     };
    
    


    const personSearch = function(){
        CaseListService.searchPerson("InitialName,full_name,kana_name,alphabet_name",state.reminder.person_id,"persons").then((response) => {
            setPersonList(response.data.data);
            setPersonFieldList(response.data.info);
            setPersonListDisplay("block");
        //    window.location.href = "/cases";
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        })
    }

 
    const [selectedStartDate, setSelectedStartDate] = useState(null);

    const handleDateChange = function(date:any, column_name:any){
    let selected_data =(date != null)?date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()) : "";
    //let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate());        
        switch (column_name) {
            case "start_dt":
                setSelectedStartDate(date);
                setState({
                    reminder : {
                        ...state.reminder,
                       start_dt : date // input name 'username' set value 'event target value'
                    },
                    case_list : case_list,
                    staff_list : staff_list
                });
                break;
            default:
                break;
        }

    }

    const handleKeyPress = (event:React.KeyboardEvent)  => {
        if (event.key === "Backspace") {
            setSearchResult("");
        }
    };

    return(
        <React.Fragment>
            <DetailTopbar />
            <div className="container">
                <div className = "row">
                        <h3 className='header-left-design'>面談通知情報{(id !== undefined) ?"編集":"登録"}</h3>
                </div>
                <div className="row anken-detail">
                <form className="form create-form " onSubmit={store}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">担当者</span>
                                    <select name="staff_id" className="form-control" value={(state.reminder.staff_id != "")?state.reminder.staff_id:""} onChange={onSelect} aria-label=".form-select-sm example">
                                        <option value="" hidden> 未選択</option>
                                        {staff_list.map(staff => {
                                                if (staff !== null){
                                                    return(<option value={staff['user_id']}> {staff['name_jp']}</option>)
                                                }
                                            })
                                        }
                                    </select> 
                                </div>
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text'>要員名</span>
                                    <div className="row partner_search">
                                            <div className="col-9">
                                                <input type="text" name="person_id" value={(state.reminder.person_id != 0)?state.reminder.person_id:""} id="person_id" className="form-control" onKeyDown={handleKeyPress} onChange={updateInput} />
                                            </div>
                                            <div className="col-3">
                                                <button className="btn_standard btn_search w-100" onClick={personSearch} type="button">検索</button>
                                            </div> 
                                            {(id !== null) ?
                                                <span  style={{fontSize: "13px" }}>{person_search_result}</span> :
                                                ""
                                            }
                                        </div>
                                        <div className="row  mt-3" style={{display: person_list_display }}>
                                            <div className="col-12">
                                                <select name="partner_id" className="form-control" onChange={assignPersonId}>
                                                    <option value="" selected disabled></option>
                                                {person_list.length > 0 && person_list.map((key,val) => {
                                                    return(<option value={key['person_id']}>イニシャル{key["InitialName"]}、
                                                英語氏名{key["full_name"]}、 漢字氏名{key["alphabet_name"]}</option>);
                                                    })
                                                }
                                                </select>
                                                {
                                                person_list.length == 0 ?
                                                <span className='search-txt'>検索結果はありません</span>
                                                : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text'>案件名</span>
                                    <select name="case_id" className="input-box form-control" value={state.reminder.case_id} onChange={onSelect} aria-label=".form-select-sm example">
                                        <option value="" hidden> 未選択</option>
                                        {case_list.map(cs => {
                                                if (cs !== null){
                                                    return(<option value={cs['0']}> {cs['1']}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 input-box col-xs-12">
                                <span id='text' className="input-required">タイトル</span>
                                    <input type="text" name="title" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.reminder.title} onChange={updateInput}/>
                                </div>
                            </div>
    
                            <div className="row mt-3">
                                <div className="col-12 input-box">
                                    <span id='text' className="input-required">概要</span>
                                    <textarea className="form-control" rows={3} name = "description" id= "description" aria-label="With textarea" value={state.reminder.description} onChange={updateTextArea}></textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">電話番号</span>
                                    <input type="text" name="phone" id="phone" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.reminder.phone} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">開始日時</span>
                                    <DatePicker 
                                            value={(state.reminder.start_dt != null)?state.reminder.start_dt: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedStartDate} 
                                            onChange={date =>
                                                handleDateChange(date, "start_dt")
                                            }
                                            name="start_dt"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                </div> 
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 col-xs-12">
                                    <span id='text' className="input-required">SMS通知 (分)</span>
                                    <select name="notify_dt" id="notify_dt" className="input-box form-control" aria-label="form-select-sm example" value={state.reminder.notify_dt} onChange={onSelect}>
                                        <option value="1"> 開始日時の5分前</option>
                                        <option value="2"> 開始日時の10分前</option>
                                        <option value="3"> 開始日時の15分前</option>
                                        <option value="4"> 開始日時の20分前</option>
                                        <option value="5"> 開始日時の25分前</option>
                                        <option value="6"> 開始日時の30分前</option>
                                    </select>
                                </div>
                                <div className="col-md-6 col-xs-12 input-box horizontal">
                                    <span id='text'  className="input-required">通知型</span>
                                    <select name="sms_voice_flg" id="sms_voice_flg" className="input-box form-control" aria-label="form-select-sm example" value={state.reminder.sms_voice_flg} onChange={onSelect}>
                                        <option value="SMS"> SMS</option>
                                        <option value="PHONE"> PHONE</option>
                                        <option value="SMS+PHONE"> SMS+PHONE</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <button className="btn btn_standard" id="case_create_submit" type="submit">{(id !== undefined) ?"更新":"登録"}</button>
                            <Link to={`/interview/reminders`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                            
                        </div>
                    </div>
                    </form>
                </div>
                
            </div>
        </React.Fragment>
    );
};
export default ReminderCreate;