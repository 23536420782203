import React ,{useState , useEffect} from "react";
import { Base } from "../../models/Person";
import PersonService from "../../services/PersonService";
import {AssignmentService} from "../../services/AssignmentService";
import ResourceService from "../../services/ResourceService";

import {Link} from "react-router-dom";
import Topbar from "../../components/common/Topbar";
import DetailTopbar from "../../components/common/DetailTopbar";

import { AppBar, Toolbar, Typography, responsiveFontSizes } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {useParams,useLocation,  useNavigate} from "react-router-dom";
import ResourceImg from '../../components/common/ResourceImg';
import Loader from "../../components/common/Loader";

interface URLParam {
    id : string;
}

interface IState{
    loading :boolean;
    persons_arr : [];
    assignment_arr : [];
    work_arr : [];
    total : number;
    errorMessage : string;
}
interface IProps{}

let PersonsDetail:React.FC<IProps> = () =>{
    let {encode} = useParams<URLParam | any>();
    console.log(encode);
    let [state, setState] = useState<IState>({
        loading : false,
        persons_arr :  [],
        assignment_arr : [],
        work_arr : [],
        total : 0,
        errorMessage : "",
    });

  let [data , setData] = useState([]);
  const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
  let location = useLocation();

//   const str = "Helllo World";
//   console.log("Lower Case");
//   console.log(str.toLowerCase());
    useEffect(() => {
        if(encode){
            setState({
                ...state,
                loading : true,
            });
            PersonService.show(encode).then((response)=>{
                setState({
                    ...state,
                    loading : false,
                    persons_arr : response.data.person,
                    assignment_arr : (response.data.assignments !== null)?response.data.assignments:[],
                    work_arr : (response.data.works !== null)? response.data.works : []
                })
                setData(response.data.person);
            }).catch((error)=>{
                console.log(error);
                if(error.response.status == 403){
                    navigate('/403');
                }else if(error.response.status == 404 || error.response.status == 402){
                    navigate('/404');
                }

                setState({
                    ...state,
                    loading : false,
                    errorMessage : error.message
                })
            });
        }
    }, [encode, location]);

    let {loading, persons_arr, assignment_arr, work_arr, errorMessage} = state;
    let downloadURL = process.env.REACT_APP_DOWNLOADURL;
   
    let assignmentFinish =  (e:any,assignment_id:any) => {
        if(!window.confirm("割り当て終了しますか？")){
            return false;
        }

        AssignmentService.assignmentFinish(assignment_id).then((response) =>{
           window.location.reload();
        }).catch((error)=>{
            if(error.response.status == 403){
             alert(error.response.data.message);
            }
         });

    }

    const copy = () => {
        let id =  persons_arr['person_id'];
        localStorage.setItem("person_encode" , persons_arr["encode"]);

        PersonService.copy(id).then((response) => {
            console.log("PersonServiceResponses");
            console.log(response.data.url);
            const someUrl = "/" +response.data.url;
            // const someUrl = "http://localhost:3000/" +response.data.url;
            window.location.href = someUrl;
        }).catch((error)=>{
            if(error.response.status == 403){
             alert(error.response.data.message);
            }
         });
    }

    let assignmentDelete =  (e:any,assignment_id:any) => {
        if(!window.confirm("削除を実施します、大丈夫ですか？")){
            return false;
        }

        AssignmentService.assignmentDelete(assignment_id).then((response) =>{
           window.location.reload();
        }).catch((error)=>{
           if(error.response.status == 403){
            alert(error.response.data.message);
           }
        });
    }

    const company:string = persons_arr['person-company']?.toLowerCase();
    const company1:any = company?.indexOf("floboard");

    const company2:any = company?.indexOf("flonet");
    const company3:any = company?.indexOf("フロネット");
    // if(persons_arr['skill'] != null){
    //     if(Array.isArray(persons_arr['skill'])){
    //         persons_arr['skill'] = persons_arr['skill'].join(", ");
    //     }
    //     else{
    //         console.log(typeof(persons_arr['skill']));
    //         persons_arr['skill'] =  Object.entries(persons_arr['skill'])
    //         .map( ([key, value]) => {
    //             console.log(value);
    //         } )
    //     }
    // }
  
    const navigate = useNavigate();

    const caseDetail = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        window.location.href = `/cases/${encode}`
    }

    const navigateToPrevious = () => {
        if (localStorage.getItem('previousRoute') && localStorage.getItem('previousRoute')!= location.pathname) {
          navigate(localStorage.getItem('previousRoute')!);
        } else {

          navigate(localStorage.getItem('rootRoute')!); // Fallback to home if previousPath is not set
        }
      };
      const personDetail = function (person:any, encode:any) {
        if(person != null){
            localStorage.setItem('previousRoute', location.pathname);
            window.location.href = `/persons/${encode}`;
        }
    }

    console.log("role");
    console.log([1,2,3,4,5,6,7,9].includes(login_user.role));
    let priority = "";
    if(persons_arr["priority"] == 0){
        priority = "普通";
    }else if(persons_arr["priority"] == 1){
        priority = "推薦";
    }else if(persons_arr["priority"] == 2){
        priority = "優先";
    }else{
        priority = "重要";
    }

    return(
       <React.Fragment>
            <DetailTopbar /> 
            <div className="container">
                <div className="detail">
                    <div className = "row">
                        <div className="col-4 col-sm-4 col-xs-6">
                            <h3 className='header-left-design'>要員情報<span className='span-title'> (要員ID : {persons_arr['person_id']})</span> </h3>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-6">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </div>
                        <div className="col-4 col-sm-4 col-xs-6   mt_2">
                            {
                            (() => {
                                if(login_user.role ==1 || login_user.role ==2 || login_user.role ==3 || login_user.role == 4|| login_user.role == 5 || login_user.role == 7 || login_user.role == 9) {
                                return( <Link to={`/persons/${persons_arr["person_id"]}/edit`} className={((loading == true)?"disabled-link ": " ") + " edit-btn"} >
                                要員編集
                                </Link>)
                                }
                            })()
                            }
                            {/* <Link to={`/persons/${persons_arr["person_id"]}/edit`} className="header-back-btn">
                                要員編集
                            </Link> */}
                            {
                            (() => {
                                if(login_user.role ==1 || login_user.role ==2 || login_user.role ==3 || login_user.role == 4|| login_user.role == 7 || login_user.role == 9) {
                                return( <Link to={`/persons/assignment/${persons_arr["encode"]}`} className="text-decoration-none text-success fw-bold toriate-btn blue assign_btn btn_standard">
                                    割リ当て追加
                                </Link>)
                                }
                            })()
                            }
                            {/* <Link to={`/persons/assignment/${persons_arr["encode"]}`} className="text-decoration-none text-success fw-bold toriate-btn blue assign_btn btn_standard">
                                割リ当て追加
                            </Link> */}
                            <a onClick={() => {navigateToPrevious()}} 　className="header-back-btn-white mr_1" style={{position:"relative",top:"2px",padding:"2px"}}>戻る</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className="mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4 profile">
                                       <a href={(persons_arr['photo_path'] != null && persons_arr['photo_path'] != '')?ResourceService.download(`${persons_arr['photo_path']}`):((persons_arr['gender'] == "男")?'../images/businessman.jpg':'../images/businesswoman.jpg')} className="" download>
                                            {/* {(persons_arr['photo_path'] != null && persons_arr['photo_path'] != '')?
                                            <ResourceImg src={persons_arr["photo_path"]} className="img-circle" alt="icons"/> :
                                            <img src="images/businessman.jpg" className="img-circle" alt="icons"/>
                                            } */}
                                            <ResourceImg remoteSrc={persons_arr["photo_path"]} src={(persons_arr['gender'] == "男")?'../images/businessman.jpg':'../images/businesswoman.jpg'} className="img-circle" alt="icons" />
                                            {(persons_arr["proposable_flg"] == 0)?<span className="status_btn_1">営業不可</span>:<span className="status_btn_2" >営業可</span>}<br />
                                        </a>

                                    {/* <img src="..." className="img-circle rounded-start" alt="..." /> */}
                                    </div>
                                    <div className="col-md-8 mt-5">
                                        <h5 className="card-title mb-3">{persons_arr['InitialName']}</h5>
                                        <span>
                                            {
                                                (() =>{
                                                    if((persons_arr['resume_file_path'] != null && persons_arr['resume_file_path'] != '')){
                                                        return(
                                                            <a className= "text-decoration-none ml-3 text-success fw-bold btn_standard download_btn" href={(persons_arr['resume_file_path'] != null && persons_arr['resume_file_path'] != '')?ResourceService.download(`${persons_arr['resume_file_path']}`):'#'}  
                                                            >ダウンロード</a>
                                                        )
                                                    }else{
                                                        return(
                                                            <a className= "text-decoration-none ml-3 text-success fw-bold btn_standard_1 download_btn" href='#'>ダウンロード</a>
                                                        )
                                                    }
                                                })()
                                            }
                                            </span>
                                    </div>
                                </div>
                            </div>
                            {
                                (() => {
                                        if(login_user.role ==3 || login_user.role==4 || login_user.role ==7 ||login_user.role ==9){
                                            return(
                                                <div className = "row">
                                                    <div className="col-md-4">
                                                        <span>
                                                            <a href = "#"
                                                            onClick={() => {copy()}}>コピー作成</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                })()
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-sm-6 col-xs-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>基本情報 </h4>   
                                        </div>
                                    </div>
                                    <hr className="reset" />
                                    <table className="new-table">
                                        <tbody>
                                          
                                            {
                                            (() => {
                                                if(login_user.role !=8){
                                                    return(
                                                    <tr className="start">
                                                        <th>漢字氏名 :</th>
                                                        <td>{persons_arr["full_name"]}</td>
                                                    </tr>
                                                    )
                                                }
                                            })()
                                            }
                                            {/* <tr>
                                                <th>漢字氏名</th>
                                                <td>{persons_arr["full_name"]}</td>
                                            </tr> */}
                                            {
                                            (() => {
                                                if(login_user.role !=8){
                                                    return(
                                                    <tr>
                                                        <th>カタカナ氏名 :</th>
                                                        <td>{persons_arr["kana_name"]}</td>
                                                    </tr>
                                                    )
                                                }
                                            })()
                                            }
                                          
                                            {/* <tr>
                                                <th>カタカナ氏名</th>
                                                <td>{persons_arr["kana_name"]}</td>
                                            </tr> */}
                                            {
                                                (() => {
                                                    if(login_user.role !=8){
                                                        return(
                                                        <tr>
                                                            <th>英語氏名 :</th>
                                                            <td>{persons_arr["alphabet_name"]}</td>
                                                        </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                            (() => {
                                                return(
                                                <tr>
                                                    <th>要員ステータス :</th>
                                                    <td>{persons_arr['assign_status']}</td>
                                                </tr>
                                                )
                                            })()
                                            }
                                            <tr>
                                                <th>優先順位 :</th>
                                                <td>{priority}</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <hr className="reset" />
                                        <table className="new-table">
                                        <tbody>
                                            <tr >
                                                <th>ジョブタイトル :</th>
                                                <td>{persons_arr["jobtitle"]}</td>
                                            </tr>
                                            <tr>
                                                <th>イニシャル :</th>
                                                <td>{persons_arr["InitialName"]}</td>
                                            </tr>
                                            <tr>
                                                <th>性別 :</th>
                                                <td> {persons_arr["gender"]}</td>
                                            </tr>
                                            <tr>
                                                <th>国籍 :</th>
                                                <td>{persons_arr["country"]}</td>
                                            </tr>
                                            <tr>
                                                <th>年齢 :</th>
                                                <td>{persons_arr["birthday"]} 歳</td>
                                            </tr>
                                            <tr>
                                                <th>最寄り駅 :</th>
                                                <td>{persons_arr["nearest_station"]}</td>
                                            </tr>
                                            <tr>
                                                <th>来日年数 :</th>
                                                <td>{persons_arr["arrival_in_jp_ym"]}年</td>
                                            </tr>
                                            <tr>
                                                <th>経験年数 :</th>
                                                <td>{persons_arr["prof_start_ym"]}年</td>
                                            </tr>
                                            <tr>
                                                <th>日本語レベル :</th>
                                                <td>{persons_arr["japanese_level"]}</td>
                                            </tr>
                                            <tr>
                                                <th>英語レベル :</th>
                                                <td>{persons_arr["english_level"]}</td>
                                            </tr>
                                            <tr>
                                                <th>スキル :</th>
                                                <td>{persons_arr['skill']}</td>

                                            </tr>
                                            <tr>
                                                <th>対応フェーズ :</th>
                                                <td>{persons_arr["response_phase"]}</td>
                                            </tr>
                                            <tr>
                                                <th>特記事項 :</th>
                                                <td>{persons_arr["remarks"]}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <hr className="reset" />
                                        <table className="new-table">
                                        <tbody>
                                            {/* <tr>
                                                <th>社員ID（自社の場合のみ）</th>
                                                <td>{persons_arr["emp_id"]}</td>
                                            </tr> */}
                                            <tr>
                                                <th>新規稼働可能日 :</th>
                                                <td>{persons_arr['workstart_date']}</td>
                                            </tr>
                                                {
                                                    (() => {
                                                        if([1,2,3,4,6,8,9].includes(login_user.role)){
                                                            return(
                                                                <tr>
                                                                    <th>希望単価 :</th>
                                                                    <td>{persons_arr['proposal_price']}</td>
                                                                </tr>
                                                                )}
                                                            })()
                                                }
                                            <tr>
                                                <th>面接記録・評価 :</th>
                                                <td>{persons_arr['interview_remarks']}</td>
                                            </tr>
                                            <tr>
                                                <th>現場評価 :</th>
                                                <td>{persons_arr['customer_remarks']}</td>
                                            </tr>
                                            {
                                                (() => {
                                                    if(login_user.role !=5 && login_user.role !=7 && login_user.role !=8){
                                                        return(
                                                        <tr>
                                                            <th>公開フラグ :</th>
                                                            <td>{(persons_arr['publish_flg'] == 0)? '不可' : '可'}</td>
                                                        </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                            {/* <tr>
                                                <th>公開フラグ</th>
                                                <td>{(persons_arr['publish_flg'] == 0)? '不可' : '可'}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                    {
                        (() => {
                            if([3,4,6,7,9].includes(login_user.role)){
                                return(
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>調達情報 </h4>  
                                        </div>
                                    </div>
                                    <hr className="reset" />
                                    <table className="new-table">
                                        <tbody>
                                        {
                                                (() => {
                                                    if(login_user.role !=8 && login_user.role !=7){
                                                        return(
                                                        <tr>
                                                            <th>所属種別 :</th>
                                                            <td>{persons_arr["belong_type"]}</td>
                                                        </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                            {/* <tr>
                                                <th>所属種別 </th>
                                                <td>{persons_arr["belong_type"]}</td>
                                            </tr> */}
                                            {
                                                (() => {
                                                    if(login_user.role !=8 && login_user.role !=7){
                                                        return(
                                                        <tr>
                                                            <th>社員ID（自社の場合のみ） :</th>
                                                            <td>{persons_arr["emp_id"]}</td>
                                                        </tr>
                                                        )
                                                    }
                                                })()
                                            }
                                        {
                                                (() => {
                                                    if([3,4,6,7,9].includes(login_user.role)){
                                                        return(
                                                        <tr>
                                                            <th>所属先ID :</th>
                                                            <td>{persons_arr['person-company']}</td>
                                                        </tr>  
                                                        )
                                                    }
                                                })()
                                        }
                                        {/* <tr>
                                            <th>所属先ID</th>
                                            <td>{persons_arr['person-company']}</td>
                                        </tr> */}
                                        {
                                                (() => {
                                                    if([3,4,6,7,9].includes(login_user.role)){
                                                        return(
                                                        <tr>
                                                            <th> 所属先担当者 :</th>
                                                            <td>{persons_arr['belong_contact_nm']}</td>
                                                        </tr>  
                                                        )
                                                    }
                                                })()
                                        }
                                        {/* <tr>
                                            <th> 所属先担当者</th>
                                            <td>{persons_arr['belong_contact_nm']}</td>
                                        </tr> */}
                                         {
                                                (() => {
                                                    if([3,4,6,7,9].includes(login_user.role)){
                                                        return(
                                                        <tr>
                                                            <th>所属先担当者メール :</th>
                                                            <td> {persons_arr['belong_contact_email']}</td>
                                                        </tr>
                                                        )
                                                    }
                                                })()
                                        }
                                        {/* <tr>
                                            <th>所属先担当者メール</th>
                                            <td> {persons_arr['belong_contact_email']}</td>
                                        </tr> */}
                                        {
                                                (() => {
                                                    if([3,4,6,7,9].includes(login_user.role)){
                                                        return(
                                                        <tr>
                                                            <th>所属先担当者電話 :</th>
                                                            <td> {persons_arr['belong_contact_tel']}</td>
                                                        </tr>
                                                        )
                                                    }
                                                })()
                                        }
                                        {/* <tr>
                                            <th>所属先担当者電話</th>
                                            <td> {persons_arr['belong_contact_tel']}</td>
                                        </tr> */}
                                        {
                                                (() => {
                                                        if([3,4,6,7,9].includes(login_user.role) && (login_user.role !=7 ||(login_user.role ==7 && ((company1 !== -1) || (company2 !== -1) || (company3 !== -1))))){
                                                            return(
                                                                <tr>
                                                                    <th>個人連絡先 :</th>
                                                                    <td> {persons_arr['person_contact_info']}</td>
                                                                </tr>
                                                                )
                                                        }
                                                })()
                                        }
                                        {
                                                (() => {
                                                        if([3,4,6,7,9].includes(login_user.role) && (login_user.role !=7 ||(login_user.role ==7 && ((company1 !== -1) || (company2 !==-1) || (company3 !==-1))))){
                                                            return(
                                                                <tr>
                                                                    <th>個人メールアドレス :</th>
                                                                    <td>  {persons_arr['person_contact_email']}</td>
                                                                </tr>
                                                                )
                                                        }
                                                })()
                                        }
                                        {/* <tr>
                                            <th>個人連絡先</th>
                                            <td> {persons_arr['person_contact_info']}</td>
                                        </tr> */}
                                        {/* <tr>
                                            <th>個人メールアドレス</th>
                                            <td>  {persons_arr['person_contact_email']}</td>
                                        </tr> */}
                                        {
                                                (() => {
                                                        if([3,4,6,9].includes(login_user.role)){
                                                            return( <tr>
                                                                <th>コスト :</th>
                                                                <td> {persons_arr['person_cost']}</td>
                                                            </tr>)
                                                        }else if(login_user.role == 7){
                                                            return( <tr>
                                                                <th>希望単価 :</th>
                                                                <td> {persons_arr['person_cost']}</td>
                                                            </tr>)
                                                        }
                                                })()
                                        }
                                       
                                       {
                                                (() => {
                                                        if([3,4,6,9].includes(login_user.role)){
                                                            return( <tr>
                                                                <th>コスト備考 :</th>
                                                                <td> {persons_arr['cost_memo']}</td>
                                                            </tr>)
                                                        }else if(login_user.role == 7){
                                                            return( <tr>
                                                                <th>単価備考 :</th>
                                                                <td> {persons_arr['cost_memo']}</td>
                                                            </tr>)
                                                        }
                                                })()
                                        }
     
                                        </tbody>
                                       
                                    </table>
                                </div>
                            </div>
                              )
                            }
                        })()
                    }
    {
        (() => {
                if([1,2,3,4,5,6,7,9].includes(login_user.role)){
                    return(<div className="card mt-3 case_assign_list">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>割り当て状況</h4> 
                                        </div>
                                    </div>
                                    <hr className="reset" />
                                    {
                                            assignment_arr.length > 0 && assignment_arr.map((assignment, index)=> {
                                                let i = {index}.index;
                                                let name = (assignment["case_name"] != null)?assignment["case_name"]:"";
                                                return(
                                                        <div className="row case_list">
                                                            <div className="col-md-2">
                                                                <label className="person-lbl" htmlFor="caseName"> 案件名 :</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Link  to="" onClick={()=>caseDetail(assignment["encode"])}>{name} </Link>
                                                            </div>
                                                            <div className="col-md-4">
                                                               <button  onClick={(e) => {
                                                                            assignmentDelete(e,assignment["assignment_id"]);
                                                                            }} className="btn-finish flit-right finish-wariate" style={{position:"relative",bottom:"2px"}}>取り消し</button>
                                                                <button  onClick={(e) => {
                                                                            assignmentFinish(e,assignment["assignment_id"]);
                                                                            }} className="btn-finish flit-right finish-wariate" style={{position:"relative",bottom:"2px"}}>割り当て終了</button>
                                                             </div>
                                                        </div>
                                                         )
                                            })
                                        }   
                                </div>
                            </div>
                    )
                } 
            })()
            }
        
                            {
                                (() => {
                                    if(login_user.role ==1 || login_user.role ==2 || login_user.role ==3 || login_user.role ==4 || login_user.role ==5 || login_user.role ==6 || login_user.role ==9){
                                        return(
                                            <div className="card mt-3">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4>稼働履歴 </h4>   
                                                    </div>
                                                </div>
                                                <hr className="reset" />
                                                    {
                                                        work_arr.length > 0 && work_arr.map((work, index)=> {
                                                            let i = {index}.index;
                                                            return(
                                                                <div>
                                                                    <table className="new-table">
                                                                    <tr><th>起動時間： </th><td> {work['work_start_date'] +" ~ " + ((work['exit_date'] !== null)? work['exit_date']:"")}</td></tr>
                                                                    <tr><th>案件名123：</th><td> <Link to="" onClick={()=>caseDetail(work["encode"])} >{(work['case_name'] !== "") ? work['case_name'] : ''}</Link></td></tr>
                                                                    <tr><th>現場評価： </th><td>{work['customer_remarks']}</td></tr>
                                                                    </table>
                                                                    <hr className="reset" />
                                                                </div>
                                                            )
                                                        })
                                                    } 
                                              
                                            </div>
                                        </div>  
                                        )
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PersonsDetail;