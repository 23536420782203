import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#fff",
    color: "#eeeeee",
    '&:hover': {
      color: '#000'
    },
    hoverBg: "rgb(0, 23, 193)",
    activeBg: "rgb(0, 23, 193)",
    activeColor : "#fff",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
    
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;