import React ,{useState , useEffect} from "react";
import {PersonModel} from "../../models/Person";
import PersonService from "../../services/PersonService";
import PartnerService from "../../services/PartnerService";
import ResourceService from "../../services/ResourceService";
import { useLocation } from 'react-router-dom';

import {useParams, Link, useNavigate} from "react-router-dom";
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import DatePicker from "react-datepicker";
import {showErrorInfo, checkMaxLength , inputNumber} from "../../components/common/Helpers";

interface URLParam {
    id : string;
}

interface IState{
    persons : PersonModel
}
interface IProps{}

let PersonsCreate:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const login_user_company = (login_user != "" && login_user != null)? login_user.company.toString().toLowerCase() : "";
    let [state , setState] = useState<IState>({
        persons : {
            assign_status : '稼働中',
            proposable_flg : 0,
            priority : 0,
            InitialName: '',
            full_name: '',
            kana_name:'',
            alphabet_name: '',
            gender:'男',
            country: '',
            birthday:'',
            nearest_station:'',
            arrival_in_jp_ym:'',
            prof_start_ym:'',
            japanese_level:'ネイティブレベル',
            english_level:'ネイティブレベル',
            jobtitle:'システムエンジニア',
            workstart_date:'',
            skill:[],
            response_phase:'',
            remarks:'',
            belong_type: '自社',
            emp_id: '',
            resume_file_path : "",
            photo_path : "",
            belong_company_id: (login_user.role == 7)?login_user.company:null,
            belong_contact_nm: '',
            belong_contact_email : '',
            belong_contact_tel: '',
            person_contact_info:'',
            person_contact_email: '',
            person_cost: null,
            cost_memo: "",
            proposal_price : null,
            publish_flg: 0,
            interview_remarks: "",
            customer_remarks: ""
        }
    });

    let [resume_file, setResumeFile] = useState();
    let [photo, setPhoto] = useState();
    let skill : string[] = [];
    let [skill_list, setSkillList] = useState(skill); 
    let [updateSkill, setUpdateSkill] = useState(skill);
 
    let [other_disabled, setDisabled] = useState(true);
    let [other, setOther] = useState("");
    let [other_click_flag, setOtherClickFlag] = useState(false);

    let [selectedBirthday, setSelectedBirthday] = useState(null);
    let [selectedArrival, selectedArrivalInJapan] = useState(null);
    let [selectedProf, selectedProfStart] = useState(null);
    let [selectedWorkStartDate, setSelectedWorkStartDate] = useState(null);

    let location = useLocation();
    //console.log("Current Location");
    let search = location.search;
    // console.log(id);
    
    let [partner_list, setPartnerList] = useState([]);
    let [assign_status_list, setAssignStatusList] = useState([]);
    let [belong_type_list, setBelongTypeList] = useState([]);
    let [english_level_list, setEnglishLevelList] = useState([]);
    let [gender_list, setGenderList] = useState([]);
    let [japanese_level_list, setJapaneseLevelList] = useState([]);
    let [job_title_list, setJobTitleList] = useState([]);
    let [proposal_flg_list, setProposalFlgList] = useState([]);
    let [priority_list, setPriorityList] = useState([]);

    let [partner_list_display, setPartnerListDisplay] = useState("none");
    let [partner_search_result , setSearchResult] = useState("");
    let [company , setCompany] = useState("");
    let [assign_partner_id, setAssignPartnerID] = useState("");

    let [resume_file_name, setResumeFileName] = useState("ファイルを選択");
    let [photo_name, setPhotoName] = useState("ファイルを選択");
    let [show_resume_file_name, showResumeFileName] = useState("ファイルを選択");
    let [show_photo_name, showPhotoName] = useState("ファイルを選択");
    let [encode, setEncode] = useState("");
    let [initialname_error, setInitialNameError] = useState("none");
    let [alphabet_name_error, setAlphabetNameError] = useState("none");


    useEffect(()=>{
        if(id){
            
            PersonService.edit(id).then((response)=>{
                setState({
                    ...state,
                    persons:response.data.person,
                })
                setState({
                    persons : {
                        ...state.persons,
                        assign_status : response.data.person.assign_status,
                        proposable_flg : response.data.person.proposable_flg,
                        priority : response.data.person.priority,
                        InitialName: response.data.person.InitialName,
                        full_name: response.data.person.full_name,
                        kana_name: response.data.person.kana_name,
                        alphabet_name: response.data.person.alphabet_name,
                        gender:response.data.person.gender,
                        country: response.data.person.country,
                        birthday:response.data.person.birthday,
                        nearest_station:response.data.person.nearest_station,
                        arrival_in_jp_ym:response.data.person.arrival_in_jp_ym,
                        prof_start_ym:response.data.person.prof_start_ym,
                        japanese_level:response.data.person.japanese_level,
                        english_level:response.data.person.english_level,
                        jobtitle:response.data.person.jobtitle,
                        workstart_date: response.data.person.workstart_date,
                        skill: response.data.person.skill,
                        response_phase:response.data.person.response_phase,
                        remarks: response.data.person.remarks,
                        belong_type: response.data.person.belong_type,
                        emp_id: response.data.person.emp_id,
                        resume_file_path : response.data.person.resume_file_path,
                        photo_path : response.data.person.photo_path,
                        belong_company_id: response.data.person.company,
                        belong_contact_nm: response.data.person.belong_contact_nm,
                        belong_contact_email : response.data.person.belong_contact_email,
                        belong_contact_tel: response.data.person.belong_contact_tel,
                        person_contact_info: response.data.person.person_contact_info,
                        person_contact_email: response.data.person.person_contact_email,
                        person_cost: response.data.person.person_cost,
                        cost_memo: response.data.person.cost_memo,
                        proposal_price : response.data.person.proposal_price,
                        publish_flg: response.data.person.publish_flg,
                        interview_remarks: response.data.person.interview_remarks,
                        customer_remarks: response.data.person.customer_remarks
                    }
                })


                if(response.data.person.resume_file_path != ""){
                    showResumeFileName(response.data.person.resume_file_path);
                }
                if(response.data.person.photo_path != ""){
                    // alert(response.data.person.photo_path);
                    // const modifiedUrl = response.data.person.photo_path.replace('http://localhost:8000/', '');
                    showPhotoName(response.data.person.photo_path);
                }
                console.log("ResponsePersonData");
                console.log(response.data.select_options.assign_status);
                // setEmptyFileAndPhoto(response);
                setSkillList(response.data.person.skill);
                setUpdateSkill(response.data.person.skill);
                response.data.person.skill.map((skill:any, index:any)=> {
                    let key = "";
                    if(skill === "other"){
                        key = index + 1;
                    }
                    if(key !== ""){
                        setOther(response.data.person.skill[key]);  //bind data in other textbox
                        setDisabled(false);
                        setOtherClickFlag(false);
                    }
                });

                setEncode(response.data.person.encode);
                setAssignStatusList(response.data.select_options.assign_status);

                setCompany(response.data.person.company);
                setBelongTypeList(response.data.select_options.belong_type);
                setEnglishLevelList(response.data.select_options.english_level);
                setGenderList(response.data.select_options.gender);
                setJapaneseLevelList(response.data.select_options.japanese_level);
                setJobTitleList(response.data.select_options.jobtitle);
                setProposalFlgList(response.data.select_options.proposable_flg);
                setPriorityList(response.data.select_options.priority);
                console.log("company :"+ response.data.person.company);
                if(response.data.person.company != undefined){
                    setSearchResult("会社名:"+response.data.person.company);
                
                }
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                   }

                });
        }else{
            if(login_user.role == 7){
                setState({
                    persons : {
                        ...state.persons,
                        belong_company_id : login_user.company,
                         // input name set value 'event target value'
                    }
                })
            }

            if([3,4,5,7,9].includes(login_user.role)){
                setState({
                    persons : {
                        ...state.persons,
                        belong_contact_nm : login_user.name_jp,
                        belong_contact_email : login_user.email,
                        belong_contact_tel : login_user.tel_no,
                        belong_type : "他社",
                         // input name set value 'event target value'
                    }
                })
            }


            

            PersonService.create().then((response)=>
            {
                setAssignStatusList(response.data.select_options.assign_status);
                setBelongTypeList(response.data.select_options.belong_type);
                setEnglishLevelList(response.data.select_options.english_level);
                setGenderList(response.data.select_options.gender);
                setJapaneseLevelList(response.data.select_options.japanese_level);
                setJobTitleList(response.data.select_options.jobtitle);
                setProposalFlgList(response.data.select_options.proposable_flg);
                setPriorityList(response.data.select_options.priority);
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                   }

                });
        }

    }, [id]);

   

    let handleChange = (e:any) => {
        switch (e.target.name) {
            case "resume_file_path":
                setResumeFile(e.target.files[0]);
                break;
            case "photo_path":
                setPhoto(e.target.files[0]);
                break;
            default:
                break;
        }
    }


    let ccompany:any = company?.toLowerCase();
    //console.log(ccompany);
        // console.log("Event end");

    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => {
         // change event type declare in React
        checkMaxLength(event.target.getAttribute("maxlength"), event.target.value.length ,event.target.name);
        if(event.target.name == "other_input"){
            if(id != ""){
                state.persons.skill.map((required_skill, index) => {
                    let key = 0;
                    if(required_skill === "other"){
                        key = index+1;
                    }
                    //console.log("Key"+key);
                    //console.log(state.persons.skill[key]);
                    if(key > 0 &&  state.persons.skill[key] != undefined){
                        state.persons.skill[key] = other;
                    }else{
                        setOther(event.target.value);  
                        setOtherClickFlag(true);                  
                    }
                })   
            }else{
                setOther(event.target.value); 
                setOtherClickFlag(true);                   
            }

    }else if(event.target.name == "InitialName"){
        var pattern = /^[A-Za-z0-9 一-龠ぁ-ゔァ-ヴー々〆〤ａ-ｚＡ-Ｚ０-９./_　 ]+$/;
        var value = event.target.value;
        if (!pattern.test(value)) {
            setInitialNameError("block");
            setState({
                persons : {
                    ...state.persons,
                    [event.target.name] : value.slice(0, -1)  // input name 'username' set value 'event target value'
                }
            }); 

        }else{
            setInitialNameError("none");
            setState({
                persons : {
                    ...state.persons,
                    [event.target.name] : value  // input name 'username' set value 'event target value'
                }
            });  
        }
    }else if(event.target.name == "alphabet_name"){
        var pattern = /^[ 一-龠ぁ-ゔァ-ヴー々〆〤]+$/;
        // var pattern =/^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]+$/;
        var value = event.target.value;
       
        if (pattern.test(value)) {
            setAlphabetNameError("block");
            setState({
                persons : {
                    ...state.persons,
                    [event.target.name] : ''  // input name 'username' set value 'event target value'
                }
            }); 

        }else{
            setAlphabetNameError("none");
            setState({
                persons : {
                    ...state.persons,
                    [event.target.name] : value  // input name 'username' set value 'event target value'
                }
            }); 

           
           
        }
    }else{ 
        setState({
            persons : {
                ...state.persons,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        }); 
    } 

    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        checkMaxLength(event.target.getAttribute("maxlength"), event.target.value.length ,event.target.name);
        setState({
            persons : {
                ...state.persons,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    }
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            persons : {
                ...state.persons,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
     };



     const handleKeyPress = (event:React.KeyboardEvent)  => {
        if (event.key === "Backspace") {
            setSearchResult("");
        }
      };
    
    //  const handleSkill = function(e:any){
    //     let isChecked = e.target.checked;
    //     let value = e.target.value;
    //     if(isChecked === true){
    //         skill_list.push(value);
    //         setSkillList(skill_list);
    //         setState({
    //             persons : {
    //                 ...state.persons,
    //                 skill: skill_list  // input name 'username' set value 'event target value'
    //             }
    //         });
    //     }
    //     else{            
    //         const skill = skill_list.filter(function(skill){
    //             return skill != value; 
    //          });
    //          setSkillList(skill);
    //          setState({
    //             persons : {
    //                 ...state.persons,
    //                 skill: skill  // input name 'username' set value 'event target value'
    //             }
    //         });
    //     }

        
    // }


    
    const handleSkill = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(value === "other"){
            if(isChecked === true){
                setDisabled(false);
            }else{
                setOther("");
                setDisabled(true);
                state.persons.skill.map((required_skill, index) => {
                    let key = 0;
                    if(required_skill === "other"){
                        delete state.persons.skill[index];
                        key = index+1;
                    }
                    if(key >0){
                        delete state.persons.skill[key];
                    }
                    // state.cases.required_skill[key] = other;
                    console.log(state.persons.skill);
                    console.log(key);
                })

            }
            setOtherClickFlag(true);
        }
       
        if(isChecked === true){
            skill_list.push(value);
            setSkillList(skill_list);

            console.log(skill_list);

            setState({
                persons : {
                    ...state.persons,
                    skill: skill_list  // input name 'username' set value 'event target value'
                }
            });
        }
        else{            
            const skill = skill_list.filter(function(skill){
                return skill != value; 
             });
             setSkillList(skill);
             setState({
                persons : {
                    ...state.persons,
                    skill: skill  // input name 'username' set value 'event target value'
                }
            });
        }

        
    }

    const fileUpload = function(event:any){
        let file_name = event.target.files[0].name;
        
        console.log("File NAme " + file_name);
        // console.log(event.target.name);
        switch (event.target.name) {
            case "resume_file_path":
                setResumeFileName(file_name);
                console.log(resume_file_name);
                break;
            case "photo_path":
                setPhotoName(file_name);
                console.log(photo_name);
                break;
            default:
                break;
        }
        setState({
            persons : {
                ...state.persons,
                [event.target.name] : event.target.files[0]  // input name 'username' set value 'event target value'
            }
        });
    }

    const partnerSearch = function(){
        setAssignPartnerID("");
        PartnerService.search("company",state.persons.belong_company_id,"persons").then((response) => {
            setPartnerList(response.data.data);
            setPartnerListDisplay("block");
        //    window.location.href = "/cases";
        })
    }

    let assignPartnerId = (e: React.ChangeEvent<HTMLSelectElement>): void => { // change event type declare in React
        var index = e.target.selectedIndex;
        setSearchResult(e.target[index].innerHTML);
        setState({
            persons : {
                ...state.persons,
                belong_company_id : parseInt(e.target.value),
                 // input name set value 'event target value'
            }
        })
        setAssignPartnerID(e.target.value);
        setPartnerListDisplay("none");       
    };

    const destroy = () => {
        let ans = window.confirm("コピー作成された要員情報を削除しても宜しいでしょうか。");
        if(ans){
            PersonService.destroy(id).then((response) => {
                window.location.href = "/persons/"+localStorage.getItem("person_encode");
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                    window.location.href = "/persons/"+localStorage.getItem("person_encode");
                }
            })
        }
    }


    let store = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        // console.log(state.persons);
        // console.log("store123");
        // for (let key in state.persons) {
        //     if (key == "skill") {
        //         delete state.persons[key];
        //     }
        // }

       
        
        if(id != undefined){
            // if(other != "" && other_click_flag == true){
            if(other != ""){
                state.persons.skill.map((required_skill, index) => {
                    let key = 0;
                    if(required_skill === "other"){
                        key = index+1;
                        state.persons.skill[key] = other;
                        setOtherClickFlag(false);
                    }
                    // state.cases.required_skill[key] = other;
                   
                });
            }


            for (let key in state.persons) {
                if ((key == "resume_file_path" || key == "photo_path") && typeof(state.persons[key]) == "string") {
                    // state.persons[key] = "";
                    delete state.persons[key];
                }
                delete state.persons["company"];
            }
            state.persons.skill.filter((skill) => skill !== null);

            PersonService.update(state.persons, id).then((response) => {
                successRedirect(response.data);  
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
    
            })
        }else{
            if(other != "" && other_click_flag == true){
                state.persons.skill.push(other);
                setOtherClickFlag(false);
            }
            state.persons.skill.filter((skill) => skill !== null);
            PersonService.store(state.persons).then((response) => {
                console.log(response);
                successRedirect(response.data);
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
          
    
            })
        }
       
    };

    const successRedirect = function(data:any){
        console.log(data);
        // console.log(state.persons.photo_path);
        if(state.persons.resume_file_path == null && state.persons.photo_path  == null){
            window.location.href = "/persons/"+data.encode;
        }else{
            if(state.persons.resume_file_path != null && state.persons.photo_path  != null) {
                ResourceService.apiUpload(state.persons.resume_file_path, 'resume_file_path/', data.resume_file_name).then((res) => {
                    ResourceService.apiUpload(state.persons.photo_path, 'photo_path/', data.photo_name).then((res) => {
                        window.location.href = "/persons/"+data.encode;                        
                    });
                });
            }else if(state.persons.resume_file_path  != null){
                    ResourceService.apiUpload(state.persons.resume_file_path, 'resume_file_path/',data.resume_file_name).then((res) =>{
                        window.location.href = "/persons/"+data.encode;                        
                    });                   
            }else if(state.persons.photo_path  != null){

                    ResourceService.apiUpload(state.persons.photo_path, 'photo_path/', data.photo_name).then((res) =>{
                        console.log(state.persons.photo_path);
                        window.location.href = "/persons/"+data.encode;
                    });                             
            }
        }    
    }
    const handleDateChange = function(date:any, column_name:any){
        let selected_data:any = "";
        if(date !== null){
            selected_data = date.getFullYear() +"/"+ (date.getMonth() + 1).toString().padStart(2, "0")+"/"+(date.getDate());
        }
        switch (column_name) {
            case "birthday":
                setSelectedBirthday(date);
                setState({
                    persons : {
                        ...state.persons,
                       birthday : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "arrival_in_jp_ym":  
                selectedArrivalInJapan(date);
                setState({
                    persons : {
                        ...state.persons,
                        arrival_in_jp_ym : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "prof_start_ym":  
                selectedProfStart(date);
                setState({
                    persons : {
                        ...state.persons,
                        prof_start_ym : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;
            case "workstart_date":  
                setSelectedWorkStartDate(date);
                // setSelectedWorkStartDate(date);
                setState({
                    persons : {
                        ...state.persons,
                        workstart_date : selected_data // input name 'username' set value 'event target value'
                    }
                });
                break;

            default:
                break;
        }
       
    }

    function handleKeyUp(event:any) {
        if (event.key === 'Escape') {
          // Perform an action when the Escape key is released
        }
      }

    const all_skill_list = {
        "JAVA": "JAVA",
        "Javascript": "Javascript",
        "C": "C/C++",
        "VB": "VB",
        "VB.NET": "VB.NET",
        "VBA": "VBA",
        "C#" : "C#",
        "asp" : "ASP.NET",
        "PLSQL" : "PLSQL(PL/SQL)",
        "ruby" : "Ruby",
        "python" : "Python",
        "cobol" : "COBOL",
        "Shell" : "Shell",
        "perl" : "Perl",
        "SAP": "SAP(ABAP)",
        "CRM" : "CRM",
        "Salesforce" : "Salesforce",
        "PHP" : "PHP",
        "iOS(Swift)" : "iOS(Swift)",
        "ObjectC": "ObjectC",
        "Android": "Android",
        "Linux" : "Linux",
        "Oracle" : "Oracle",
        "sqlserver" : "SQL Server",
        "postgre": "Postgre SQL",
        "db2" : "DB2",
        "pmo" : "PMO",
        "infra_construction":"インフラ構築",
        "infra_maintenance" : "インフラ運用保守",
        "system_maintenance" : "システム運用保守",
        "leader_exp": "リーダー経験",
        "handoutai": "半導体"
    }
    const required_skill = skill_list;

    const skillArray = Object.values(required_skill || []);

   

    // if(id!==undefined && [1,2,5,6,8].includes(login_user.role)){
    //     setDisabled(true);
    // }else{
    //     setDisabled(false);
    // }
    console.log("ID " +id);
    return(
        <React.Fragment>
         <DetailTopbar />
         <div className="container">
             <div className = "row">
                 <h3 className='header-left-design'>要員情報{(id !== undefined) ?"編集":"登録"}</h3>
             </div>                
            <div className="row">
               <form onSubmit={store} className="form create-form">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    (() => {
                                        if((id !== undefined) || [3,4,7,9].includes(login_user.role)) {
                                            return(
                                            <React.Fragment>
                                                <div className="col-md-4">
                                                    <span className="text">要員ステータス</span>
                                                    <select className="form-control" name="assign_status" onChange={onSelect} value={state.persons.assign_status} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}>
                                                        {assign_status_list.map(assign_status => {
                                                               console.log("assign status" + assign_status);
                                                                // if (assign_status !== null){
                                                                    return(<option value={assign_status}> {assign_status}</option>)
                                                                // }
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-md-4">
                                                    <span className="text">営業可否</span>
                                                    <select className="form-control" name="proposable_flg" onChange={onSelect} value={state.persons.proposable_flg} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}>
                                                    {proposal_flg_list.map(proposable_flg => {
                                                                if (proposable_flg !== null){
                                                                    return(<option value={proposable_flg[0]}> {proposable_flg[1]}</option>)
                                                                }
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-md-4">
                                                    <span className="text">優先順位</span>
                                                    <select className="form-control" name="priority" onChange={onSelect} value={state.persons.priority} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}>
                                                    {priority_list.map(priority => {
                                                                if (priority !== null){
                                                                    return(<option value={priority[0]}> {priority[1]}</option>)
                                                                }
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </React.Fragment>
                                            )
                                         }
                                    })()
                                }
                                {/* <div className="col-md-6">
                                    <span className="text">要員ステータス（選択）</span>
                                    <select className="form-control" name="assign_status" onChange={onSelect} value={state.persons.assign_status}>
                                        {assign_status_list.map(assign_status => {
                                                if (assign_status !== null){
                                                    return(<option value={assign_status}> {assign_status}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div> */}
                                {/* <div className="col-md-6">
                                    <span className="text">営業可否</span>
                                    <select className="form-control" name="proposable_flg" onChange={onSelect} value={state.persons.proposable_flg}>
                                    {proposal_flg_list.map(proposable_flg => {
                                                if (proposable_flg !== null){
                                                    return(<option value={proposable_flg[0]}> {proposable_flg[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="row">
                                <h5 id="title">基本情報</h5>
                            </div>
                            {
                                (() => {
                                    if((id != undefined) || ([3,4,7,9].includes(login_user.role))) {
                                        return(
                                            <React.Fragment>
                                                <div className="row"> 
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span className="input-required text" id='spantext'>イニシャル</span>
                                                        <input type="text"  className="form-control" onChange={updateInput} value={state.persons.InitialName} name="InitialName" maxLength={30} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}/>
                                                        <span className="error"  style={{display: initialname_error }}>漢字とromaji しか入力出来ないです</span>
                                                    </div>
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span className="input-required text" >漢字氏名</span>
                                                        <input type="text"  className="form-control" name="full_name" onChange={updateInput} value={state.persons.full_name}  maxLength={50} disabled={(id!==undefined && [1,2,5,6].includes(login_user.role))?true:false}/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span className="input-required text">カタカナ氏名</span>
                                                        <input type="text"  className="form-control" name="kana_name" onChange={updateInput} value={state.persons.kana_name}  maxLength={50} disabled={(id!==undefined && [1,2,5,6].includes(login_user.role))?true:false}/>
                                                    </div>
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span className="text">英語氏名</span>
                                                        <input type="text"  className="form-control" name="alphabet_name" onChange={updateInput} value={state.persons.alphabet_name}  maxLength={50} disabled={(id!==undefined && [1,2,5,6].includes(login_user.role))?true:false}/>
                                                        <span className="error"  style={{display: alphabet_name_error }}>英語しか入力出来ないです</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12">
                                                        <span  className="text">性別</span>
                                                        <select className="input-box form-control" name="gender" onChange={onSelect} value={state.persons.gender} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}>
                                                        {gender_list.map(gender => {
                                                                    if (gender !== null){
                                                                        return(<option value={gender}> {gender}</option>)
                                                                    }
                                                                })
                                                            }
                                    
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span className="input-required text">国籍</span>
                                                        <input type="text"  className="form-control" name="country" onChange={updateInput} value={state.persons.country}  maxLength={30} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span className="input-required text">生年月日</span>
                                                        <DatePicker 
                                                                value={(state.persons.birthday != null)?state.persons.birthday: ""}
                                                                dateFormat="yyyy/MM/dd"
                                                                locale="ja"
                                                                selected={selectedBirthday} 
                                                                onChange={date =>
                                                                    handleDateChange(date, "birthday")
                                                                }
                                                                name="birthday"
                                                                autoComplete = "off"
                                                                isClearable
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}
                                                                />
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 input-box">
                                                        <span  className="text">最寄り駅</span>
                                                        <input type="text" name="nearest_station" onChange={updateInput} value={state.persons.nearest_station}   className="form-control" maxLength={30} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}/>       
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12 input-box">
                                                        <span  className="text">来日年数</span>
                                                        <DatePicker 
                                                                value={(state.persons.arrival_in_jp_ym != null)?state.persons.arrival_in_jp_ym: ""}
                                                                dateFormat="yyyy/MM/dd"
                                                                locale="ja"
                                                                selected={selectedArrival} 
                                                                onChange={date =>
                                                                    handleDateChange(date, "arrival_in_jp_ym")
                                                                }
                                                                name="arrival_in_jp_ym"
                                                                autoComplete = "off"
                                                                isClearable
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}
                                                                />

                                                        {/* <input type="text" id="arrival_in_jp_ym" name="arrival_in_jp_ym" onChange={updateInput} value={state.persons.arrival_in_jp_ym}   className="form-control"/>  */}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 input-box">
                                                        <span  className="text">経験年数</span>
                                                        <DatePicker 
                                                                value={(state.persons.prof_start_ym != null)?state.persons.prof_start_ym: ""}
                                                                dateFormat="yyyy/MM/dd"
                                                                locale="ja"
                                                                selected={selectedProf} 
                                                                onChange={date =>
                                                                    handleDateChange(date, "prof_start_ym")
                                                                }
                                                                name="prof_start_ym"
                                                                autoComplete = "off"
                                                                isClearable
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}
                                                                />

                                                        {/* <input type="text" id="prof_start_ym" name="prof_start_ym" onChange={updateInput} value={state.persons.prof_start_ym}   className="form-control"/>          */}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span  className="text">日本語レベル</span>
                                                        <select className="input-box form-control" name="japanese_level" onChange={onSelect} value={state.persons.japanese_level} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}>
                                                            {japanese_level_list.map(japanese_level => {
                                                                    if (japanese_level !== null){
                                                                        return(<option value={japanese_level}> {japanese_level}</option>)
                                                                    }
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span  className="text">英語レベル</span>
                                                        <select className="input-box form-control" name="english_level" onChange={onSelect} value={state.persons.english_level} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}>
                                                        {english_level_list.map(english_level => {
                                                                    if (english_level !== null){
                                                                        return(<option value={english_level}> {english_level}</option>)
                                                                    }
                                                                })
                                                            }
                                                        </select>     
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span className="input-required text">ジョブタイトル</span>
                                                        <select className="input-box form-control" name="jobtitle" onChange={onSelect} value={state.persons.jobtitle} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}>
                                                        {job_title_list.map(jobtitle => {
                                                                if (jobtitle !== null){
                                                                    return(<option value={jobtitle}> {jobtitle}</option>)
                                                                }
                                                            })
                                                        }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span  className="text">新規稼働可能日</span>
                                                        <DatePicker 
                                                                value={(state.persons.workstart_date != null)?state.persons.workstart_date: ""}
                                                                dateFormat="yyyy/MM/dd"
                                                                locale="ja"
                                                                selected={selectedWorkStartDate} 
                                                                onChange={date =>
                                                                    handleDateChange(date, "workstart_date")
                                                                }
                                                                name="workstart_date"
                                                                autoComplete = "off"
                                                                isClearable
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}
                                                                />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 input-box">
                                                        <span className="input-required text">スキル</span>
                                                        <div className="check-list" data-filter="skill_list"  id="case-filter-by-skill" >
                                                            <div className="pl-5">
                                                                    <div className="row">
                                                                    {Object.keys(all_skill_list).map((key, index) => (
                                                                        <div  key={index} className="col-md-6 col-lg-3 col-sm-12 mb-3">
                                                                            <input type="checkbox" id={key} value={key}  checked ={skillArray.includes(key)? true: false}  onChange={e => handleSkill(e)} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}/>
                                                                            <label htmlFor={key}> {all_skill_list[key]}</label>
                                                                        </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className="row input-box">
                                                                    <div className="col-lg-2 col-md-5 col-sm-6 col-xs-6">
                                                                        <input type="checkbox" id="other"  value="other"  checked ={skillArray.includes("other")? true: false} onChange={e => handleSkill(e)} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}/>
                                                                        <label htmlFor="other"  className="text"> その他</label>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-7  col-sm-6 col-xs-6 pl-0  float-left">
                                                                        <input type="text" id="other_input"  className="form-control" maxLength={50} name="other_input" value={other}  onChange={updateInput} disabled = {(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:other_disabled}/>
                                                                    </div>
                                                                </div>  
                                                            </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span>対応フェーズ</span>
                                                        <input type="text" name="response_phase"  className="form-control"  onChange={updateInput} value={state.persons.response_phase} maxLength={150} />
                                                    </div>
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span>特記事項</span>
                                                        <textarea className="form-control" name="remarks"  onChange={updateTextArea} value={state.persons.remarks} maxLength={250}></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span>履歴書 ({show_resume_file_name})</span><br/>
                                                        <label className="file">
                                                        <input name="resume_file_path"  className="form-control" type="file" onChange={fileUpload}/>{resume_file_name}
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 col-xs-12  input-box">
                                                        <span>写真 ({show_photo_name})</span><br/>
                                                        <label className="file">
                                                        <input name="photo_path"  className="form-control" type="file" onChange={fileUpload}/>{photo_name}
                                                        </label>
                                                    </div>
                                                </div> */}
                                            </React.Fragment>
                                        )
                                    }
                                })()
                            }
                            {/* <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span className="input-required" id='spantext'>イニシャル</span>
                                    <input type="text"  className="form-control" onChange={updateInput} value={state.persons.InitialName} name="InitialName" maxLength={30} />
                                    <span className="error"  style={{display: initialname_error }}>漢字とromaji しか入力出来ないです</span>
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span className="input-required" >漢字氏名</span>
                                    <input type="text"  className="form-control" name="full_name" onChange={updateInput} value={state.persons.full_name}  maxLength={50}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span className="input-required">カタカナ氏名</span>
                                    <input type="text"  className="form-control" name="kana_name" onChange={updateInput} value={state.persons.kana_name}  maxLength={50}/>
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span>英語氏名</span>
                                    <input type="text"  className="form-control" name="alphabet_name" onChange={updateInput} value={state.persons.alphabet_name}  maxLength={50}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                    <span>性別</span>
                                    <select className="input-box form-control" name="gender" onChange={onSelect} value={state.persons.gender} >
                                    {gender_list.map(gender => {
                                                if (gender !== null){
                                                    return(<option value={gender}> {gender}</option>)
                                                }
                                            })
                                        }
                
                                    </select>
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span className="input-required">国籍</span>
                                    <input type="text"  className="form-control" name="country" onChange={updateInput} value={state.persons.country}  maxLength={30}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span className="input-required">生年月日</span>
                                    <DatePicker 
                                            value={(state.persons.birthday != null)?state.persons.birthday: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedBirthday} 
                                            onChange={date =>
                                                handleDateChange(date, "birthday")
                                            }
                                            name="birthday"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            />
                                </div>
                                <div className="col-md-6 col-xs-12 input-box">
                                    <span>最寄り駅</span>
                                    <input type="text" name="nearest_station" onChange={updateInput} value={state.persons.nearest_station}   className="form-control" maxLength={30}/>       
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-md-6 col-xs-12 input-box">
                                    <span>来日年数</span>
                                    <DatePicker 
                                            value={(state.persons.arrival_in_jp_ym != null)?state.persons.arrival_in_jp_ym: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedArrival} 
                                            onChange={date =>
                                                handleDateChange(date, "arrival_in_jp_ym")
                                            }
                                            name="arrival_in_jp_ym"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            /> */}

                                    {/* <input type="text" id="arrival_in_jp_ym" name="arrival_in_jp_ym" onChange={updateInput} value={state.persons.arrival_in_jp_ym}   className="form-control"/>  */}
                                {/* </div>
                                <div className="col-md-6 col-xs-12 input-box">
                                    <span>経験年数</span>
                                    <DatePicker 
                                            value={(state.persons.prof_start_ym != null)?state.persons.prof_start_ym: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedProf} 
                                            onChange={date =>
                                                handleDateChange(date, "prof_start_ym")
                                            }
                                            name="prof_start_ym"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            /> */}

                                    {/* <input type="text" id="prof_start_ym" name="prof_start_ym" onChange={updateInput} value={state.persons.prof_start_ym}   className="form-control"/>          */}
                                {/* </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span>日本語レベル</span>
                                    <select className="input-box form-control" name="japanese_level" onChange={onSelect} value={state.persons.japanese_level} >
                                        {japanese_level_list.map(japanese_level => {
                                                if (japanese_level !== null){
                                                    return(<option value={japanese_level}> {japanese_level}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span>英語レベル</span>
                                    <select className="input-box form-control" name="english_level" onChange={onSelect} value={state.persons.english_level}>
                                    {english_level_list.map(english_level => {
                                                if (english_level !== null){
                                                    return(<option value={english_level}> {english_level}</option>)
                                                }
                                            })
                                        }
                                    </select>     
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span className="input-required">ジョブタイトル</span>
                                    <select className="input-box form-control" name="jobtitle" onChange={onSelect} value={state.persons.jobtitle}>
                                    {job_title_list.map(jobtitle => {
                                            if (jobtitle !== null){
                                                return(<option value={jobtitle}> {jobtitle}</option>)
                                            }
                                        })
                                    }
                                    </select>
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span>新規稼働可能日</span>
                                    <DatePicker 
                                            value={(state.persons.workstart_date != null)?state.persons.workstart_date: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedWorkStartDate} 
                                            onChange={date =>
                                                handleDateChange(date, "workstart_date")
                                            }
                                            name="workstart_date"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            />
                                </div>
                            </div> */}
                            {/* <div className="row mt-3">
                                <div className="col-12 input-box">
                                    <span id='text' className="input-required">要求スキル</span>
                                    <div className="check-list" data-filter="skill_list"  id="case-filter-by-skill" >
                                        <div className="pl-5">
                                                <div className="row">
                                                {Object.keys(all_skill_list).map((key, index) => (
                                                    <div  key={index} className="col-md-6 col-lg-3 col-sm-12 mb-3">
                                                        <input type="checkbox" id={key} value={key}  checked ={skillArray.includes(key)? true: false}  onChange={e => handleSkill(e)}/>
                                                        <label htmlFor={key}> {all_skill_list[key]}</label>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-5 col-sm-6 col-xs-6">
                                                    <input type="checkbox" id="other"  value="other" checked ={skillArray.includes("other")? true: false} onChange={e => handleSkill(e)}/>
                                                    <label htmlFor="other"> その他</label>
                                                </div>
                                                <div className="col-lg-4 col-md-7  col-sm-6 col-xs-6 pl-0  float-left">
                                                    <input type="text" id="other_input"  className="form-control" name="other_input"  value={other}  onChange={updateInput} disabled = {other_disabled} />
                                                </div>
                                            </div>  
                                        </div>
                                </div>
                            </div> */}
                            <div className="row mb_1">
                                {
                                    (() => {
                                        if((id != undefined) || ([3,4,7,9].includes(login_user.role))) {
                                        return(
                                        <div className="col-md-6 col-xs-12  input-box">
                                            <span  className="text">対応フェーズ</span>
                                            <input type="text" name="response_phase"  className="form-control"  onChange={updateInput} value={state.persons.response_phase} maxLength={150} disabled={(id!==undefined && [1,2,5,6,8].includes(login_user.role))?true:false}/>
                                        </div>
                                        )
                                        }
                                    })()
                                }
                                {/* <div className="col-md-6 col-xs-12  input-box">
                                    <span>対応フェーズ</span>
                                    <input type="text" name="response_phase"  className="form-control"  onChange={updateInput} value={state.persons.response_phase} maxLength={150} />
                                </div> */}
                                  {
                                    (() => {
                                        if((id != undefined) || ([1,2,3,4,7,9].includes(login_user.role))) {
                                        return(
                                            <div className="col-md-6 col-xs-12  input-box">
                                                <span  className="text">特記事項</span>
                                                <textarea className="form-control" name="remarks"  onChange={updateTextArea} value={state.persons.remarks} maxLength={250} disabled={(id!==undefined && [6,8].includes(login_user.role))?true:false}></textarea>
                                            </div>
                                        )
                                        }
                                    })()
                                }
                                {/* <div className="col-md-6 col-xs-12  input-box">
                                    <span>特記事項</span>
                                    <textarea className="form-control" name="remarks"  onChange={updateTextArea} value={state.persons.remarks} maxLength={250}></textarea>
                                </div> */}
                            </div>
                            {
                                (() => {
                                        if((id != undefined) || ([3,4,5,7,9].includes(login_user.role))) {
                                        return(
                                            <div className="row">
                                                <div className="col-md-6 col-xs-12  input-box">
                                                    <span  className="text">履歴書 ({show_resume_file_name})</span><br/>
                                                    <label className="file">
                                                    <input name="resume_file_path"  className="form-control" type="file" onChange={fileUpload} disabled={(id!==undefined && [1,2,6,8].includes(login_user.role))?true:false}/>{resume_file_name}
                                                    </label>
                                                </div>
                                                <div className="col-md-6 col-xs-12  input-box">
                                                    <span  className="text">写真 ({show_photo_name})</span><br/>
                                                    <label className="file">
                                                    <input name="photo_path"  className="form-control" type="file" onChange={fileUpload} disabled={(id!==undefined && [1,2,6,8].includes(login_user.role))?true:false}/>{photo_name}
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    })()
                            }
                            {/* <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span>履歴書 ({show_resume_file_name})</span><br/>
                                    <label className="file">
                                    <input name="resume_file_path"  className="form-control" type="file" onChange={fileUpload}/>{resume_file_name}
                                    </label>
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span>写真 ({show_photo_name})</span><br/>
                                    <label className="file">
                                    <input name="photo_path"  className="form-control" type="file" onChange={fileUpload}/>{photo_name}
                                    </label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="row">
                                <h5 id="title" >調達情報</h5>
                            </div>
                            <div className="row">
                            {/* person create form and check role */}
                            {([3,4,9].includes(login_user.role) && id == undefined)?    // && id !== undefined 削除2024/07/10
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属種別</span>
                                    <select name="belong_type" id="type" onChange={onSelect} value={state.persons.belong_type} style={{width:'100%'}}>
                                        {belong_type_list.map(belong_type => {
                                                if (belong_type !== null){
                                                    return(<option value={belong_type}> {belong_type}</option>)
                                                }
                                            })
                                        }
                                    </select>                   
                                </div>
                                 :""
                            }
                            {/* person edit form and check role */}
                            {([1,2,3,4,5,6,9].includes(login_user.role) && id !== undefined)? //&& id !== undefined 削除2024/07/10
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属種別</span>
                                    <select name="belong_type" id="type" onChange={onSelect} value={state.persons.belong_type} style={{width:'100%'}} disabled={([1,2,5,6].includes(login_user.role))?true:false}>
                                        {belong_type_list.map(belong_type => {
                                                if (belong_type !== null){
                                                    return(<option value={belong_type}> {belong_type}</option>)
                                                }
                                            })
                                        }
                                    </select>                   
                                </div>
                                 :""
                            }
                                {/* person create form and verify user role */}
                                {(login_user.role != 7 && id==undefined )? //新規登録
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">社員ID（自社の場合のみ）</span>
                                    <input type="text" name="emp_id"  className="form-control" onChange={updateInput} value={state.persons.emp_id} maxLength={30}/>
                                </div>
                                 :""
                                }
                                {/* person edit form and verify user role */}
                                {([3,4,5,6,9].includes(login_user.role) && id!==undefined)? //編集
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">社員ID（自社の場合のみ）</span>
                                    <input type="text" name="emp_id"  className="form-control" onChange={updateInput} value={state.persons.emp_id} maxLength={30} disabled={([1,2,5,6].includes(login_user.role))?true:false}/>
                                </div>
                                 :""
                                }

                                {(login_user.role == 7)?
                                    <div className="col-md-6 col-xs-12  input-box">
                                        <span  className="text">所属先</span>
                                        <input type="text" name="belong_type"  className="form-control hidden" value={state.persons.belong_type} />
                                        <input type="text" name="belong_company_id"  className="form-control" maxLength={50}  onChange={updateInput} value={state.persons.belong_company_id} />
                                    </div>
                                    :""
                                }

                            </div>
                            <div className="row">
                            {/* person create form and check role */}
                            {([3,4,9].includes(login_user.role) && id==undefined)?  //新規登録
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属先</span>
                                    <div className="row partner_search">
                                        <div className="col-9">
                                            <input type="text" name="belong_company_id" id="belong_company_id" maxLength={50} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" 
                                            value={state.persons.belong_company_id} onChange={updateInput} onKeyDown={handleKeyPress} />
                                        </div>
                                        <div className="col-3">
                                            <button className="btn_standard btn_search w-100" onClick={partnerSearch} type="button">検索</button>
                                        </div> 
                                        <span>{partner_search_result}</span>
                                     </div>
                                    <div className="row  mt-3 " style={{display: partner_list_display }}>
                                        <div className="col-12  input-box">
                                            <select name="partner_id" className="form-control" value={assign_partner_id} onChange={assignPartnerId}>
                                                <option value="" selected disabled></option>
                                            {partner_list.length > 0 && partner_list.map((key,val) => {
                                                return(<option value={key['partner_id']}>会社名:{key["company"]}</option>);
                                                })
                                            }
                                            </select>
                                            {
                                              partner_list.length == 0 ?
                                               <span>検索結果はありません</span>
                                               : null
                                            }
                                        </div>
                                    </div>                   
                                </div>
                               :""
                            }
                            {/* person edit form and check role */}
                            {([3,4,5,6,9].includes(login_user.role)   && id!==undefined)? // 編集
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属先ID</span>
                                    <div className="row partner_search">
                                        <div className="col-9">
                                            <input type="text" name="belong_company_id" id="belong_company_id" maxLength={50} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={state.persons.belong_company_id} onChange={updateInput} onKeyDown={handleKeyPress}  disabled={([1,2,5,6].includes(login_user.role))?true:false}/>
                                        </div>
                                        <div className="col-3">
                                            <button className="btn_standard btn_search w-100" onClick={partnerSearch} type="button" disabled={([1,2,5,6].includes(login_user.role))?true:false}>検索</button>
                                        </div>
                                        <span>{partner_search_result}</span>
                                     </div>
                                    <div className="row  mt-3 " style={{display: partner_list_display }}>
                                        <div className="col-12  input-box">
                                            <select name="partner_id" className="form-control" value={assign_partner_id} onChange={assignPartnerId}>
                                            <option value="" selected disabled></option>
                                            {partner_list.length > 0 && partner_list.map((key,val) => {
                                                return(<option value={key['partner_id']}>会社名{key["company"]}</option>);
                                                })
                                            }
                                            </select>
                                            {
                                              partner_list.length == 0 ?
                                               <span>検索結果はありません</span>
                                               : null
                                            }
                                        </div>
                                    </div>                   
                                </div>
                               :""
                            }
                              
                                {/* person create form and verify role */}
                                {([3,4,5,7,9].includes(login_user.role) && id == undefined)? //新規登録
                                    <div className="col-md-6 col-xs-12  input-box">
                                        <span  className="text">所属先担当者</span>
                                        <input type="text" name="belong_contact_nm"  className="form-control" maxLength={30}  onChange={updateInput} value={state.persons.belong_contact_nm} />
                                    </div>
                                    :""
                                }
                                {/* person edit form and verify role */}
                                {([3,4,5,6,7,9].includes(login_user.role) && id !== undefined)? //編集
                                    <div className="col-md-6 col-xs-12  input-box"> 
                                        <span className="text">所属先担当者</span>
                                        <input type="text" name="belong_contact_nm"  className="form-control" maxLength={30}  onChange={updateInput} value={state.persons.belong_contact_nm} disabled={([1,2,5,6].includes(login_user.role))?true:false} />
                                    </div>
                                    :""
                                }
                            </div>
                            {/* person create form and verify role */}
                            {([3,4,5,7,9].includes(login_user.role) && id==undefined)? //新規登録
                            <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属先担当者メール</span>
                                    <input type="email" name="belong_contact_email"  className="form-control" maxLength={100}  onChange={updateInput} value={state.persons.belong_contact_email} />    
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属先担当者電話</span>
                                    <input type="text" name="belong_contact_tel" className="form-control"  maxLength={50}  onChange={updateInput} value={state.persons.belong_contact_tel} />
                                </div>
                            </div>
                            :""
                            }
                             {/* person edit form and verify role */}
                            {([3,4,5,6,7,9].includes(login_user.role) && id!==undefined)?  //編集
                            <div className="row">
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属先担当者メール</span>
                                    <input type="email" name="belong_contact_email"  className="form-control" maxLength={100}  onChange={updateInput} value={state.persons.belong_contact_email} disabled={([1,2,5,6].includes(login_user.role))?true:false} />    
                                </div>
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">所属先担当者電話</span>
                                    <input type="text" name="belong_contact_tel" className="form-control"  maxLength={50}  onChange={updateInput} value={state.persons.belong_contact_tel} disabled={([1,2,5,6].includes(login_user.role))?true:false} />
                                </div>
                            </div>
                            :""
                            }
                            {/* person create form and verify role */}
                            {(([3,4,5,9].includes(login_user.role) || 
                            (login_user.role== 7 && (login_user_company.indexOf("floboard") > -1 ||
                            login_user_company.indexOf("flonet") > -1 ||
                            login_user_company.indexOf("フロネット") > -1
                            ))) && id==undefined)?
                                <div className="row">
                                    <div className="col-md-6 col-xs-12  input-box">
                                        <span  className="text">個人連絡先</span>
                                        <input type="text" name="person_contact_info"  className="form-control" maxLength={50}  onChange={updateInput} value={state.persons.person_contact_info} />    
                                    </div>
                                    <div className="col-md-6 col-xs-12  input-box">
                                        <span  className="text">個人メールアドレス</span>
                                        <input type="text" name="person_contact_email"  className="form-control" maxLength={50}  onChange={updateInput} value={state.persons.person_contact_email} />
                                    </div>
                                </div>
                                :""
                            }
                            {/* person edit form and verify role */}
                            {(([3,4,5,6,9].includes(login_user.role) || 
                            (login_user.role== 7 && company!==undefined && (ccompany.indexOf("floboard") > -1 ||
                            ccompany.indexOf("flonet") > -1 ||
                            ccompany.indexOf("フロネット") > -1
                            ))) && id!==undefined)?
                                    <div className="row">
                                        <div className="col-md-6 col-xs-12  input-box">
                                            <span  className="text">個人連絡先</span>
                                            <input type="text" name="person_contact_info"  className="form-control" maxLength={50}  onChange={updateInput} value={state.persons.person_contact_info} disabled={([1,2,6].includes(login_user.role))?true:false}/>    
                                        </div>
                                        <div className="col-md-6 col-xs-12  input-box">
                                            <span  className="text">個人メールアドレス</span>
                                            <input type="text" name="person_contact_email"  className="form-control" maxLength={50}  onChange={updateInput} value={state.persons.person_contact_email} disabled={[1,2,6].includes(login_user.role)?true:false}/>
                                        </div>
                                    </div>
                                :""
                            }
                            <div className="row">
                                {(id != undefined) || ([3,4,7,9].includes(login_user.role))?
                                <div className="col-md-3 col-xs-6  input-box">
                                    <span className="input-required text">{(login_user.role == 7)? "希望単価": "コスト"}</span>
                                    <input type="number" name="person_cost"  className="form-control" min="0"   onKeyPress={inputNumber}  maxLength={50}  onChange={updateInput} value={state.persons.person_cost} disabled={([1,2,5,6].includes(login_user.role)&&id!==undefined)?true:false} />
                                </div>
                                  :""
                                }
                                {/* person create form and verify role */}
                                {([3,4,7,9].includes(login_user.role) && id == undefined)?  // 登録
                                <div className="col-md-3 col-xs-6  input-box">
                                    <span  className="text">{(login_user.role == 7)? "単価備考": "コスト備考"}</span>
                                    <input type="text" name="cost_memo"  className="form-control" maxLength={50}  onChange={updateInput} value={state.persons.cost_memo} /> 
                                </div>
                                    :""
                                }
                                  {/* person edit form and verify role */}
                                {([3,4,5,6,7,9].includes(login_user.role) && id != undefined)? //編集
                                <div className="col-md-3 col-xs-6  input-box">
                                    <span  className="text">{(login_user.role == 7)? "単価備考": "コスト備考"}</span>
                                    <input type="text" name="cost_memo"  className="form-control" maxLength={50}  onChange={updateInput} value={state.persons.cost_memo} disabled={([1,2,5,6].includes(login_user.role))?true:false}/> 
                                </div>
                                    :""
                                }
                                {/* person create form and verify role */}
                                {([4,9].includes(login_user.role) && id==undefined)? // 登録
                                <div className="col-md-3 col-xs-6  input-box">
                                    <span  className="text">希望単価</span>
                                    <input type="text"  className="form-control" name="proposal_price" maxLength={50} onChange={updateInput} value={state.persons.proposal_price} />     
                                </div>
                                 :""
                                }
                                 {/* person edit form and verify role */}
                                 {([1,2,3,4,6,9].includes(login_user.role) && id!==undefined)? // 編集
                                <div className="col-md-3 col-xs-6  input-box">
                                    <span  className="text">希望単価</span>
                                    <input type="text"  className="form-control" name="proposal_price"  maxLength={50} onChange={updateInput} value={state.persons.proposal_price} disabled={([1,2,3,7,5,6].includes(login_user.role))?true:false} />     
                                </div>
                                 :""
                                }
                                {/* person create form and verify role */}
                                {([3,4,9].includes(login_user.role) && id == undefined)? //登録
                                <div className="col-md-3 col-xs-6  input-box">
                                    <span  className="text">公開フラグ</span>
                                    <select name="publish_flg"  className="form-control"  onChange={onSelect} value={state.persons.publish_flg} >
                                        <option value="0">不可</option>
                                        <option value="1">可</option>
                                    </select>
                                </div>
                                 :""
                                }
                                {/* person edit form and verify role */}
                                {([1,2,3,4,6,9].includes(login_user.role) && id !== undefined)? //編集
                                <div className="col-md-3 col-xs-6  input-box">
                                    <span  className="text">公開フラグ</span>
                                    <select name="publish_flg"  className="form-control"  onChange={onSelect} value={state.persons.publish_flg} disabled={([1,2,7,5,6].includes(login_user.role))?true:false}>
                                        <option value="0">不可</option>
                                        <option value="1">可</option>
                                    </select>
                                </div>
                                 :""
                                }
                            </div>
                            <div className="row">
                                 {/* person create form and verify role */}
                                {([1,2,3,4,5,7,9].includes(login_user.role) && id == undefined)? //登録
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">面接記録・評価</span>
                                    <textarea className="form-control" name="interview_remarks" maxLength={250} onChange={updateTextArea} value={state.persons.interview_remarks}></textarea>     
                                </div>
                                 :""
                                }
                                {/* person edit form and verify role */}
                                {(id !== undefined)?
                                <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">面接記録・評価</span>
                                    <textarea className="form-control" name="interview_remarks" maxLength={250} onChange={updateTextArea} value={state.persons.interview_remarks} disabled={[6,8].includes(login_user.role)?true:false}></textarea>     
                                </div>
                                 :""
                                }
                                {/* person create form and verify role */}
                                {([1,2,3,4,5,7,8,9].includes(login_user.role) && id == undefined)?  //登録
                                    <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">現場評価</span>
                                    <textarea className="form-control" name="customer_remarks" maxLength={250} onChange={updateTextArea} value={state.persons.customer_remarks}></textarea>
                                    </div>
                                    :""
                                }
                                 {/* person edit form and verify role */}
                                {(id !== undefined)?
                                    <div className="col-md-6 col-xs-12  input-box">
                                    <span  className="text">現場評価</span>
                                    <textarea className="form-control" name="customer_remarks" maxLength={250} onChange={updateTextArea} value={state.persons.customer_remarks} disabled={(login_user.role==6)?true:false}></textarea>
                                    </div>
                                    :""
                                }
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 text-center">
                                    <button className="btn btn_standard" id="case_create_submit" type="submit">{(id !== undefined) ?"更新":"登録"}</button>
                                    {
                                        (() => {
                                            if(search == "?process=copy") {
                                            return( 
                                                <button className="btn btn_standard" id="case_copy_delete" onClick={() => {destroy()}} >削除</button>
                                            )
                                            }else{
                                                return( 
                                                    (id !== undefined) ?
                                                        <Link to={`/persons/${encode}`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                                        :<Link to={`/persons`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                                )  
                                            }
                                        })()
                                    }
                                    {/* {(id !== undefined) ?
                                        <Link to={`/persons/${encode}`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                        :<Link to={`/persons`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
               </form>
            </div>
         </div>
     </React.Fragment>
    )
};
export default PersonsCreate;