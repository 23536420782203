import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "./DropDownList";

const DetailTopbar = () => {
  return (
    <AppBar
        position="fixed"
        sx={{
            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
            ml: sizeConfigs.sidebar.width,
            boxShadow: "unset",
            backgroundColor: colorConfigs.topbar.bg,
            color: colorConfigs.topbar.color
        }}
        >
        <Toolbar>
                <Typography variant="h6">
                <header className="top-baner-detail">
                    <div className="row">
                        <div className="col-10 p-0 floboard_logo">
                            {/* <div className="pl-3">
                                <h2 className="">株式会社 FloBoard</h2>
                            </div> */}
                        </div>
                        <div className="col-2 mt-4">
                            <DropDownList />
                        </div>
                    </div>
                </header>
                </Typography>
            </Toolbar>
        </AppBar>
  );
};

export default DetailTopbar;