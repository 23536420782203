import React, {useEffect, useState} from 'react';
import ProcedureService from "../../services/ProcedureService";
import PurchaseOrderService from "../../services/PurchaseOrderService";
import BillService from "../../services/BillService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import $ from 'jquery';
import {AccountingModel} from "../../models/Accounting";
import { useLocation } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {PDFDocument} from 'pdf-lib';
import { saveAs } from 'file-saver';
import {generatePDF} from "../../components/common/Helpers";

// import PrintSvgsAsPdf from './PrintSvgsAsPdf';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';

interface URLParam {
    id : string;
}

interface IState{
    accounting_model : AccountingModel;
}
interface IProps{}
let SVGDownload:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();
    let location = useLocation();
    let search = location.search;

    let [state , setState] = useState<IState>({
        accounting_model : {
            amount : "",
            bill_no : "", 
            created_date : "",
            partner : "",
            case : "",
            buyup_order_no : "",
            from_date : "",
            to_date : "",
            case_desc : "",
            work_id : "",
            deduct_price : "",
            excess_price : "",
            hours_low : "",
            hours_high : "",
            biko : "",
            work_hours : "",
            excess_hours : "",
            deduct_hours : "",
            effort_val: "",
            excess_unit_price:"",
            deductible_unit_price:"",
            unit_money: "",
            excess_amount: "",
            deduct_amount: "",
            sale_order_amount: "",
            subtotal: "",
            expense_subtotal: "",
            consumption_tax: "",
            total: "",
            payment_site: "",
            person_nm: "",
            bill_memo: "",
            invoice_no: "",
            sales_tax_rate: "",
        },
     });

    const [svgContent, setSvgContent] = useState(null);
    const [svgContents, setSvgContents] = useState<string[]>([]);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [file_name, setFileName] = useState("");

    useEffect(()=>{
        if(id != undefined){
            let data = {}
            data["id"] = id;
            if(search == "?type=1")
            {
                ProcedureService.createPurchaseOrder(data).then((response)=>{
                    let filename =  response.data.data.data.filename;
                    let exampleSvg = response.data.data.data.template;
                    // exampleSvg +=  response.data.data.data.template;
                    setSvgContents(exampleSvg!);
                    setFileName(filename.replace(".svg", '.pdf'));
                    // Example SVG data
                    // const fetchedSvgContents = [exampleSvg,exampleSvg];
                    // setSvgContents(fetchedSvgContents);
                }).catch((error) => {
                    // console.log(error);
                    // setState({
                    //     ...state,
                    // })
                })
            }else if(search == "?type=3"){
                PurchaseOrderService.showPdf(id).then((response)=>{
                    console.log(response);
                    let filename =  response.data.data.data.pdf_file_name;                    ;
                    let exampleSvg = response.data.data.data.template;
                    console.log(exampleSvg);
                    console.log(filename);
                    // exampleSvg +=  response.data.data.data.template;
                    setSvgContents(exampleSvg!);
                    setFileName(filename.replace(".svg", '.pdf'));

                    // Example SVG data
                    // const fetchedSvgContents = [exampleSvg,exampleSvg];
                    // setSvgContents(fetchedSvgContents);
                }).catch((error) => {
                    console.log(error);
                    // setState({
                    //     ...state,
                    // })
                })
                
            }else if(search == "?type=4"){
                BillService.showPdf(id).then((response)=>{
                    let filename =  response.data.data.data.filename;
                    let exampleSvg = response.data.data.data.template;
                    // exampleSvg +=  response.data.data.data.template;
                    setSvgContents(exampleSvg!);
                    setFileName(filename.replace(".svg", '.pdf'));
                    // Example SVG data
                    // const fetchedSvgContents = [exampleSvg,exampleSvg];
                    // setSvgContents(fetchedSvgContents);
                }).catch((error) => {
                    // console.log(error);
                    // setState({
                    //     ...state,
                    // })
                })
                
            }else{
                ProcedureService.createBill(data).then((response)=>{
                    let filename =  response.data.data.data.filename;
                    let exampleSvg = response.data.data.data.template;
                    // exampleSvg +=  response.data.data.data.template;
                    setSvgContents(exampleSvg!);
                    setFileName(filename.replace(".svg", '.pdf'));
                    // Example SVG data
                    // const fetchedSvgContents = [exampleSvg,exampleSvg];
                    // setSvgContents(fetchedSvgContents);
                }).catch((error) => {
                    // console.log(error);
                    // setState({
                    //     ...state,
                    // })
                })
            }
          
        }
       

    }, [id]);
    let {accounting_model} = state;

    // Function to handle downloading the SVG
    const downloadSvg = () => {
        const blob = new Blob([svgContent!], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.svg';
        link.click();
        URL.revokeObjectURL(url);
    };

    // Function to handle printing the SVG
    const printSvg = () => {
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow!.document.write('<html><head><title>Print SVG</title></head><body>');
        printWindow!.document.write(svgContent!);
        printWindow!.document.write('</body></html>');
        printWindow!.document.close();
        printWindow!.focus();
        printWindow!.print();
    };

    // //   // Example SVG contents
    //   const svgContents = [
    //     '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /></svg>',
    //     '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100" height="100" style="fill:blue" /></svg>',
    //     // Add more SVG contents as needed
    // ];

    const handleDownloadPdf = async () => {
        generatePDF(svgContents, file_name);
    }

    // const handleDownloadPdf = async () => {
    //     const svgElement = document.getElementById('svg-container');

    //     try {
    //         // Capture SVG as canvas
    //         const canvas = await html2canvas(svgElement!, {
    //             useCORS: true, // Enables cross-origin resource sharing (CORS)
    //             allowTaint: true, // Allows canvas to be tainted by cross-origin content
    //         });

    //         // Convert canvas to image data
    //         const imgData = canvas.toDataURL('image/png');

    //         // Create a new PDF
    //         const pdf = new jsPDF('p', 'mm', 'a4');
    //         const imgWidth = 210; // A4 paper width in mm
    //         const pageHeight = 295; // A4 paper height in mm
    //         const imgHeight = canvas.height * imgWidth / canvas.width;
    //         let heightLeft = imgHeight;
    //         console.log("imgHeight start");
    //         console.log(imgHeight);
    //         console.log("imgHeight end");

    //         let position = 20;

    //         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;

    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             pdf.addPage();
    //             pdf.addImage(imgData, 'PNG', 0, position+30, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //         }

    //         pdf.save('document.pdf');
    //     } catch (error) {
    //         console.error('Error generating PDF:', error);
    //     }
    // };

    const generatePdf = async () => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        
        for (let i = 0; i < svgContents.length; i++) {
            const svg = svgContents[i];
            console.log(svg);
            // // Create a temporary container for the SVG
            const container = document.createElement('div');
            container.style.position = 'absolute';
            container.style.left = '-9999px'; // Hide it from view
            container.innerHTML = svg;
            document.body.appendChild(container);

            // // Use html2canvas to render SVG as a canvas
            const canvas = await html2canvas(container, { useCORS: true });
            const imgData = canvas.toDataURL('image/png');

            // // Add image to PDF
            if (i > 0) {
                pdf.addPage();
            }
            pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);

            // // Clean up
            // document.body.removeChild(container);
        }

        // Save the PDF
        pdf.save('svgs.pdf');
    };

    const handleDownloadPdf1 = async () => {
        const svgString = `
            <svg xmlns="http://www.w3.org/2000/svg" width="325" height="465" viewBox="0 0 325 465">
                <path d="M10 80 Q 95 10 180 80 T 360 80" stroke="black" stroke-width="3" fill="none" />
            </svg>
        `;

        // Convert SVG to PNG using a canvas
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
        const svgUrl = URL.createObjectURL(svgBlob);

        const img = new Image();
        img.src = svgUrl;

        // Ensure image is loaded
        await new Promise((resolve) => {
            img.onload = resolve;
        });

        // Create a canvas to convert SVG to PNG
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx!.drawImage(img, 0, 0);

        const pngDataUrl = canvas.toDataURL('image/png');
        URL.revokeObjectURL(svgUrl);

        // Create PDF document
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([210, 297]); // A4 size in mm

        // Embed PNG image into PDF
        const pngImage = await pdfDoc.embedPng(pngDataUrl);
        const { width, height } = pngImage.scale(1);

        // Fit PNG to A4 page dimensions
        const pageWidth = 210;
        const pageHeight = 297;
        const scale = Math.min(pageWidth / width, pageHeight / height);
        const scaledWidth = width * scale;
        const scaledHeight = height * scale;

        page.drawImage(pngImage, {
            x: (pageWidth - scaledWidth) / 2, // Center horizontally
            y: (pageHeight - scaledHeight) / 2, // Center vertically
            width: scaledWidth,
            height: scaledHeight,
        });

        // Serialize PDF to bytes
        const pdfBytes = await pdfDoc.save();

        // Download the PDF
        saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'document.pdf');
    };


    return(
        <React.Fragment>
            {/* <DetailTopbar /> */}
            {/* <div className="container text-center">
                <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <button onClick={generatePdf}>Generate PDF</button>
                    <button onClick={downloadSvg}>Download SVG</button>
                    <button onClick={printSvg} className="btn_standard">Print SVG</button>
                </div>
                <div dangerouslySetInnerHTML={{ __html: svgContent! }}  style={{width:"80%",margin:"auto", backgroundColor:"#fff", marginTop:"20px", marginBottom:"50px", border:"1px solid #cecece"}}/>
            </div> */}
            <div>
            {/* <button onClick={generatePdf} disabled={svgContents.length === 0}>
                Generate PDF
            </button> */}
            <div className="container text-center">
                <button onClick={handleDownloadPdf} className="btn_standard  mt-3">Download PDF</button>

                <div ref={containerRef} id="svg-container">
                    {svgContents.map((svg, index) => (
                        
                        <div key={index} dangerouslySetInnerHTML={{ __html: svg }} style={{width:"80%",margin:"auto", backgroundColor:"#fff", marginTop:"20px", 
                            marginBottom:"50px", border:"1px solid #cecece"}} />
                    ))}
                </div>
                </div>
            </div>

            
        </React.Fragment>
    );
};
export default SVGDownload;