import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return (
    item.sidebarProps && item.path ? (
        <ListItemButton
          component={Link}
          to={item.path}
          sx={{
            "&: hover": {
              backgroundColor: colorConfigs.sidebar.hoverBg,
            },
            "&.Mui-selected": {
              backgroundColor: colorConfigs.sidebar.activeBg, // Optional: Set active background color
            },
            "&.Mui-selected:hover": {
              backgroundColor: colorConfigs.sidebar.activeBg, // Ensure the background stays consistent when active and hovered
            },
            // Adjust colors for text and icons when active
            "&.Mui-selected .MuiListItemIcon-root": {
              color: '#fff', // Set icon color to white when active
            },
            "&.Mui-selected .MuiListItemText-primary": {
              color: '#fff', // Set text color to white when active
            },
            backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
            color: appState === item.state ? colorConfigs.sidebar.activeColor : "unset",
            paddingY: "12px",
            paddingX: "24px"
          }}
        >
        <ListItemIcon sx={{
          color: colorConfigs.sidebar.color
        }}>
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {item.sidebarProps.displayText}
      </ListItemButton>
    ) : null
  );
};

export default SidebarItem;