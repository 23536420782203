import axios from 'axios';
import AuthService from "./AuthService";
const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client_domain" :  `${CLIENT_DOMAIN}`,
       } 
};
class CompanyInfoService {

    list(perPage:number,page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string =  API_URL +`/company_info/list?list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL,header); // use axios library getting request data
    }  
    store(data:any) {
        console.log(data);
        let dataURL:string = API_URL + "/company_info/store1";
        return axios.post(dataURL,data, header);
    }
    update(data:any ,id:any) {
        let dataURL:string = API_URL + "/company_info/update1/"+id;
        return axios.post(dataURL,data, header);
    }
    showCompany(company_id:any){
        let dataURL:string = API_URL +`/company_info/detail/${company_id}`;
        return axios.get(dataURL, header);
    }
    deleteList(data:any){
        let dataURL:string = API_URL+"/company_info/list/destroy1";
        return axios.post(dataURL,data,header);
    }
    
}

export default new CompanyInfoService();