import React, {useEffect, useState} from 'react';
import WorkService from "../../services/WorkService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import $ from 'jquery';
import {WorkModel} from "../../models/Work";

interface URLParam {
    id : string;
}

interface IState{
    work : WorkModel;
    person_list: [];
    case_list : [];
    buyer_contract_list : [];
    seller_contract_list : [];
}
interface IProps{}
let WorkCreate:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();

    let [state , setState] = useState<IState>({
        work : {
            work_id: "",
            person_id : 15,
            case_id : 17,
            buyer_contract : "請負",
            seller_contract : "請負",
            work_start_date : "",
            work_place : "",
            work_end_date_plan: "",
            contract_price : "",
            contract_price_type : "0",
            settlement : "",
            settlement_to : "",
            settlement_biko : "",
            payment_site : "",
            person_cost : "",
            o_adj_hours_low : "",
            o_adj_hours_high : "",
            o_adj_hours_biko : "",
            o_payment_site : "",
            flb_shared_cmt : "",
            accept_proc_date : "",
            security_edu_date : "",
            entrance_date : "",
            hearing_date: "",
            hearing_remarks : "",
            customer_remarks : "",
            exit_date : "",
        },
        person_list: [],
        case_list : [],
        buyer_contract_list : [],
        seller_contract_list : [],
     });

    const [startDate, setStartDate] = useState(new Date());
   

    useEffect(()=>{
        if(id != undefined){
            WorkService.edit(id).then((response)=>{
                console.log(response);
                setState({
                    ...state,
                    work : response.data.work,
                    person_list:response.data.person_list,
                    case_list : response.data.case_list,
                    buyer_contract_list : response.data.expect_contract_type,
                    seller_contract_list : response.data.expect_contract_type,
                })

            }).catch((error) => {
                // console.log(error);
                // setState({
                //     ...state,
                // })
            })
        }else{
            WorkService.create().then((response)=>{
                console.log(response);
                setState({
                    ...state,
                    person_list:response.data.person_list,
                    case_list : response.data.case_list,
                    buyer_contract_list : response.data.expect_contract_type,
                    seller_contract_list : response.data.expect_contract_type,
                })
            }).catch((error) => {
                console.log(error);
                // setState({
                //     ...state,
                // })
            })
        }
       

    }, [id]);
    let {work ,person_list,case_list, buyer_contract_list, seller_contract_list} = state;



    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        if(id != undefined){
            WorkService.update(state.work, id).then((response) => {
                window.location.href = "/management/works/list";
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }else{
            WorkService.store(state.work).then((response) => {
                window.location.href = "/management/works/list";
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }
    };
  
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            work : {
                ...state.work,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            person_list: person_list,
            case_list : case_list,
            buyer_contract_list : buyer_contract_list,
            seller_contract_list : seller_contract_list,
        });        
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            work : {
                ...state.work,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            person_list: person_list,
            case_list : case_list,
            buyer_contract_list : buyer_contract_list,
            seller_contract_list : seller_contract_list,
        });
    }
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            work : {
                ...state.work,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
            person_list: person_list,
            case_list : case_list,
            buyer_contract_list : buyer_contract_list,
            seller_contract_list : seller_contract_list,
        });
     };
    
    
 
    const [selectedWorkStartDate, setSelectedWorkStartDate] = useState(null);
    const [selectedWorkEndDatePlan, setSelectedWorkEndDatePlan] = useState(null);
    const [selectedAcceptProcDate, setSelectedAcceptProcDate] = useState(null);
    const [selectedSecurityEduDate, setSelectedSecurityEduDate] = useState(null);
    const [selectedEntranceDate, setSelectedEntranceDate] = useState(null);
    const [selectedHearingDate, setSelectedHearingDate] = useState(null);
    const [selectedExitDate, setSelectedExitDate] = useState(null);

    const handleDateChange = function(date:any, column_name:any){

    let selected_data = (date != null)? date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()): "";        
        switch (column_name) {
            case "work_start_date":
                setSelectedWorkStartDate(date);
                setState({
                    work : {
                        ...state.work,
                       work_start_date : date // input name 'username' set value 'event target value'
                    },
                    person_list: person_list,
                    case_list : case_list,
                    buyer_contract_list : buyer_contract_list,
                    seller_contract_list : seller_contract_list,
                });
                break;
            case "work_end_date_plan":
                setSelectedWorkEndDatePlan(date);
                setState({
                    work : {
                        ...state.work,
                        work_end_date_plan : date // input name 'username' set value 'event target value'
                    },
                    person_list: person_list,
                    case_list : case_list,
                    buyer_contract_list : buyer_contract_list,
                    seller_contract_list : seller_contract_list,
                });
                break;
            case "accept_proc_date":
                setSelectedAcceptProcDate(date);
                setState({
                    work : {
                        ...state.work,
                        accept_proc_date : date // input name 'username' set value 'event target value'
                    },
                    person_list: person_list,
                    case_list : case_list,
                    buyer_contract_list : buyer_contract_list,
                    seller_contract_list : seller_contract_list,
                });
                break;
            case "security_edu_date":
                setSelectedSecurityEduDate(date);
                setState({
                    work : {
                        ...state.work,
                        security_edu_date : date // input name 'username' set value 'event target value'
                    },
                    person_list: person_list,
                    case_list : case_list,
                    buyer_contract_list : buyer_contract_list,
                    seller_contract_list : seller_contract_list,
                });
                break;
            case "entrance_date":
                setSelectedEntranceDate(date);
                setState({
                    work : {
                        ...state.work,
                        entrance_date : date // input name 'username' set value 'event target value'
                    },
                    person_list: person_list,
                    case_list : case_list,
                    buyer_contract_list : buyer_contract_list,
                    seller_contract_list : seller_contract_list,
                });
                break;
            case "hearing_date":
                setSelectedHearingDate(date);
                setState({
                    work : {
                        ...state.work,
                        hearing_date : date // input name 'username' set value 'event target value'
                    },
                    person_list: person_list,
                    case_list : case_list,
                    buyer_contract_list : buyer_contract_list,
                    seller_contract_list : seller_contract_list,
                });
                break;
            case "exit_date":
                setSelectedExitDate(date);
                setState({
                    work : {
                        ...state.work,
                        exit_date : date // input name 'username' set value 'event target value'
                    },
                    person_list: person_list,
                    case_list : case_list,
                    buyer_contract_list : buyer_contract_list,
                    seller_contract_list : seller_contract_list,
                });
                break;
            default:
                break;
        }

    }

   
      const person_arr:any = [];
      person_list.map(person =>{
        person_arr.push({label:person[1], value: person[0]});
      })

    return(
        <React.Fragment>
            <DetailTopbar />
            <div className="container">
                <div className = "row">
                        <h3 className='header-left-design'>要員稼働情報{(id !== undefined) ?"編集":"登録"}</h3>
                </div>
                <div className="row anken-detail">
                <form className="form create-form " onSubmit={store}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">要員名</span>
                                    {/* <Select
                                                value={state.work.person_id}
                                                options={person_arr}
                                                onChange={opt => {
                                                
                                                 }}
                                            /> */}
                                    <select name="person_id" className="input-box form-control" value={state.work.person_id} onChange={onSelect} aria-label=".form-select-sm example">
                                        {person_list.map(person => {
                                                if (person !== null){
                                                    return(<option value={person[0]}> {person[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">案件名</span>
                                    <select name="case_id" className="input-box form-control" value={state.work.case_id} onChange={onSelect} aria-label=".form-select-sm example">
                                        {case_list.map(cs => {
                                                if (cs !== null){
                                                    return(<option value={cs[0]}> {cs[1]}</option>)
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">受注契約</span>
                                    <select name="buyer_contract" className="input-box form-control" value={(state.work.buyer_contract != "")?state.work.buyer_contract:""} onChange={onSelect} aria-label=".form-select-sm example">
                                        {buyer_contract_list.map(buyer_contract => {
                                                if (buyer_contract !== null){
                                                    return(<option value={buyer_contract}> {buyer_contract}</option>)
                                                }
                                            })
                                        }
                                    </select> 
                                </div>
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">発注契約</span>
                                    <select name="seller_contract" className="input-box form-control" value={(state.work.seller_contract != "")?state.work.seller_contract:""} onChange={onSelect} aria-label=".form-select-sm example">
                                        {seller_contract_list.map(seller_contract => {
                                                if (seller_contract !== null){
                                                    return(<option value={seller_contract}> {seller_contract}</option>)
                                                }
                                            })
                                        }
                                    </select> 
                                </div>
                            </div>
    
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">稼働開始日</span>
                                    <DatePicker 
                                            value={(state.work.work_start_date != null)?state.work.work_start_date: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedWorkStartDate} 
                                            onChange={date =>
                                                handleDateChange(date, "work_start_date")
                                            }
                                            name="work_start_date"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">勤務場所</span>
                                    <input type="text" name="work_place" id="work_place" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.work.work_place} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text'>稼働終了予定日</span>
                                <DatePicker 
                                    value={(state.work.work_end_date_plan != null)?state.work.work_end_date_plan: ""}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    selected={selectedWorkEndDatePlan} 
                                    onChange={date =>
                                        handleDateChange(date, "work_end_date_plan")
                                    }
                                    name="work_end_date_plan"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    />
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">稼働単金</span>
                                    <input type="text" name="contract_price" id="contract_price" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.work.contract_price} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">稼働単金種別</span>
                                    <select name="contract_price_type" className="input-box form-control" value={(state.work.contract_price_type != "")?state.work.contract_price_type:""} onChange={onSelect} aria-label=".form-select-sm example">
                                        <option value="0"> 月額単金</option>
                                        <option value="1"> 時間単価</option>                                       
                                    </select> 
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>精算</span>
                                    <input type="text" name="settlement" id="settlement" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.work.settlement} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>支払サイト</span>
                                    <input type="text" name="payment_site" id="payment_site" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.work.payment_site} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>社内共有メモ</span>
                                    <textarea className="form-control" rows={3} name = "flb_shared_cmt" id= "flb_shared_cmt" aria-label="With textarea" value={state.work.flb_shared_cmt} onChange={updateTextArea}></textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>入場手続き完了日</span>
                                    <DatePicker 
                                        value={(state.work.accept_proc_date != null)?state.work.accept_proc_date: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedAcceptProcDate} 
                                        onChange={date =>
                                            handleDateChange(date, "accept_proc_date")
                                        }
                                        name="accept_proc_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>セキュリティ教育日</span>
                                    <DatePicker 
                                        value={(state.work.security_edu_date != null)?state.work.security_edu_date: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedSecurityEduDate} 
                                        onChange={date =>
                                            handleDateChange(date, "security_edu_date")
                                        }
                                        name="security_edu_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>入場日</span>
                                    <DatePicker 
                                            value={(state.work.entrance_date != null)?state.work.entrance_date: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedEntranceDate} 
                                            onChange={date =>
                                                handleDateChange(date, "entrance_date")
                                            }
                                            name="entrance_date"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>訪問日</span>
                                    <DatePicker 
                                        value={(state.work.hearing_date != null)?state.work.hearing_date: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedHearingDate} 
                                        onChange={date =>
                                            handleDateChange(date, "hearing_date")
                                        }
                                        name="hearing_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>訪問日登録</span>
                                    <textarea className="form-control" rows={3} name = "hearing_remarks" id= "hearing_remarks" aria-label="With textarea" value={state.work.hearing_remarks} onChange={updateTextArea}></textarea>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>現場評価</span>
                                    <textarea className="form-control" rows={3} name = "customer_remarks" id= "customer_remarks" aria-label="With textarea" value={state.work.customer_remarks} onChange={updateTextArea}></textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>退場日</span>
                                    <DatePicker 
                                        value={(state.work.exit_date != null)?state.work.exit_date: ""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedExitDate} 
                                        onChange={date =>
                                            handleDateChange(date, "exit_date")
                                        }
                                        name="exit_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <button className="btn btn_standard" id="case_create_submit" type="submit"> {(id !== undefined) ?"更新":"登録"}</button>
                            <Link to={`/management/works/list`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                            
                        </div>
                    </div>
                    </form>
                </div>
                
            </div>
        </React.Fragment>
    );
};
export default WorkCreate;