import React, {useEffect, useState} from 'react';
import ProcedureService from "../../services/ProcedureService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";

import DetailTopbar from "../../components/common/DetailTopbar";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import $ from 'jquery';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

interface URLParam {
    id : string;
}

// interface IState{
//     accounting_model : AccountingModel;
// }
interface IProps{}
let KeyConfirmation:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();
    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false); 
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(300);
    const [key, setKey] = useState("");
    const [key_val, setKeyVal] = useState("");
    let location = useLocation();
    let search = location.search;

    useEffect(()=>{
        if(id != undefined){
            ProcedureService.checkKey(id).then((response)=>{
               console.log(response);
               if(response.status == 200){
                 setKey(response.data);
               }
            }).catch((error) => {
              
            })
        }
       

    }, [id]);
    // let {accounting_model} = state;

   const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        let arr = key.split("-");
        console.log(arr);
        if(arr[0] == key_val){
            if(search == "?type=1")
                {
                    window.location.href= "/download/encryptsvg/"+ arr[1]+ "?type=1";
                }else if(search == "?type=3")
                    {
                        window.location.href= "/management/download/svg/"+ arr[1]+ "?type=3";
                }else if(search == "?type=4")
                    {
                        window.location.href= "/management/download/svg/"+ arr[1]+ "?type=4";
                }else{
                    window.location.href= "/download/encryptsvg/"+ arr[1]+ "?type=2"; 
                }
        }else{
            alert("認証コードが間違っています。もう一度やり直してください。")
        }
    };

    let updateInput = (e:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setKeyVal(e.target.value);
    };


    return(
        <React.Fragment>
            <div className="container">
                <Modal
                    keepMounted
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >                                
                    <Box sx={
                        {
                            width: `${width}px`,
                            height: `${height}px`,
                            backgroundColor: 'white',
                            resize: 'both',
                            overflow: 'auto',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '15px',
                            paddingLeft: '30px',
                            paddingRight: '30px',
                            position:'relative',
                            top: '5%',
                            left: '30%',
                        }
                    }>
                        <div className="row">
                            <div className="col-6">
                                <h3>承認画面</h3>
                            </div>
                            {/* <div className="col-6">
                                <button type="button" className="modal_close" onClick={handleClose}>X</button>
                            </div>             */}
                        </div>
                        <form className="form create-form" onSubmit={store}>
                            <div className="row">
                                <div className="col-12 input-box">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className="input-required" style={{fontSize:"13px",fontWeight:"600"}}>認証コード:</label> 
                                        <input type="text" className="form-control" name="key"  value={key_val}  onChange={updateInput}/>                                      
                                    </div>          
                                </div>
                            </div>                  
                        <div className="row">
                                <div className="form-group mt-5 text-center">
                                    <button type="submit" className="button submit-btn btn_standard">保存</button>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Modal>    
            </div>
        </React.Fragment>
    );
};
export default KeyConfirmation;