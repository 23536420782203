import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client_domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};
class SaleryDetailService {
    list(emp_name:any,period_start:any,period_end:any,approve_status:any,perPage:number, page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/salerys_detail/list?&emp_name=${emp_name}&period_start=${period_start}&period_end=${period_end}&approv_status=${approve_status}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, header);
    }

    create(){
        let dataURL:string = API_URL + `/salerys/create1`;
        return axios.get(dataURL, header);
    }

    store(data:any) {
        let dataURL:string = API_URL + "/salerys_detail/store1";
        return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` } });
    }

    edit(id:any, sal_yyyy_mm:any){
        let dataURL:string = API_URL + "/salerys_detail/"+id+"/edit?"+sal_yyyy_mm;
        return axios.get(dataURL, header);
    }

    update(data:any ,id:any) {
        console.log(data);
        let dataURL:string = API_URL + "/salerys_detail/update1/"+id;
        return axios.post(dataURL,data, header);
    }

    delete(data:any) {
        let dataURL:string = API_URL + "/salerys_detail/list/destroy1";
        return axios.post(dataURL,data, header);
    }

    // calTax(data:any){
    //     let dataURL:string = API_URL + "/salerys/tax/pay1";
    //     return axios.post(dataURL,data, header);
    // }

    yoyoicsvfile(data:any){
        let dataURL:string = API_URL + "/salery_detail/yoyoi-csv-file1";
        return axios.post(dataURL,data, header);
    }

    dlCSVFile(){
        let dataURL:string = API_URL + "/salery_detail/download-yoyoi-csv-file1";
        return axios.get(dataURL, header);
    }

    calTax(data:any){
        let dataURL:string = API_URL + "/salery_detail/incomeTax";
        return axios.post(dataURL,data, header);
    }

    getSaleryEmpIns(data:any){
        let dataURL:string = API_URL + "/salery_detail/getSaleryEmpIns1";
        return axios.post(dataURL,data, header);
    }
    sendSalaryDetail(datas:any){
        let dataURL:string = API_URL + "/salerydetail/sendSaleryDetail1";
        return axios.post(dataURL,datas, header);
    }
    
}

  export default new SaleryDetailService();